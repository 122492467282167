import React from 'react';
import {
	Fade,
	Paper,
	IconButton,
	Grid,
	Typography,
	Modal,
	Button,
	useMediaQuery
} from '@material-ui/core';
import { Add, Close } from '@material-ui/icons';
import useBStyles from 'styles/bStyles';
import useCStyles from 'styles/cStyles';
import physicalEmotions from '../data/physicalEmotions';
import negativeEmotions from '../data/negativeEmotions';
import toolkit from '../data/toolkit';

const categoryTerms = {
	chat: 'chat tool',
	helpfulThinking: 'helpful thinking tool',
	amusement: 'amusement tool',
	relaxation: 'relaxation tool',
	goodRoutine: 'good routine tool',
	exercise: 'exercise tool',
	event: 'event'
};

const ListModal = ({ open, setOpen, type, handleEdit }) => {
	const bClasses = useBStyles();
	const cClasses = useCStyles();
	const matchesXS = useMediaQuery(theme => theme.breakpoints.down('xs'));

	let elements = [];
	let term = '';
	let category = '';
	switch (type) {
		case 'PHYSICAL_EMOTION':
			term = 'physical emotion';
			elements = physicalEmotions;
			break;
		case 'NEGATIVE_EMOTION':
			elements = negativeEmotions;
			term = 'negative emotion';
			break;
		case 'CHAT':
			elements = toolkit.chat;
			term = categoryTerms.chat;
			category = 'chat';
			break;
		case 'HELPFUL_THINKING':
			elements = toolkit.helpfulThinking;
			term = categoryTerms.helpfulThinking;
			category = 'helpfulThinking';
			break;
		case 'AMUSEMENT':
			elements = toolkit.amusement;
			term = categoryTerms.amusement;
			category = 'amusement';
			break;
		case 'RELAXATION':
			elements = toolkit.relaxation;
			term = categoryTerms.relaxation;
			category = 'relaxation';
			break;
		case 'GOOD_ROUTINE':
			elements = toolkit.goodRoutine;
			term = categoryTerms.goodRoutine;
			category = 'goodRoutine';
			break;
		case 'EXERCISE':
			elements = toolkit.exercise;
			term = categoryTerms.exercise;
			category = 'exercise';
			break;
		case 'EVENT':
			elements = toolkit.goodRoutine;
			term = categoryTerms.event;
			category = 'event';
			break;
		default:
			break;
	}
	return (
		<Modal open={open} onClose={() => setOpen(false)}>
			<Fade in={open}>
				<Paper
					className={bClasses.modalPaper}
					style={{
						height: matchesXS ? 'calc(100 * var(--vh))' : '85%',
						overflow: 'hidden'
					}}
				>
					<IconButton className={bClasses.close} onClick={() => setOpen(false)}>
						<Close />
					</IconButton>
					<Grid container justifyContent='center'>
						<Typography variant='h3' className={bClasses.modalTitle}>
							Add {term}
						</Typography>
					</Grid>
					<Grid container className={cClasses.elementScroll}>
						<Grid item xs={6} sm={4} className={cClasses.elementContainer}>
							<Button
								variant='outlined'
								className={cClasses.element}
								onClick={() => {
									setOpen(false);
									handleEdit({
										type,
										payload: {
											title: `New ${
												type === 'EVENT'
													? 'event'
													: type === 'PHYSICAL_EMOTION' ||
													  type === 'NEGATIVE_EMOTION'
													? 'emotion'
													: 'tool'
											}`,
											category
										}
									});
								}}
							>
								<Grid direction='column' alignItems='center' container>
									<Typography
										variant='h6'
										className={cClasses.elementTitle}
									>{`New ${term}`}</Typography>
									<Add color='secondary' style={{ width: 100, height: 100 }} />
								</Grid>
							</Button>
						</Grid>
						{elements.map(element => (
							<Grid
								item
								key={element.image}
								xs={6}
								sm={4}
								className={cClasses.elementContainer}
							>
								<Button
									variant='outlined'
									className={cClasses.element}
									onClick={() => {
										setOpen(false);
										handleEdit({ type, payload: { ...element, category } });
									}}
								>
									<Grid direction='column' alignItems='center' container>
										<Typography variant='h6' className={cClasses.elementTitle}>
											{element.title}
										</Typography>
										<img
											src={element.image}
											className={cClasses.elementAvatar}
											alt={element.title}
										/>
									</Grid>
								</Button>
							</Grid>
						))}
					</Grid>
				</Paper>
			</Fade>
		</Modal>
	);
};

export default ListModal;
