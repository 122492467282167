import React, { useEffect, useState } from 'react';
import {
	Fade,
	Grid,
	IconButton,
	Modal,
	Typography,
	Paper,
	TextField,
	Button,
	CircularProgress,
	FormGroup,
	FormControlLabel,
	Checkbox,
	useTheme,
	useMediaQuery,
	Popover
} from '@material-ui/core';
import { Close, HelpOutline, Save } from '@material-ui/icons';
import useBStyles from 'styles/bStyles';
import { useDispatch, useSelector } from 'react-redux';
import { updateEmotionRatingAction } from 'actions/rainbowActions';

const AppSettingsModal = ({ open, setOpen }) => {
	const bClasses = useBStyles();
	const dispatch = useDispatch();
	const theme = useTheme();
	const matchesXS = useMediaQuery(theme => theme.breakpoints.down('xs'));

	const {
		user: { degreeRange }
	} = useSelector(state => state.userData);
	const {
		loading: updateEmotionRatingLoading,
		success: updateEmotionRatingSuccess
	} = useSelector(state => state.updateEmotionRating);

	const [isChecked, setIsChecked] = useState(!!degreeRange);
	const [maxRating, setMaxRating] = useState(degreeRange);
	const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);

	const submitHandler = e => {
		e.preventDefault();
		dispatch(updateEmotionRatingAction(isChecked ? maxRating : 0));
	};

	const checkboxHandler = e => {
		setIsChecked(e.target.checked);
	};

	const maxRatingHandler = e => {
		const rating = Number(e.target.value);
		if (rating > 0 && rating <= 10) setMaxRating(e.target.value);
		if (e.target.value === '') setMaxRating('');
	};
	const popoverIsOpen = Boolean(popoverAnchorEl);
	const popoverId = popoverIsOpen ? 'rating-tip-popover' : undefined;

	useEffect(() => {
		if (updateEmotionRatingSuccess) setOpen(false);
	}, [updateEmotionRatingSuccess, setOpen]);

	return (
		<Modal open={open} onClose={() => setOpen(false)}>
			<Fade in={open}>
				<Paper
					className={bClasses.modalPaper}
					style={{ width: matchesXS ? '' : 410 }}
				>
					<IconButton className={bClasses.close} onClick={() => setOpen(false)}>
						<Close />
					</IconButton>
					<IconButton
						className={bClasses.popover}
						onClick={e => setPopoverAnchorEl(e.currentTarget)}
					>
						<HelpOutline />
					</IconButton>
					<Popover
						id={popoverId}
						open={popoverIsOpen}
						anchorEl={popoverAnchorEl}
						onClose={() => setPopoverAnchorEl(null)}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'left'
						}}
						transformOrigin={{
							vertical: 'top',
							horizontal: 'center'
						}}
					>
						<Typography
							style={{ width: 320, padding: theme.spacing(1), fontWeight: 600 }}
						>
							The emotion rating allows you to rate the intensity of your
							selected emotion.
							<br />
							<br />
							If you choose to include the rating, please select a maximum
							rating between 1 and 10
						</Typography>
					</Popover>
					<Grid container direction='column' alignItems='center'>
						<Typography variant='h3'>Emotion rating</Typography>
						<form onSubmit={submitHandler} className={bClasses.modalForm}>
							<FormGroup row style={{ marginTop: theme.spacing(1) }}>
								<FormControlLabel
									control={
										<Checkbox
											checked={isChecked}
											onChange={checkboxHandler}
											name='ratingCheckbox'
											color='secondary'
										/>
									}
									label='Include emotion rating'
								/>
							</FormGroup>

							<TextField
								id='maxRating'
								label='Max Rating'
								type='number'
								placeholder='Max Rating'
								fullWidth
								color='secondary'
								value={maxRating}
								onChange={maxRatingHandler}
								className={bClasses.input}
								disabled={!isChecked}
								style={{ width: 100, margin: theme.spacing(1) }}
							/>

							<Button
								variant='contained'
								type='submit'
								endIcon={<Save />}
								className={bClasses.button}
								fullWidth
								color='secondary'
								disableElevation
								style={{ fontWeight: 700, fontSize: '1.1rem' }}
								disabled={
									isChecked &&
									(maxRating < 1 || maxRating > 10 || maxRating === '')
								}
							>
								{updateEmotionRatingLoading ? (
									<CircularProgress size={25} style={{ color: '#fff' }} />
								) : (
									'Save'
								)}
							</Button>
						</form>
					</Grid>
				</Paper>
			</Fade>
		</Modal>
	);
};

export default AppSettingsModal;
