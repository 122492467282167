import axios from 'axios';
import {
	UPLOAD_RAINBOW_IMAGE_REQUEST,
	UPLOAD_RAINBOW_IMAGE_SUCCESS,
	UPLOAD_RAINBOW_IMAGE_FAIL,
	UPDATE_EMOTION_RATING_REQUEST,
	UPDATE_EMOTION_RATING_SUCCESS,
	UPDATE_EMOTION_RATING_FAIL,
	UPLOAD_AUDIO_REQUEST,
	UPLOAD_AUDIO_SUCCESS,
	UPLOAD_AUDIO_FAIL,
	DELETE_AUDIO_REQUEST,
	DELETE_AUDIO_SUCCESS,
	DELETE_AUDIO_FAIL,
	DELETE_RAINBOW_IMAGE_REQUEST,
	DELETE_RAINBOW_IMAGE_SUCCESS,
	DELETE_RAINBOW_IMAGE_FAIL,
	CREATE_RAINBOW_ELEMENT_REQUEST,
	CREATE_RAINBOW_ELEMENT_SUCCESS,
	CREATE_RAINBOW_ELEMENT_FAIL,
	UPDATE_RAINBOW_ELEMENT_REQUEST,
	UPDATE_RAINBOW_ELEMENT_SUCCESS,
	UPDATE_RAINBOW_ELEMENT_FAIL,
	DELETE_RAINBOW_ELEMENT_REQUEST,
	DELETE_RAINBOW_ELEMENT_SUCCESS,
	DELETE_RAINBOW_ELEMENT_FAIL,
	ADD_HISTORY_REQUEST,
	ADD_HISTORY_SUCCESS,
	ADD_HISTORY_FAIL,
	ADD_EVENT_REQUEST,
	ADD_EVENT_SUCCESS,
	ADD_EVENT_FAIL,
	EDIT_EVENT_REQUEST,
	EDIT_EVENT_SUCCESS,
	EDIT_EVENT_FAIL,
	DELETE_EVENT_REQUEST,
	DELETE_EVENT_SUCCESS,
	DELETE_EVENT_FAIL,
	TOGGLE_TIMES_REQUEST,
	TOGGLE_TIMES_SUCCESS,
	TOGGLE_TIMES_FAIL
} from 'constants/rainbowConstants';
import { USER_DATA_SUCCESS } from 'constants/userConstants';

export const uploadRainbowImageAction =
	(file, type) => async (dispatch, getState) => {
		try {
			dispatch({
				type: UPLOAD_RAINBOW_IMAGE_REQUEST
			});

			const {
				userData: { token }
			} = getState();

			const config = {
				headers: {
					'Content-Type': 'application/json',
					'Authorization': `Bearer ${token}`
				}
			};
			const formData = new FormData();
			formData.append('image', file);

			const { data } = await axios.post(
				`/api/v1/rainbow/image/${type.toLowerCase()}`,
				formData,
				config
			);

			dispatch({
				type: UPLOAD_RAINBOW_IMAGE_SUCCESS,
				payload: 'Image uploaded',
				image: data.image
			});
		} catch (error) {
			dispatch({
				type: UPLOAD_RAINBOW_IMAGE_FAIL,
				payload:
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
			});
		}
	};

export const uploadAudioAction = (file, type) => async (dispatch, getState) => {
	try {
		dispatch({
			type: UPLOAD_AUDIO_REQUEST
		});

		const {
			userData: { token }
		} = getState();

		const config = {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${token}`
			}
		};

		const formData = new FormData();
		formData.append('data', file);

		const { data } = await axios.post(
			`/api/v1/rainbow/audio/${type.toLowerCase()}`,
			formData,
			config
		);

		dispatch({
			type: UPLOAD_AUDIO_SUCCESS,
			payload: 'Image uploaded',
			audio: data.audio
		});
	} catch (error) {
		dispatch({
			type: UPLOAD_AUDIO_FAIL,
			payload:
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
		});
	}
};

export const deleteAudioAction = () => async (dispatch, getState) => {
	try {
		dispatch({
			type: DELETE_AUDIO_REQUEST
		});

		const {
			userData: { token }
		} = getState();

		const config = {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${token}`
			}
		};

		await axios.delete(`/api/v1/rainbow/audio/`, config);

		dispatch({
			type: DELETE_AUDIO_SUCCESS,
			payload: 'Audio deleted'
		});
	} catch (error) {
		dispatch({
			type: DELETE_AUDIO_FAIL,
			payload:
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
		});
	}
};

export const deleteRainbowImageAction = () => async (dispatch, getState) => {
	try {
		dispatch({
			type: DELETE_RAINBOW_IMAGE_REQUEST
		});

		const {
			userData: { token }
		} = getState();

		const config = {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${token}`
			}
		};

		await axios.delete(`/api/v1/rainbow/image/`, config);

		dispatch({
			type: DELETE_RAINBOW_IMAGE_SUCCESS,
			payload: 'Image deleted'
		});
	} catch (error) {
		dispatch({
			type: DELETE_RAINBOW_IMAGE_FAIL,
			payload:
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
		});
	}
};

export const createRainbowElementAction =
	element => async (dispatch, getState) => {
		try {
			dispatch({
				type: CREATE_RAINBOW_ELEMENT_REQUEST
			});

			const {
				userData: { token }
			} = getState();

			const config = {
				headers: {
					'Content-Type': 'application/json',
					'Authorization': `Bearer ${token}`
				}
			};

			const { data } = await axios.post(`/api/v1/rainbow/`, element, config);

			dispatch({
				type: USER_DATA_SUCCESS,
				payload: data
			});

			localStorage.setItem(
				'user',
				JSON.stringify({ user: data.user, token: data.token })
			);

			dispatch({
				type: CREATE_RAINBOW_ELEMENT_SUCCESS,
				payload: `${
					element.type.includes('EMOTION') ? 'Emotion' : 'Tool'
				} added`
			});
		} catch (error) {
			dispatch({
				type: CREATE_RAINBOW_ELEMENT_FAIL,
				payload:
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
			});
		}
	};

export const updateRainbowElementAction =
	element => async (dispatch, getState) => {
		try {
			dispatch({
				type: UPDATE_RAINBOW_ELEMENT_REQUEST
			});

			const {
				userData: { token }
			} = getState();

			const config = {
				headers: {
					'Content-Type': 'application/json',
					'Authorization': `Bearer ${token}`
				}
			};

			const { data } = await axios.put(`/api/v1/rainbow/`, element, config);

			dispatch({
				type: USER_DATA_SUCCESS,
				payload: data
			});

			localStorage.setItem(
				'user',
				JSON.stringify({ user: data.user, token: data.token })
			);

			dispatch({
				type: UPDATE_RAINBOW_ELEMENT_SUCCESS,
				payload: `${
					element.type.includes('EMOTION') ? 'Emotion' : 'Tool'
				} updated`
			});
		} catch (error) {
			dispatch({
				type: UPDATE_RAINBOW_ELEMENT_FAIL,
				payload:
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
			});
		}
	};

export const deleteRainbowElementAction =
	(type, id, category) => async (dispatch, getState) => {
		try {
			dispatch({
				type: DELETE_RAINBOW_ELEMENT_REQUEST
			});

			const {
				userData: { token }
			} = getState();

			const config = {
				headers: {
					'Content-Type': 'application/json',
					'Authorization': `Bearer ${token}`
				}
			};

			const { data } = await axios.delete(
				`/api/v1/rainbow/${type}/${id}/${category}`,
				config
			);

			dispatch({
				type: USER_DATA_SUCCESS,
				payload: data
			});

			localStorage.setItem(
				'user',
				JSON.stringify({ user: data.user, token: data.token })
			);

			dispatch({
				type: DELETE_RAINBOW_ELEMENT_SUCCESS,
				payload: `${type.includes('EMOTION') ? 'Emotion' : 'Tool'} deleted`
			});
		} catch (error) {
			dispatch({
				type: DELETE_RAINBOW_ELEMENT_FAIL,
				payload:
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
			});
		}
	};

export const addHistoryAction = () => async (dispatch, getState) => {
	try {
		dispatch({
			type: ADD_HISTORY_REQUEST
		});

		const {
			userData: {
				token,
				user: { degreeRange }
			},
			rainbowState: {
				activeEmotion: emotion,
				emotionType,
				degree,
				activeTool: tool,
				activeToolCategory: toolCategory,
				outcome
			}
		} = getState();

		const config = {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${token}`
			}
		};
		const { data } = await axios.post(
			`/api/v1/rainbow/history/`,
			{
				emotion: emotion.title,
				emotionType,
				degree,
				degreeRange,
				tool: tool.title,
				toolCategory,
				outcome
			},
			config
		);

		dispatch({
			type: USER_DATA_SUCCESS,
			payload: data
		});

		localStorage.setItem(
			'user',
			JSON.stringify({ user: data.user, token: data.token })
		);

		dispatch({
			type: ADD_HISTORY_SUCCESS,
			payload: 'History saved!'
		});
	} catch (error) {
		dispatch({
			type: ADD_HISTORY_FAIL,
			payload:
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
		});
	}
};

export const updateEmotionRatingAction =
	maxRating => async (dispatch, getState) => {
		try {
			dispatch({
				type: UPDATE_EMOTION_RATING_REQUEST
			});

			const {
				userData: { token }
			} = getState();

			const config = {
				headers: {
					'Content-Type': 'application/json',
					'Authorization': `Bearer ${token}`
				}
			};

			const { data } = await axios.post(
				`/api/v1/rainbow/rating/`,
				{ maxRating },
				config
			);

			dispatch({
				type: UPDATE_EMOTION_RATING_SUCCESS,
				payload: 'Emotion rating updated'
			});

			dispatch({
				type: USER_DATA_SUCCESS,
				payload: data
			});

			localStorage.setItem(
				'user',
				JSON.stringify({ user: data.user, token: data.token })
			);
		} catch (error) {
			dispatch({
				type: UPDATE_EMOTION_RATING_FAIL,
				payload:
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
			});
		}
	};

export const addEventAction = event => async (dispatch, getState) => {
	try {
		dispatch({
			type: ADD_EVENT_REQUEST
		});

		const {
			userData: { token }
		} = getState();

		const config = {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${token}`
			}
		};

		const { data } = await axios.post(
			`/api/v1/rainbow/schedule/`,
			{ event },
			config
		);

		dispatch({
			type: ADD_EVENT_SUCCESS,
			payload: 'Event added'
		});

		dispatch({
			type: USER_DATA_SUCCESS,
			payload: data
		});

		localStorage.setItem(
			'user',
			JSON.stringify({ user: data.user, token: data.token })
		);
	} catch (error) {
		dispatch({
			type: ADD_EVENT_FAIL,
			payload:
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
		});
	}
};

export const editEventAction = event => async (dispatch, getState) => {
	try {
		dispatch({
			type: EDIT_EVENT_REQUEST
		});

		const {
			userData: { token }
		} = getState();

		const config = {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${token}`
			}
		};

		const { data } = await axios.put(
			`/api/v1/rainbow/schedule/`,
			{ event },
			config
		);

		dispatch({
			type: EDIT_EVENT_SUCCESS,
			payload: 'Event updated'
		});

		dispatch({
			type: USER_DATA_SUCCESS,
			payload: data
		});

		localStorage.setItem(
			'user',
			JSON.stringify({ user: data.user, token: data.token })
		);
	} catch (error) {
		dispatch({
			type: EDIT_EVENT_FAIL,
			payload:
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
		});
	}
};

export const deleteEventAction = id => async (dispatch, getState) => {
	try {
		dispatch({
			type: DELETE_EVENT_REQUEST
		});

		const {
			userData: { token }
		} = getState();

		const config = {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${token}`
			}
		};

		const { data } = await axios.delete(
			`/api/v1/rainbow/schedule/${id}`,
			config
		);

		dispatch({
			type: DELETE_EVENT_SUCCESS,
			payload: 'Event deleted'
		});

		dispatch({
			type: USER_DATA_SUCCESS,
			payload: data
		});

		localStorage.setItem(
			'user',
			JSON.stringify({ user: data.user, token: data.token })
		);
	} catch (error) {
		dispatch({
			type: DELETE_EVENT_FAIL,
			payload:
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
		});
	}
};

export const toggleTimesAction = () => async (dispatch, getState) => {
	try {
		dispatch({
			type: TOGGLE_TIMES_REQUEST
		});

		const {
			userData: { token }
		} = getState();

		const config = {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${token}`
			}
		};

		const { data } = await axios.get(`/api/v1/rainbow/schedule/times`, config);

		dispatch({
			type: TOGGLE_TIMES_SUCCESS,
			payload: `Times ${data.user.showTimes ? 'displayed' : 'hidden'}`
		});

		dispatch({
			type: USER_DATA_SUCCESS,
			payload: data
		});

		localStorage.setItem(
			'user',
			JSON.stringify({ user: data.user, token: data.token })
		);
	} catch (error) {
		dispatch({
			type: TOGGLE_TIMES_FAIL,
			payload:
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
		});
	}
};
