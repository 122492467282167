import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles(theme => ({
	bow: {
		position: 'fixed',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'space-between',
		bottom: 0,
		left: 0,
		width: 'calc(200* var(--vh))',
		height: 'calc(200* var(--vh))',
		borderRadius: '50% 50% 0 0',
		opacity: 1,
		textDecoration: 'none',
		willChange: 'transform',
		WebkitTapHighlightColor: 'transparent',
		backfaceVisibility: 'hidden',
		zIndex: 2
	},
	bowClose: {
		position: 'absolute',
		top: '2%',
		right: '3%',
		color: theme.palette.background.default
	},
	innerBorder: {
		position: 'absolute',
		top: 2,
		left: 2,
		width: 'calc(100% - 4px)',
		height: 'calc(100% - 4px)',
		background: 'transparent',
		borderRadius: 15,
		border: `2px solid ${theme.palette.background.default}`
	},
	expand: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'space-between',
		opacity: 1,
		width: 320,
		height: `clamp(0px, calc(calc(100 * var(--vh)) - ${
			60 + theme.spacing(3)
		}px), 500px)`,
		[theme.breakpoints.down('xs')]: {
			height: `clamp(0px, calc(calc(100 * var(--vh)) - ${
				30 + theme.spacing(3)
			}px), 510px)`
		},
		zIndex: 5
	},
	shrink: {
		width: 240,
		height: 240,
		[theme.breakpoints.down('xs')]: {
			width: 176,
			height: 176
		},
		zIndex: 2
	},
	emotionScroll: {
		position: 'relative',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		overflowY: 'auto',
		padding: theme.spacing(0, 1, 1, 1)
	},
	shadow: {
		boxShadow: '5px -5px 2px 2px rgba(0,0,0,0.1)'
	},
	emotionLabel: {
		textDecoration: 'none',
		position: 'fixed',
		bottom: 0,
		left: 0,
		zIndex: 5,
		display: 'flex',
		alignItems: 'center',
		wrap: 'nowrap',
		overflow: 'hidden',
		padding: theme.spacing(1)
	},
	emotionImageContainer: {
		borderRadius: '50%',
		padding: 'calc(1* var(--vh))'
	},
	emotionImage: {
		width: '100%',
		height: '100%',
		backgroundColor: 'rgba(255, 255, 255, 0.5)'
	},
	cloud: {
		position: 'absolute',
		top: 150,
		left: '50%',
		width: '75%',
		transform: 'translateX(-50%)'
	},
	cloudScreen: {
		height: 1000
	},
	center: {
		minWidth: 320 - theme.spacing(6),
		[theme.breakpoints.down('xs')]: {
			minWidth: 320 - theme.spacing(4)
		},
		margin: '0 auto',
		maxWidth: '100%'
	},
	rainbowCloudContainer: {
		position: 'relative',
		marginTop: theme.spacing(2),
		paddingTop: '52.7%'
	},
	rainbowCloud: {
		width: '100%',
		position: 'absolute',
		top: 0,
		left: 0
	},
	icon: {
		position: 'absolute',
		transform: 'translate(-50%, -50%)',
		width: '9%',
		'& svg': {
			width: '100%',
			height: '100%',
			fill: '#e0f3fc'
		},
		'&:hover svg': {
			fill: theme.palette.secondary.main,
			cursor: 'pointer'
		}
	},
	iconSelected: {
		'& svg': {
			fill: theme.palette.secondary.main
		}
	},
	iconTextSelected: {
		color: theme.palette.secondary.main
	},
	icon1: {
		left: '10%',
		top: '80%'
	},
	icon2: {
		top: '73%',
		left: '26%'
	},
	icon3: {
		top: '73%',
		left: '42%'
	},
	icon4: {
		top: '73%',
		left: '58%'
	},
	icon5: {
		top: '73%',
		left: '74%'
	},
	icon6: {
		top: '80%',
		left: '90%'
	},
	selectedIcon: {
		width: 50,
		margin: theme.spacing(1),
		'& svg': {
			fill: theme.palette.common.tan10
		}
	},
	bolt: {
		top: '57%',
		left: '50%',
		position: 'absolute',
		transform: 'translate(-50%, -50%)',
		width: '10%',
		'& svg': {
			width: '100%',
			height: '100%'
		}
	},
	chargeHeading: {
		fontFamily: "'Nanum Gothic', sans-serif",
		fontWeight: 700,
		color: theme.palette.secondary.main,
		margin: theme.spacing(1),
		textAlign: 'center',
		whiteSpace: 'nowrap'
	},
	chargeImage: {
		width: 100,
		position: 'absolute',
		top: '60%',
		left: '50%',
		transform: 'translate(-50%, -50%)'
	},
	emotionAvatar: {
		backgroundColor: 'rgba(255, 255, 255, 0.5)',
		margin: theme.spacing(1)
	},
	emotion: {
		position: 'absolute',
		top: 0,
		left: '50%',
		height: 'calc(100 * var(--vh))',
		transformOrigin: 'bottom center',
		transform: 'translate(-50%, 5px) rotate(30deg)',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center'
	},
	emotionRotate: {
		transform: 'translate(-50%, 5px) rotate(30deg)'
	},
	emotionCenter: {
		transform: 'translate(-50%, 5px)'
	},
	emotionContainer1: {
		position: 'relative',
		borderRadius: 10,
		padding: 5,
		margin: 0,
		backgroundColor: 'rgba(255, 255, 255, .5)',
		width: 'min-content',
		zIndex: 5
	},
	emotionContainer2: {
		borderRadius: '50%',
		padding: 0,
		margin: theme.spacing(1),
		// backgroundColor: 'rgba(0, 0, 0, .1)',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		width: '100%'
	},
	emotionContainer3: {
		padding: 5,
		margin: 0,
		width: 'min-content'
	},
	emotionTitle: {
		// color: 'rgba(0,0,0,0.7)',
		borderRadius: 5,
		// backgroundColor: 'rgba(0, 0, 0, .2)',
		backgroundColor: 'rgba(255, 255, 255, .6)',
		// color: '#fff',
		// color: theme.palette.grey[900],
		color: '#000',
		fontWeight: 800,
		padding: theme.spacing(0, 1)
	},
	degreeContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		position: 'relative',
		width: '100%',
		backgroundColor: 'rgba(255, 255, 255, 0.5)',
		borderRadius: 5,
		padding: theme.spacing(2, 2, 1, 2),
		marginTop: theme.spacing(1)
	},
	degreeTriangle: {
		position: 'absolute',
		left: 'calc(50% + 2px)',
		top: 15,
		[theme.breakpoints.down('xs')]: {
			top: 10
		},
		fill: 'rgba(255, 255, 255, 0.5)',
		transform: 'translateX(-50%)',
		height: 40,
		width: 210,
		margin: 0,
		padding: 0
	},
	degreeIcon: {
		position: 'absolute',
		top: theme.spacing(2),
		color: theme.palette.secondary.main
	},
	degreeIconLeft: {
		left: 9
	},
	degreeIconRight: {
		right: 9
	},
	degreeSlider: {
		width: 203,
		// backgroundColor: 'rgba(255, 255, 255, 0.6)',
		borderRadius: 5,
		margin: theme.spacing(2),
		padding: 0,
		zIndex: 5
	},
	degreeLabel: {
		display: 'flex',
		justifyContent: 'center',
		borderRadius: '0 0 5px 5px',
		backgroundColor: 'rgba(255, 255, 255, .5)',
		color: '#000',
		fontWeight: 700,
		padding: theme.spacing(0, 1)
	},
	emotionButtonContainer: {
		position: 'relative',
		width: '100%',
		padding: theme.spacing(1),
		// marginTop: theme.spacing(1),
		borderRadius: '0px 0px 5px 5px',
		// backgroundImage: `linear-gradient(to bottom,rgba(255, 255, 255, 0),  ${theme.palette.background.default})`,
		backgroundColor: 'rgba(255, 255, 255, 0.5)',
		display: 'flex',
		justifyContent: 'center'
	},
	chargeButtonContainer: {
		position: 'relative',
		width: '100%',
		padding: theme.spacing(3, 1, 1, 1),
		// marginTop: theme.spacing(1),
		borderRadius: '0px 0px 5px 5px',
		// backgroundImage: `linear-gradient(to bottom,rgba(255, 255, 255, 0),  ${theme.palette.background.default})`,
		backgroundColor: 'rgba(255, 255, 255, 0.5)',
		display: 'flex',
		justifyContent: 'center'
	},
	emotionButton: {
		fontWeight: 700,
		textTransform: 'none',
		fontSize: '1.3rem'
	},
	closeButton: {
		position: 'absolute',
		left: 5,
		top: 5,
		zIndex: 5,
		width: 30,
		height: 30,
		color: '#fff',
		backgroundColor: 'rgba(0, 0, 0, .2)'
	},
	audioButton: {
		position: 'absolute',
		[theme.breakpoints.down('xs')]: {
			left: theme.spacing(1)
		},
		[theme.breakpoints.up('sm')]: {
			right: theme.spacing(1)
		},
		zIndex: 5,
		width: 60,
		height: 60,
		color: theme.palette.background.default,
		// backgroundColor: 'rgba(255, 255, 255, .5)'
		backgroundColor: theme.palette.secondary.main,
		'&:hover': {
			backgroundColor: theme.palette.secondary.main
		}
	},
	happyAudioButton: {
		marginTop: theme.spacing(1),
		color: theme.palette.background.default,
		// backgroundColor: 'rgba(255, 255, 255, .5)'
		backgroundColor: theme.palette.secondary.main,
		'&:hover': {
			backgroundColor: theme.palette.secondary.main
		}
	},
	toolModal: {
		flexWrap: 'nowrap',
		position: 'absolute',
		left: '50%',
		transform: 'translateX(-50%)',
		width: 320,
		height: `calc(calc(100 * var(--vh)) - ${60 + theme.spacing(4)}px)`,
		[theme.breakpoints.down('xs')]: {
			height: `calc(calc(100 * var(--vh)) - ${30 + theme.spacing(4)}px)`
		},
		maxHeight: 430,
		padding: theme.spacing(0, 0, 1, 0)
	},
	toolModalTitle: {
		textAlign: 'center',
		fontWeight: 700
	},
	toolModalButton: {
		fontSize: '1.7rem',
		textTransform: 'none',
		fontWeight: 700
	},
	changeToolButton: {
		position: 'fixed',
		top: '50%',
		transform: 'translate(50%, -50%)',
		color: theme.palette.secondary.main,
		backgroundColor: theme.palette.background.default,
		zIndex: 4,
		padding: theme.spacing(1),
		'& svg': {
			width: 40,
			height: 40
		},
		'&:hover': {
			backgroundColor: theme.palette.background.default
		},
		'&:active': {
			backgroundColor: theme.palette.background.default
		}
	},
	nextToolButton: {
		right: 0,
		[theme.breakpoints.down('xs')]: {
			right: 30
		}
	},
	prevToolButton: {
		left: 0,
		transform: 'translate(-50%, -50%) rotate(180deg)',
		[theme.breakpoints.down('xs')]: {
			left: 30
		}
	},
	toolImageContainer: {
		position: 'relative',
		minHeight: 128,
		overflow: 'visible'
	},
	toolImagePaper: {
		padding: theme.spacing(1),
		position: 'absolute',
		top: 0,
		left: '50%',
		transform: 'translateX(-50%)',
		opacity: 0
	},
	toolImageText: {
		fontWeight: 700,
		fontSize: '1.3rem',
		marginTop: theme.spacing(1)
	},
	toolAudioButton: {
		margin: theme.spacing(0, 0, 2, 0),
		color: theme.palette.background.default,
		// backgroundColor: 'rgba(255, 255, 255, .5)'
		backgroundColor: theme.palette.secondary.main,
		'&:hover': {
			backgroundColor: theme.palette.secondary.main
		}
	},
	toolIndicatorContainer: {
		position: 'absolute',
		top: -theme.spacing(1),
		left: '50%',
		transform: 'translateX(-50%)',
		'& svg': {
			width: 15,
			heigth: 15,
			fill: theme.palette.grey[700]
		}
	},
	firstTool: {
		opacity: 1
	},
	toolInRight: {
		animation: '$tool-in-right 1s ease forwards'
	},
	toolInLeft: {
		animation: '$tool-in-left 1s ease forwards'
	},
	toolOutLeft: {
		animation: '$tool-out-left 1s ease forwards'
	},
	toolOutRight: {
		animation: '$tool-out-right 1s ease forwards'
	},
	'@keyframes tool-in-left': {
		'0%': {
			transform: 'translateX(50%)',
			opacity: 0
		},
		'100%': {
			transform: 'translateX(-50%)',
			opacity: 1
		}
	},
	'@keyframes tool-out-left': {
		'0%': {
			transform: 'translateX(-50%)',
			opacity: 1
		},
		'100%': {
			transform: 'translateX(-150%)',
			opacity: 0
		}
	},
	'@keyframes tool-in-right': {
		'0%': {
			transform: 'translateX(-150%)',
			opacity: 0
		},
		'100%': {
			transform: 'translateX(-50%)',
			opacity: 1
		}
	},
	'@keyframes tool-out-right': {
		'0%': {
			transform: 'translateX(-50%)',
			opacity: 1
		},
		'100%': {
			transform: 'translateX(50%)',
			opacity: 0
		}
	},
	fixed: {
		height: 'calc(100 * var(--vh))',
		width: '100%',
		position: 'fixed',
		top: 0,
		left: 0,
		zIndex: 3
	},
	iconText: {
		position: 'absolute',
		left: '50%',
		top: '100%',
		transform: 'translateX(-50%)',
		fontWeight: 1000,
		textAlign: 'center'
	}
}));

export default styles;
