import React from 'react';
import clsx from 'clsx';
import {
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableSortLabel,
	TableBody
} from '@material-ui/core';
import useCStyles from 'styles/cStyles';
import moment from 'moment';

const HistoryTable = ({
	tableRef = null,
	headCells,
	orderBy,
	order,
	rows,
	matchesXS,
	matchesSM,
	sortHandler,
	printFriendly
}) => {
	const cClasses = useCStyles();
	return (
		<Table
			size='small'
			className={clsx(cClasses.table, printFriendly && cClasses.printTable)}
			ref={tableRef}
		>
			<TableHead>
				<TableRow>
					{headCells.map(headCell => (
						<TableCell
							key={headCell.id}
							sortDirection={orderBy === headCell.id ? order : false}
						>
							<TableSortLabel
								active={orderBy === headCell.id}
								direction={orderBy === headCell.id ? order : 'asc'}
								onClick={e => sortHandler(e, headCell.id)}
								className={orderBy === headCell.id ? cClasses.sortLabel : null}
								style={{ whiteSpace: 'nowrap' }}
							>
								{headCell.label}
							</TableSortLabel>
						</TableCell>
					))}
				</TableRow>
			</TableHead>
			<TableBody>
				{!rows.length ? (
					<TableRow>
						<TableCell align='left' colSpan={headCells.length}>
							No history to display
						</TableCell>
					</TableRow>
				) : (
					rows.map(row => (
						<TableRow key={row._id}>
							<TableCell>
								{moment(row.createdAt).format('ddd Do MMM')}
							</TableCell>
							{(!matchesSM || printFriendly) && (
								<TableCell>{moment(row.createdAt).format('h:mm a')}</TableCell>
							)}
							<TableCell>{row.emotion}</TableCell>
							{(!matchesSM || printFriendly) && (
								<TableCell style={{ textTransform: 'capitalize' }}>
									{row.emotionType}
								</TableCell>
							)}
							{(!matchesXS || printFriendly) && (
								<TableCell>
									{row.degree}/{row.degreeRange}
								</TableCell>
							)}
							<TableCell>{row.tool}</TableCell>
							{(!matchesSM || printFriendly) && (
								<TableCell>{row.toolCategory}</TableCell>
							)}
							{(!matchesXS || printFriendly) && (
								<TableCell>{row.outcome}</TableCell>
							)}
						</TableRow>
					))
				)}
			</TableBody>
		</Table>
	);
};

export default HistoryTable;
