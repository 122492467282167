import React from 'react';
import clsx from 'clsx';
import {
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableSortLabel,
	TableBody,
	Button
} from '@material-ui/core';
import useCStyles from 'styles/cStyles';
import moment from 'moment';
import {useDispatch} from "react-redux";
import {
	cancelSubscriptionsAction,
	getUserAction
} from 'actions/userActions';

const HistoryTable = ({
	tableRef = null,
	headCells,
	orderBy,
	order,
	rows,
	sortHandler,
    matchesXS,
	matchesSM,
}) => {
	const cClasses = useCStyles();
	const dispatch = useDispatch();
	const handleCancel = (subId) => {
		dispatch(cancelSubscriptionsAction(subId));
		dispatch(getUserAction());
	}
	return (
		<Table
			size='small'
			ref={tableRef}
		>
			<TableHead>
				<TableRow>
					{headCells && headCells.map(headCell => (
						<TableCell
							key={headCell.id}
							sortDirection={orderBy === headCell.id ? order : false}
						>
							<TableSortLabel
								active={orderBy === headCell.id}
								direction={orderBy === headCell.id ? order : 'asc'}
								onClick={e => sortHandler(e, headCell.id)}
								className={orderBy === headCell.id ? cClasses.sortLabel : null}
								style={{ whiteSpace: 'nowrap' }}
							>
								{headCell.label}
							</TableSortLabel>
						</TableCell>
					))}
				</TableRow>
			</TableHead>
			<TableBody>
				{!rows ? (
					<TableRow>
						<TableCell align='left' colSpan={headCells.length}>
							No subscription to display
						</TableCell>
					</TableRow>
				) : (
					rows.map(row => (
						<TableRow key={row._id}>

							{!matchesSM && <TableCell>{row.type === 'month'?'Monthly':'Yearly'}</TableCell>}
							{!matchesSM && <TableCell style={{textTransform: "capitalize"}}>{row.status}</TableCell>}
							<TableCell>
								{row.type === 'month'?moment(row.start).add(1, 'months').format('MMM YYYY'):moment(row.start).add(1, 'years').format('MMM YYYY')}
							</TableCell>
							<TableCell>{row.status !== 'canceled' &&<Button onClick={()=>handleCancel(row.subId)}>Cancel</Button>}</TableCell>
						</TableRow>
					))
				)}
			</TableBody>
		</Table>
	);
};

export default HistoryTable;
