const negativeEmotions = [
	{
		title: 'Afraid',
		image: '/images/emotions/afraid.png',
		audio: '',
		color: '#ffffff'
	},
	{
		title: 'Afraid',
		image: '/images/emotions/afraid2.png',
		audio: '',
		color: '#ffffff'
	},
	{
		title: 'Afraid',
		image: '/images/emotions/afraid3.png',
		audio: '',
		color: '#ffffff'
	},
	{
		title: 'Angry',
		image: '/images/emotions/angry.png',
		audio: '',
		color: '#ffffff'
	},
	{
		title: 'Angry',
		image: '/images/emotions/angry2.png',
		audio: '',
		color: '#ffffff'
	},
	{
		title: 'Angry',
		image: '/images/emotions/angry3.png',
		audio: '',
		color: '#ffffff'
	},
	{
		title: 'Anxious',
		image: '/images/emotions/anxious.png',
		audio: '',
		color: '#ffffff'
	},
	{
		title: 'Anxious',
		image: '/images/emotions/anxious2.png',
		audio: '',
		color: '#ffffff'
	},
	{
		title: 'Anxious',
		image: '/images/emotions/anxious3.png',
		audio: '',
		color: '#ffffff'
	},
	{
		title: 'Ashamed',
		image: '/images/emotions/ashamed.png',
		audio: '',
		color: '#ffffff'
	},
	{
		title: 'Bored',
		image: '/images/emotions/bored.png',
		audio: '',
		color: '#ffffff'
	},
	{
		title: 'Bored',
		image: '/images/emotions/bored2.png',
		audio: '',
		color: '#ffffff'
	},
	{
		title: 'Bored',
		image: '/images/emotions/bored3.png',
		audio: '',
		color: '#ffffff'
	},
	{
		title: 'Confused',
		image: '/images/emotions/confused.png',
		audio: '',
		color: '#ffffff'
	},
	{
		title: 'Confused',
		image: '/images/emotions/confused2.png',
		audio: '',
		color: '#ffffff'
	},
	{
		title: 'Confused',
		image: '/images/emotions/confused3.png',
		audio: '',
		color: '#ffffff'
	},
	{
		title: 'Embarrassed',
		image: '/images/emotions/embarrassed.png',
		audio: '',
		color: '#ffffff'
	},
	{
		title: 'Embarrassed',
		image: '/images/emotions/embarrassed2.png',
		audio: '',
		color: '#ffffff'
	},
	{
		title: 'Guilty',
		image: '/images/emotions/guilty.png',
		audio: '',
		color: '#ffffff'
	},
	{
		title: 'Guilty',
		image: '/images/emotions/guilty2.png',
		audio: '',
		color: '#ffffff'
	},
	{
		title: 'Guilty',
		image: '/images/emotions/guilty3.png',
		audio: '',
		color: '#ffffff'
	},
	{
		title: 'Happy',
		image: '/images/emotions/happy.png',
		audio: '',
		color: '#ffffff'
	},
	{
		title: 'Happy',
		image: '/images/emotions/happy2.png',
		audio: '',
		color: '#ffffff'
	},
	{
		title: 'Happy',
		image: '/images/emotions/happy3.png',
		audio: '',
		color: '#ffffff'
	},
	{
		title: 'Homesick',
		image: '/images/emotions/homesick.png',
		audio: '',
		color: '#ffffff'
	},
	{
		title: 'Homesick',
		image: '/images/emotions/homesick2.png',
		audio: '',
		color: '#ffffff'
	},
	{
		title: 'Homesick',
		image: '/images/emotions/homesick3.png',
		audio: '',
		color: '#ffffff'
	},

	{
		title: 'Insecure',
		image: '/images/emotions/insecure.png',
		audio: '',
		color: '#ffffff'
	},
	{
		title: 'Insecure',
		image: '/images/emotions/insecure2.png',
		audio: '',
		color: '#ffffff'
	},
	{
		title: 'Insecure',
		image: '/images/emotions/insecure3.png',
		audio: '',
		color: '#ffffff'
	},
	{
		title: 'Jealous',
		image: '/images/emotions/jealous.png',
		audio: '',
		color: '#ffffff'
	},
	{
		title: 'Jealous',
		image: '/images/emotions/jealous2.png',
		audio: '',
		color: '#ffffff'
	},
	{
		title: 'Jealous',
		image: '/images/emotions/jealous3.png',
		audio: '',
		color: '#ffffff'
	},
	{
		title: 'Lonely',
		image: '/images/emotions/lonely.png',
		audio: '',
		color: '#ffffff'
	},
	{
		title: 'Lonely',
		image: '/images/emotions/lonely2.png',
		audio: '',
		color: '#ffffff'
	},
	{
		title: 'Lonely',
		image: '/images/emotions/lonely3.png',
		audio: '',
		color: '#ffffff'
	},
	{
		title: 'Mean',
		image: '/images/emotions/mean.png',
		audio: '',
		color: '#ffffff'
	},
	{
		title: 'Mean',
		image: '/images/emotions/mean2.png',
		audio: '',
		color: '#ffffff'
	},
	{
		title: 'Mean',
		image: '/images/emotions/mean3.png',
		audio: '',
		color: '#ffffff'
	},
	{
		title: 'Nervous',
		image: '/images/emotions/nervous.png',
		audio: '',
		color: '#ffffff'
	},
	{
		title: 'Nervous',
		image: '/images/emotions/nervous2.png',
		audio: '',
		color: '#ffffff'
	},
	{
		title: 'Nervous',
		image: '/images/emotions/nervous3.png',
		audio: '',
		color: '#ffffff'
	},
	{
		title: 'Sad',
		image: '/images/emotions/sad.png',
		audio: '',
		color: '#ffffff'
	},
	{
		title: 'Sad',
		image: '/images/emotions/sad2.png',
		audio: '',
		color: '#ffffff'
	},
	{
		title: 'Sad',
		image: '/images/emotions/sad3.png',
		audio: '',
		color: '#ffffff'
	},
	{
		title: 'Shocked',
		image: '/images/emotions/shocked.png',
		audio: '',
		color: '#ffffff'
	},
	{
		title: 'Shocked',
		image: '/images/emotions/shocked2.png',
		audio: '',
		color: '#ffffff'
	},
	{
		title: 'Shocked',
		image: '/images/emotions/shocked3.png',
		audio: '',
		color: '#ffffff'
	},
	{
		title: 'Stubborn',
		image: '/images/emotions/stubborn.png',
		audio: '',
		color: '#ffffff'
	},
	{
		title: 'Stubborn',
		image: '/images/emotions/stubborn2.png',
		audio: '',
		color: '#ffffff'
	},
	{
		title: 'Stubborn',
		image: '/images/emotions/stubborn3.png',
		audio: '',
		color: '#ffffff'
	},
	{
		title: 'Worried',
		image: '/images/emotions/worried.png',
		audio: '',
		color: '#ffffff'
	},
	{
		title: 'Worried',
		image: '/images/emotions/worried2.png',
		audio: '',
		color: '#ffffff'
	},
	{
		title: 'Worried',
		image: '/images/emotions/worried3.png',
		audio: '',
		color: '#ffffff'
	}
];

export default negativeEmotions;
