import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
	BrowserRouter as Router,
	Redirect,
	Route,
	Switch
} from 'react-router-dom';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import AuthScreen from 'screens/AuthScreen';
import ProfileScreen from 'screens/ProfileScreen';
import PageNotFoundScreen from 'screens/PageNotFoundScreen';
import VerifyScreen from 'screens/VerifyScreen';
import ResetPasswordScreen from 'screens/ResetPasswordScreen';
import ForgotPasswordScreen from 'screens/ForgotPasswordScreen';
import ContactScreen from 'screens/ContactScreen';
import TermsScreen from 'screens/TermsScreen';
import PrivacyScreen from 'screens/PrivacyScreen';
import UserFeedback from 'components/UserFeedback';
import Menu from 'components/Menu';
import Rainbow from 'components/Rainbow';
import { getTheme } from 'styles/theme';
import AboutScreen from 'screens/AboutScreen';
import SettingsScreen from 'screens/SettingsScreen';
import ChargeToolkit from 'components/ChargeToolkit';
import useStyles from 'styles/aStyles';
import SettingsMessage from 'components/SettingsMessage';
import SummaryMessage from 'components/SummaryMessage';
import { getUserAction } from 'actions/userActions';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const theme = getTheme();

axios.defaults.baseURL = process.env.API_BASEURL || 'https://api.rainbowofemotions.app';

const getStripePromise = async () => {
	const { data: stripePublishableKey } = await axios.get(
		'/api/v1/config/stripe'
	);
	return loadStripe(stripePublishableKey);
};
const stripePromise = getStripePromise();

const App = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const { isAuth, user } = useSelector(state => state.userData);

	const { settingsMessageIsOpen, toolkitIsOpen, summary } = useSelector(
		state => state.rainbowState
	);
	const [authFormEmail, setAuthFormEmail] = useState('');
	const [innerHeight, setInnerHeight] = useState(window.innerHeight);
	const [showRainbow, setShowRainbow] = useState(false);
	axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;
	useEffect(() => {
		window.addEventListener('resize', handleResize);
		handleResize();
		setShowRainbow(true);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	useEffect(() => {
		if (isAuth) dispatch(getUserAction());
	}, [isAuth, dispatch]);

	const handleResize = () => {
		setInnerHeight(window.innerHeight);
		document
			.querySelector(':root')
			.style.setProperty('--vh', window.innerHeight / 100 + 'px');
	};

	return (
		<Elements stripe={stripePromise}>
			<Router>
				<ThemeProvider theme={theme}>
					<CssBaseline />
					{showRainbow && <Rainbow innerHeight={innerHeight} />}

					<main className={classes.main}>
						<UserFeedback authFormEmail={authFormEmail} />
						{settingsMessageIsOpen && <SettingsMessage />}
						{!!summary && <SummaryMessage />}
						{toolkitIsOpen && <ChargeToolkit innerHeight={innerHeight} />}
						<Menu />
						{isAuth && user.isVerified && user.isAdmin ? (
							// Admin routes
							<Switch>
								<Route path='/' exact />
								<Route path='/about' exact>
									<AboutScreen innerHeight={innerHeight} />
								</Route>
								<Redirect from='/login' exact to='/profile' />
								<Route path='/verifyuser/:token' component={VerifyScreen} />
								<Route path='/verifyemail/:token' component={VerifyScreen} />
								<Route path='/profile' exact component={ProfileScreen} />
								<Route path='/settings' exact component={SettingsScreen} />
								<Redirect from='/resetpassword' to='/profile' />
								<Redirect from='/forgotpassword' exact to='/profile' />
								<Route path='/contact' exact component={ContactScreen} />
								<Route path='/terms' exact component={TermsScreen} />
								<Route path='/privacy' exact component={PrivacyScreen} />
								<Route path='/' component={PageNotFoundScreen} />
							</Switch>
						) : isAuth && user.isVerified ? (
							// Private routes
							<Switch>
								<Route path='/' exact />
								<Route path='/about' exact>
									<AboutScreen innerHeight={innerHeight} />
								</Route>
								<Redirect from='/login' exact to='/profile' />
								<Route path='/verifyuser/:token' component={VerifyScreen} />
								<Route path='/verifyemail/:token' component={VerifyScreen} />
								<Route path='/profile' exact component={ProfileScreen} />
								<Route path='/settings' exact component={SettingsScreen} />
								<Redirect from='/resetpassword' to='/profile' />
								<Redirect from='/forgotpassword' exact to='/profile' />
								<Redirect from='/admin' to='/profile' />
								<Route path='/contact' exact component={ContactScreen} />
								<Route path='/terms' exact component={TermsScreen} />
								<Route path='/privacy' exact component={PrivacyScreen} />
								<Route path='/' component={PageNotFoundScreen} />
							</Switch>
						) : (
							// Public routes
							<Switch>
								<Route path='/' exact />
								<Route path='/about' exact>
									<AboutScreen innerHeight={innerHeight} />
								</Route>
								<Route path='/login' exact>
									<AuthScreen setEmail={setAuthFormEmail} />
								</Route>
								<Redirect from='/profile' exact to='/login' />
								<Redirect from='/settings' exact to='/login' />
								<Route path='/verifyuser/:token' component={VerifyScreen} />
								<Route path='/verifyemail/:token' component={VerifyScreen} />
								<Route
									path='/resetpassword/:token'
									component={ResetPasswordScreen}
								/>
								<Route
									path='/forgotpassword'
									exact
									component={ForgotPasswordScreen}
								/>
								<Redirect from='/admin' to='/login' />
								<Route path='/contact' exact component={ContactScreen} />
								<Route path='/terms' exact component={TermsScreen} />
								<Route path='/privacy' exact component={PrivacyScreen} />
								<Route path='/' component={PageNotFoundScreen} />
							</Switch>
						)}
					</main>
					{/* <Footer /> */}
				</ThemeProvider>
			</Router>
		</Elements>
	);
};

export default App;
