const physicalEmotions = [
	{
		title: 'Cold',
		image: '/images/emotions/cold.png',
		audio: '',
		color: '#ffffff'
	},
	{
		title: 'Cold',
		image: '/images/emotions/cold2.png',
		audio: '',
		color: '#ffffff'
	},
	{
		title: 'Cold',
		image: '/images/emotions/cold3.png',
		audio: '',
		color: '#ffffff'
	},
	{
		title: 'Hot',
		image: '/images/emotions/hot.png',
		audio: '',
		color: '#ffffff'
	},
	{
		title: 'Hot',
		image: '/images/emotions/hot2.png',
		audio: '',
		color: '#ffffff'
	},
	{
		title: 'Hot',
		image: '/images/emotions/hot3.png',
		audio: '',
		color: '#ffffff'
	},
	{
		title: 'Hungry',
		image: '/images/emotions/hungry.png',
		audio: '',
		color: '#ffffff'
	},
	{
		title: 'Hungry',
		image: '/images/emotions/hungry2.png',
		audio: '',
		color: '#ffffff'
	},
	{
		title: 'Hungry',
		image: '/images/emotions/hungry3.png',
		audio: '',
		color: '#ffffff'
	},
	{
		title: 'Hurt',
		image: '/images/emotions/hurt.png',
		audio: '',
		color: '#ffffff'
	},
	{
		title: 'Hurt',
		image: '/images/emotions/hurt2.png',
		audio: '',
		color: '#ffffff'
	},
	{
		title: 'Hurt',
		image: '/images/emotions/hurt3.png',
		audio: '',
		color: '#ffffff'
	},
	{
		title: 'Lost',
		image: '/images/emotions/lost.png',
		audio: '',
		color: '#ffffff'
	},
	{
		title: 'Lost',
		image: '/images/emotions/lost2.png',
		audio: '',
		color: '#ffffff'
	},
	{
		title: 'Lost',
		image: '/images/emotions/lost3.png',
		audio: '',
		color: '#ffffff'
	},
	{
		title: 'Nauseated',
		image: '/images/emotions/nauseated.png',
		audio: '',
		color: '#ffffff'
	},
	{
		title: 'Nauseated',
		image: '/images/emotions/nauseated2.png',
		audio: '',
		color: '#ffffff'
	},
	{
		title: 'Nauseated',
		image: '/images/emotions/nauseated3.png',
		audio: '',
		color: '#ffffff'
	},
	{
		title: 'Sick',
		image: '/images/emotions/sick.png',
		audio: '',
		color: '#ffffff'
	},
	{
		title: 'Sick',
		image: '/images/emotions/sick2.png',
		audio: '',
		color: '#ffffff'
	},
	{
		title: 'Sick',
		image: '/images/emotions/sick3.png',
		audio: '',
		color: '#ffffff'
	},
	{
		title: 'Sore',
		image: '/images/emotions/sore.png',
		audio: '',
		color: '#ffffff'
	},
	{
		title: 'Sore',
		image: '/images/emotions/sore2.png',
		audio: '',
		color: '#ffffff'
	},
	{
		title: 'Sore',
		image: '/images/emotions/sore3.png',
		audio: '',
		color: '#ffffff'
	},
	{
		title: 'Thirsty',
		image: '/images/emotions/thirsty.png',
		audio: '',
		color: '#ffffff'
	},
	{
		title: 'Thirsty',
		image: '/images/emotions/thirsty2.png',
		audio: '',
		color: '#ffffff'
	},
	{
		title: 'Thirsty',
		image: '/images/emotions/thirsty3.png',
		audio: '',
		color: '#ffffff'
	},
	{
		title: 'Tired',
		image: '/images/emotions/tired.png',
		audio: '',
		color: '#ffffff'
	},
	{
		title: 'Tired',
		image: '/images/emotions/tired2.png',
		audio: '',
		color: '#ffffff'
	},
	{
		title: 'Tired',
		image: '/images/emotions/tired3.png',
		audio: '',
		color: '#ffffff'
	},
	{
		title: 'Uncomfortable',
		image: '/images/emotions/uncomfortable.png',
		audio: '',
		color: '#ffffff'
	},
	{
		title: 'Uncomfortable',
		image: '/images/emotions/uncomfortable2.png',
		audio: '',
		color: '#ffffff'
	},
	{
		title: 'Uncomfortable',
		image: '/images/emotions/uncomfortable3.png',
		audio: '',
		color: '#ffffff'
	}
];

export default physicalEmotions;
