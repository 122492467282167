import React, { useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import {
	CircularProgress,
	Button,
	TextField,
	Typography,
	Paper,
	IconButton,
	Divider,
	useTheme,
	Grid, MenuItem, Select
} from '@material-ui/core';
import useAuthForm from 'hooks/authFormHook';
import {
	loginAction,
	signupAction,
	sendVerifyUserAction
} from 'actions/userActions';
import useBStyles from 'styles/bStyles';
import useCStyles from 'styles/cStyles';
import { Close } from '@material-ui/icons';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import {
	SIGNUP_FAIL,
	SIGNUP_REQUEST,
	SIGNUP_SUCCESS
} from 'constants/userConstants';

const AuthScreen = ({ setEmail }) => {
	const theme = useTheme();
	const bClasses = useBStyles();
	const cClasses = useCStyles();
	const dispatch = useDispatch();
	const stripe = useStripe();
	const elements = useElements();

	const [formState, formDispatch] = useAuthForm();
	const { formIsValid, isLoginMode, inputs } = formState;
	const [numberOfUsers, setNumberOfUsers] = useState(1);
	const [selectedPrice, setSelectedPrice] = useState(1);
	const [selectedPriceType, setSelectedPriceType] = useState('yearly');
	const [totalPrice, setTotalPrice] = useState(99);
	const [purchaseMethod, setPurchaseMethod] = useState('card');
	const [userCode, setUserCode] = useState({
		value: '',
		isValid: false,
		isTouched: false
	});

	const signup = useSelector(state => state.signup);
	const { loading: signupLoading } = signup;

	const login = useSelector(state => state.login);
	const { loading: loginLoading } = login;

	const changeHandler = (e, validators) => {
		formDispatch({ type: 'CHANGE', payload: e.target, validators });
		if (e.target.id === 'email') {
			setEmail(e.target.value);
		}
	};

	const touchHandler = e => {
		if (!formState.inputs[e.target.id].isTouched) {
			formDispatch({ type: 'TOUCH', payload: e.target });
		}
	};

	const switchModeHandler = () => {
		formDispatch({ type: 'SWITCH_MODE' });
	};

	const submitHandler = async e => {
		e.preventDefault();
		if (isLoginMode) {
			dispatch(loginAction(inputs.email.value, inputs.password.value));
		} else if (!isLoginMode && purchaseMethod === 'card') {
			try {
				// signupLoading = true
				dispatch({
					type: SIGNUP_REQUEST
				});
				// get React card element
				const cardElement = elements.getElement(CardElement);
				// use card element to create payment method
				const { error, paymentMethod } = await stripe.createPaymentMethod({
					type: 'card',
					card: cardElement
				});
				if (error) throw error;

				const config = {
					headers: {
						'Content-Type': 'application/json'
					}
				};
				// retrive client secret for transation from backend
				/*const {
					data: { clientSecret, numberOfUsers: resNumberOfUsers }
				} = await axios.post(
					'/api/v1/users/payment-intent',
					{
						numberOfUsers
					},
					config
				);*/

				// signup user (invalid until email sent)
				await axios.post(
					'/api/v1/users/signup',
					{
						name: inputs.name.value,
						email: inputs.email.value,
						password: inputs.password.value,
						numberOfUsers: numberOfUsers,
						paymentMethod: paymentMethod.id,
						selectedPriceType: selectedPriceType,
						userCode: null
					},
					config
				);

				// confirm payment
				/*await stripe.confirmCardPayment(clientSecret, {
					payment_method: paymentMethod.id
				});*/

				// signup loading = false
				// display success message
				dispatch({
					type: SIGNUP_SUCCESS,
					payload:
						'Please check your inbox to confirm your email address (You may need to check your junk/spam folder)'
				});
				// send confirmation email with verification code and user codes
				dispatch(sendVerifyUserAction(inputs.email.value));
			} catch (err) {
				dispatch({
					type: SIGNUP_FAIL,
					payload:
						err.response && err.response.data.message
							? err.response.data.message
							: err.message
				});
			}
		} else {
			dispatch(
				signupAction({
					name: inputs.name.value,
					email: inputs.email.value,
					password: inputs.password.value,
					numberOfUsers,
					userCode: userCode.value
				})
			);
		}
	};

	const getPricePerUnitByType = (type = 'yearly', numberOfUsers) => {
		switch (type) {
			case 'yearly': {
				if (numberOfUsers < 10) {
					return 99.00;
				} else if (numberOfUsers < 20) {
					return 78.00;
				} else if (numberOfUsers >= 20) {
					return 44.00;
				}
			}
			case 'monthly': {
				if (numberOfUsers < 10) {
					return 9.99;
				} else if (numberOfUsers < 20) {
					return 7.77;
				} else if (numberOfUsers >= 20) {
					return 4.44;
				}
			}
			default:
				return 99;
		}
		return 99;
	}

	const numberOfUsersHandler = (e, action) => {
		const value = action.type === 'RANGE' ? action.value : e.target.value;
		if (value > 0) {
			setNumberOfUsers(value);
			const pricePerUnitByType = getPricePerUnitByType(selectedPriceType, value);
			if (value < 10) {
				setSelectedPrice(1);
			} else if (value < 20) {
				setSelectedPrice(2);
			} else if (value >= 20) {
				setSelectedPrice(3);
			}
			setTotalPrice((value * pricePerUnitByType).toFixed(2));
		}
	};

	const handleChangeSelectedPriceType = (value) => {
		setSelectedPriceType(value);
		const pricePerUnitByType = getPricePerUnitByType(value, numberOfUsers);
		setTotalPrice((numberOfUsers * pricePerUnitByType).toFixed(2));
	}

	const purchaseMethodHandler = value => {
		setPurchaseMethod(value);
		if (value === 'code') setNumberOfUsers(1);
	};

	const userCodeChangeHandler = e => {
		setUserCode({
			...userCode,
			value: e.target.value,
			isValid: e.target.value.length === 8
		});
	};

	const userCodeTouchHandler = e => {
		setUserCode({ ...userCode, isTouched: true });
	};

	return (
		<Paper
			classes={{ root: cClasses.screenRoot }}
			elevation={5}
			style={{ width: 'min-content' }}
		>
			<IconButton className={cClasses.closeButton} component={Link} to='/'>
				<Close fontSize='small' />
			</IconButton>
			<form className={bClasses.form} onSubmit={submitHandler}>
				<Typography variant='h1'>
					{isLoginMode ? 'Log In' : 'Sign Up'}
				</Typography>
				{Object.keys(inputs).map(input => {
					const capitalized = input.charAt(0).toUpperCase() + input.slice(1);
					if (
						isLoginMode &&
						(input === 'name' || input === 'confirmPassword')
					) {
						return <React.Fragment key={input}></React.Fragment>;
					} else {
						return (
							<TextField
								key={input}
								id={input}
								label={capitalized}
								type={
									input === 'name'
										? 'text'
										: input === 'email'
										? 'email'
										: 'password'
								}
								placeholder={capitalized}
								fullWidth
								color='secondary'
								className={
									inputs[input].isTouched && !inputs[input].isValid
										? clsx(bClasses.input, bClasses.error)
										: bClasses.input
								}
								onChange={e => changeHandler(e, inputs[input].validators)}
								onBlur={touchHandler}
								value={inputs[input].value}
								error={inputs[input].isTouched && !inputs[input].isValid}
								helperText={
									inputs[input].isTouched && !inputs[input].isValid
										? inputs[input].helperText
										: ' '
								}
							/>
						);
					}
				})}
				{!isLoginMode && (
					<>
						<Grid container justifyContent='center'>
							<Grid
								item
								xs={6}
								container
								justifyContent='flex-end'
								style={{ padding: theme.spacing(1) }}
							>
								<Button
									variant={purchaseMethod === 'card' ? 'contained' : 'outlined'}
									color='secondary'
									style={{ textTransform: 'none', fontWeight: 700 }}
									onClick={() => purchaseMethodHandler('card')}
								>
									Credit card
								</Button>
							</Grid>
							<Grid
								item
								xs={6}
								container
								justifyContent='flex-start'
								style={{ padding: theme.spacing(1) }}
							>
								<Button
									variant={purchaseMethod === 'code' ? 'contained' : 'outlined'}
									color='secondary'
									style={{ textTransform: 'none', fontWeight: 700 }}
									onClick={() => purchaseMethodHandler('code')}
								>
									User code
								</Button>
							</Grid>
						</Grid>
						<TextField
							id='numberOfUsers'
							label='Number of Users'
							type='number'
							placeholder='Number of Users'
							fullWidth
							color='secondary'
							value={numberOfUsers}
							onChange={e => numberOfUsersHandler(e, { type: 'INPUT' })}
							className={clsx(bClasses.input, bClasses.numberUsersInput)}
							disabled={purchaseMethod === 'code'}
						/>
						<Select
							labelId="plan-simple-select-label"
							id="plan-simple-select"
							value={selectedPriceType}
							onChange={(event) => handleChangeSelectedPriceType(event.target.value)}
							className={clsx(bClasses.numberUsersInput)}
						>
							<MenuItem value='monthly'>Monthly</MenuItem>
							<MenuItem value='yearly'>Yearly</MenuItem>
						</Select>
						{purchaseMethod === 'card' ? (
							<>
								<Grid container>
									<Grid
										item
										xs={4}
										className={bClasses.priceGridItem}
										container
										justifyContent='center'
										style={{ padding: selectedPrice === 1 ? 0 : '' }}
									>
										<Button
											className={clsx(
												selectedPrice === 1 && bClasses.selectedPrice
											)}
											variant={selectedPrice === 1 ? 'contained' : 'outlined'}
											color={selectedPrice === 1 ? 'secondary' : 'default'}
											style={{ padding: theme.spacing(1) }}
											onClick={e =>
												numberOfUsersHandler(e, { type: 'RANGE', value: 1 })
											}
										>
											<Grid
												container
												direction='column'
												alignItems='center'
												variant='outlined'
												justifyContent='center'
											>
												<Typography>1-9 Users</Typography>
												<Typography>${getPricePerUnitByType(selectedPriceType,1)} ea</Typography>
											</Grid>
										</Button>
									</Grid>
									<Grid
										item
										xs={4}
										className={bClasses.priceGridItem}
										container
										justifyContent='center'
										style={{ padding: selectedPrice === 2 ? 0 : '' }}
									>
										<Button
											className={clsx(
												selectedPrice === 2 && bClasses.selectedPrice
											)}
											style={{ padding: theme.spacing(1) }}
											variant={selectedPrice === 2 ? 'contained' : 'outlined'}
											color={selectedPrice === 2 ? 'secondary' : 'default'}
											onClick={e =>
												numberOfUsersHandler(e, { type: 'RANGE', value: 10 })
											}
										>
											<Grid
												container
												direction='column'
												alignItems='center'
												variant='outlined'
												justifyContent='center'
											>
												<Typography>10-19 Users</Typography>
												<Typography>${getPricePerUnitByType(selectedPriceType,10)} ea</Typography>
											</Grid>
										</Button>
									</Grid>
									<Grid
										item
										xs={4}
										className={bClasses.priceGridItem}
										container
										justifyContent='center'
										style={{ padding: selectedPrice === 3 ? 0 : '' }}
									>
										<Button
											className={clsx(
												selectedPrice === 3 && bClasses.selectedPrice
											)}
											style={{ padding: theme.spacing(1) }}
											variant={selectedPrice === 3 ? 'contained' : 'outlined'}
											color={selectedPrice === 3 ? 'secondary' : 'default'}
											onClick={e =>
												numberOfUsersHandler(e, { type: 'RANGE', value: 20 })
											}
										>
											<Grid
												container
												direction='column'
												alignItems='center'
												justifyContent='center'
											>
												<Typography>20+ Users</Typography>
												<Typography>${getPricePerUnitByType(selectedPriceType,20)} ea</Typography>
											</Grid>
										</Button>
									</Grid>
								</Grid>
								<Grid>
									<Typography className={bClasses.totalPrice}>
										<span className={bClasses.total}>Total:</span> ${totalPrice}
										<span className={bClasses.currency}> AUD</span>
									</Typography>
								</Grid>
								<Grid container direction='column'>
									<Typography className={bClasses.cardInputLabel}>
										Pay with credit card:
									</Typography>
									<Paper
										variant='outlined'
										style={{ width: '100%' }}
										className={clsx(
											bClasses.cardInputPaper,
											(!stripe || !elements) && bClasses.cardDisabled
										)}
									>
										{stripe && elements ? (
											<CardElement
												options={{
													style: {
														base: {
															fontSize: '18px',
															color: theme.palette.text.primary,
															'::placeholder': {
																color: theme.palette.text.primary
															}
														},
														invalid: {
															color: theme.palette.error.main
														}
													}
												}}
												className={bClasses.cardInput}
											/>
										) : (
											<Typography variant='body2'>
												Loading credit card input...
											</Typography>
										)}
									</Paper>
								</Grid>
							</>
						) : (
							<TextField
								id='userCode'
								label='User Code'
								type='text'
								placeholder='User Code'
								fullWidth
								color='secondary'
								className={
									userCode.isTouched && !userCode.isValid
										? clsx(bClasses.input, bClasses.error)
										: bClasses.input
								}
								onChange={userCodeChangeHandler}
								onBlur={userCodeTouchHandler}
								value={userCode.value}
								error={userCode.isTouched && !userCode.isValid}
								helperText={
									userCode.isTouched && !userCode.isValid
										? 'User code must be 8 characters long'
										: ' '
								}
							/>
						)}
					</>
				)}
				<Button
					className={bClasses.button}
					type='submit'
					disabled={
						!formIsValid ||
						(!isLoginMode &&
							purchaseMethod === 'card' &&
							(!stripe || !elements)) ||
						(!isLoginMode && purchaseMethod === 'code' && !userCode.isValid)
					}
					variant='contained'
					color='secondary'
					fullWidth
				>
					{signupLoading || loginLoading ? (
						<CircularProgress size={25} className={bClasses.submitProgress} />
					) : isLoginMode ? (
						'Log In'
					) : (
						'Sign Up'
					)}
				</Button>
				<Button
					size='small'
					variant='outlined'
					className={bClasses.button2}
					color='secondary'
					onClick={switchModeHandler}
				>
					Switch to {isLoginMode ? 'Sign Up' : 'Log In'}
				</Button>
				<Button
					size='small'
					className={bClasses.button3}
					component={Link}
					to='/forgotpassword'
				>
					Forgot Password?
				</Button>
			</form>
			<Divider style={{ marginTop: theme.spacing(1) }} />
			<Grid container justifyContent='space-between'>
				<Button
					color='secondary'
					style={{ textTransform: 'none', fontWeight: 600 }}
					component={Link}
					to='/contact'
				>
					Contact
				</Button>
				<Button
					color='secondary'
					style={{ textTransform: 'none', fontWeight: 600 }}
					component={Link}
					to='/terms'
				>
					Terms & Conditions
				</Button>
				<Button
					color='secondary'
					style={{ textTransform: 'none', fontWeight: 600 }}
					component={Link}
					to='/privacy'
				>
					Privacy Policy
				</Button>
			</Grid>
		</Paper>
	);
};

export default AuthScreen;
