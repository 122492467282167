const schedule = [
	{
		start: 600,
		end: 615,
		title: 'Shower',
		image: '/images/tools/goodRoutine/shower.png'
	},
	{
		start: 615,
		end: 700,
		title: 'Exercise',
		image: '/images/tools/goodRoutine/exercises-on-time2.png'
	},
	{
		start: 710,
		end: 730,
		title: 'Breakfast',
		image: '/images/tools/goodRoutine/breakfast.png'
	},
	{
		start: 1000,
		end: 1100,
		title: 'Drink enough water',
		image: '/images/tools/goodRoutine/drink-enough-water.png'
	},
	{
		start: 1300,
		end: 1330,
		title: 'Lunch',
		image: '/images/tools/goodRoutine/lunch.png'
	},
	{
		start: 1700,
		end: 1800,
		title: 'Rest break',
		image: '/images/tools/goodRoutine/enough-rest.png'
	},
	{
		start: 1830,
		end: 1930,
		title: 'Dinner',
		image: '/images/tools/goodRoutine/dinner.png'
	},
	{
		start: 2130,
		end: 2200,
		title: 'Go to bed',
		image: '/images/tools/goodRoutine/go-to-bed.png'
	}
];

export default schedule;
