import React, { useState } from 'react';
import clsx from 'clsx';
import {
	Button,
	CircularProgress,
	TextField,
	Typography,
	Paper
} from '@material-ui/core';
import { VALIDATOR_REQUIRE, VALIDATOR_EMAIL, validate } from 'utils/validators';
import { forgotPasswordAction } from 'actions/userActions';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import useBStyles from 'styles/bStyles';
import useCStyles from 'styles/cStyles';

const ForgotPasswordScreen = () => {
	const bClasses = useBStyles();
	const cClasses = useCStyles();
	const dispatch = useDispatch();

	const { loading } = useSelector(state => state.forgotPassword);

	const [email, setEmail] = useState({
		value: '',
		isValid: false,
		isTouched: false
	});

	const changeHandler = (e, validators) => {
		setEmail({
			...email,
			isValid: validate(e.target.value, validators),
			value: e.target.value
		});
	};

	const touchHandler = () => {
		setEmail({ ...email, isTouched: true });
	};

	const submitHandler = e => {
		e.preventDefault();
		dispatch(forgotPasswordAction(email.value));
	};

	return (
		<Paper
			classes={{ root: cClasses.screenRoot }}
			variant='outlined'
			style={{ width: 'min-content' }}
		>
			<form className={bClasses.form} onSubmit={submitHandler}>
				<Typography variant='h2' style={{ textAlign: 'center' }}>
					Forgot Password
				</Typography>
				<TextField
					id='email'
					label='Email'
					type='email'
					placeholder='Email'
					fullWidth
					color='secondary'
					className={
						email.isTouched && !email.isValid
							? clsx(bClasses.input, bClasses.error)
							: bClasses.input
					}
					onChange={e =>
						changeHandler(e, [VALIDATOR_REQUIRE(), VALIDATOR_EMAIL()])
					}
					onBlur={touchHandler}
					value={email.value}
					error={email.isTouched && !email.isValid}
					helperText={
						email.isTouched && !email.isValid
							? 'Please enter a valid email address'
							: ' '
					}
				/>
				<Button
					className={bClasses.button}
					type='submit'
					disabled={!email.isValid}
					variant='contained'
					color='secondary'
					fullWidth
				>
					{loading ? (
						<CircularProgress size={25} className={bClasses.submitProgress} />
					) : (
						'Send Email'
					)}
				</Button>
				<Button
					size='small'
					className={bClasses.button3}
					component={Link}
					to='/login'
				>
					Back to Log In
				</Button>
			</form>
		</Paper>
	);
};

export default ForgotPasswordScreen;
