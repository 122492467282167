import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Fab, useMediaQuery } from '@material-ui/core';
import { Help, Person, Settings } from '@material-ui/icons';
import useStyles from 'styles/aStyles';
import {
	CLOSE_EMOTION,
	TOGGLE_SETTINGS_MESSAGE,
	CLOSE_TOOLKIT
} from 'constants/rainbowConstants';
import { logoutAction } from 'actions/userActions';

const Menu = () => {
	const classes = useStyles();
	const location = useLocation();
	const history = useHistory();
	const dispatch = useDispatch();
	const matchesXS = useMediaQuery(theme => theme.breakpoints.down('xs'));
	const { isAuth, user } = useSelector(state => state.userData);

	const [aboutRedirect, setAboutRedirect] = useState(true);

	const onUserPage =
		location.pathname === '/profile' || location.pathname === '/login';
	const onSettingsPage = location.pathname.startsWith('/settings');
	const onAboutPage = location.pathname === '/about';

	const handleButton = (buttonType = 'standard') => {
		if (buttonType === 'settings' && !isAuth)
			dispatch({ type: TOGGLE_SETTINGS_MESSAGE });
		dispatch({ type: CLOSE_EMOTION });
		dispatch({ type: CLOSE_TOOLKIT });
	};

	useEffect(() => {
		if (
			!isAuth &&
			aboutRedirect &&
			!location.pathname.includes('verifyuser') &&
			!location.pathname.includes('resetpassword')
		) {
			history.push('/about');
			setAboutRedirect(false);
		}
	}, [isAuth, aboutRedirect, history, location]);

	useEffect(() => {
		if (user && !user?.isVerified) {
			dispatch(logoutAction());
			history.push('/login');
		}
	}, [user, dispatch, history]);

	return (
		<>
			<Fab
				component={Link}
				color='secondary'
				className={clsx(
					classes.fab,
					classes.fab1,
					onUserPage && classes.fabFocus
				)}
				size='large'
				to={onUserPage ? '/' : isAuth ? '/profile' : '/login'}
				onClick={handleButton}
				style={!matchesXS ? { width: 80, height: 80 } : null}
			>
				<Person fontSize={!matchesXS ? 'large' : 'medium'} />
			</Fab>
			<Fab
				component={Link}
				color='secondary'
				className={clsx(
					classes.fab,
					classes.fab2,
					onSettingsPage && classes.fabFocus
				)}
				size={!matchesXS ? 'medium' : 'small'}
				to={onSettingsPage ? '/' : isAuth ? '/settings' : '/login'}
				onClick={() => handleButton('settings')}
			>
				<Settings fontSize={!matchesXS ? 'large' : 'medium'} />
			</Fab>
			<Fab
				component={Link}
				color='secondary'
				className={clsx(
					classes.fab,
					classes.fab3,
					onAboutPage && classes.fabFocus
				)}
				size={!matchesXS ? 'medium' : 'small'}
				to={onAboutPage ? '/' : '/about'}
				onClick={handleButton}
			>
				<Help fontSize={!matchesXS ? 'large' : 'medium'} />
			</Fab>
		</>
	);
};

export default Menu;
