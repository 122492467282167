import React, {useCallback, useEffect, useRef, useState} from 'react';
import clsx from 'clsx';
import {
	Fade,
	Grid,
	IconButton,
	Modal,
	Typography,
	Paper,
	TextField,
	Button,
	Avatar,
	CircularProgress,
	useTheme,
	useMediaQuery
} from '@material-ui/core';
import {
	AddPhotoAlternate,
	Cancel,
	Close,
	Delete,
	Edit,
	Mic,
	PlayArrow,
	RadioButtonChecked,
	RadioButtonUnchecked,
	Save
} from '@material-ui/icons';
import useBStyles from 'styles/bStyles';
import useCStyles from 'styles/cStyles';
import {
	VALIDATOR_REQUIRE,
	VALIDATOR_MAXLENGTH,
	validate
} from 'utils/validators';
import { useDispatch, useSelector } from 'react-redux';
import {
	uploadRainbowImageAction,
	uploadAudioAction,
	deleteAudioAction,
	deleteRainbowImageAction,
	createRainbowElementAction,
	updateRainbowElementAction,
	deleteRainbowElementAction
} from 'actions/rainbowActions';
import {
	UPLOAD_RAINBOW_IMAGE_CLEAR,
	UPLOAD_AUDIO_CLEAR,
	UPLOAD_AUDIO_FAIL,
	UPDATE_RAINBOW_ELEMENT_CLEAR_REDIRECT,
	CREATE_RAINBOW_ELEMENT_CLEAR_REDIRECT,
	DELETE_RAINBOW_ELEMENT_CLEAR_REDIRECT
} from 'constants/rainbowConstants';
import MicRecorder from 'mic-recorder-to-mp3';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

const recorder = new MicRecorder({
	bitRate: 128
});

const AppSettingsModal = ({ open, setOpen, formState, setFormState }) => {
	const bClasses = useBStyles();
	const cClasses = useCStyles();
	const [upImg, setUpImg] = useState();
	const [crop, setCrop] = useState({ unit: 'px',
		width: 150, height: 150, x: 110,y:55,
	});
	const [completedCrop, setCompletedCrop] = useState(null);
	const imgRef = useRef(null);
	const previewCanvasRef = useRef(null);
	const dispatch = useDispatch();
	const theme = useTheme();
	const matchesXS = useMediaQuery(theme => theme.breakpoints.down('xs'));
	const {
		loading: uploadImageIsLoading,
		image: uploadedImage,
		success: uploadImageSuccess
	} = useSelector(state => state.uploadRainbowImage);
	const { audio: uploadedAudio, success: uploadAudioSuccess } = useSelector(
		state => state.uploadAudio
	);
	const {
		loading: updateRainbowElementIsLoading,
		redirect: updateRainbowElementRedirect
	} = useSelector(state => state.updateRainbowElement);
	const {
		loading: createRainbowElementIsLoading,
		redirect: createRainbowElementRedirect
	} = useSelector(state => state.createRainbowElement);
	const {
		loading: deleteRainbowElementIsLoading,
		redirect: deleteRainbowElementRedirect
	} = useSelector(state => state.deleteRainbowElement);
	const [colorChanged, setColorChanged] = useState(false);
	const [isRecording, setIsRecording] = useState(false);
	const [recordingIconIsChecked, setRecordingIconIsChecked] = useState(false);

	const { title, image, audio, color, type, id, category, isHappy } = formState;

	const submitHandler = e => {
		e.preventDefault();
		const element = {
			isHappy,
			title: title?.value
				? title?.value
				: `New ${type === 'TOOL' ? 'Tool' : 'Emotion'}`,
			image: image?.value ? image?.value : '',
			audio: audio?.value ? audio?.value : '',
			color: color?.value ? color?.value : '#ffffff',
			category,
			type
		};
		if (id) {
			dispatch(updateRainbowElementAction({ ...element, id }));
		} else {
			dispatch(createRainbowElementAction(element));
		}
	};

	const changeHandler = (e, validators) => {
		setFormState({
			...formState,
			[e.target.id]: {
				value: e.target.value,
				isValid: validate(e.target.value, validators),
				isTouched: true
			}
		});
	};

	const uploadFileHandler = e => {
		const file = e.target.files[0];
		// dispatch(uploadRainbowImageAction(file, type, id));
		if (file) {
			const reader = new FileReader();
			reader.addEventListener('load', () => setUpImg(reader.result));
			reader.readAsDataURL(file);
		}
	};

	const changeColorHandler = e => {
		if (!colorChanged) setColorChanged(true);
		setFormState({
			...formState,
			color: {
				value: e.target.value
			}
		});
	};

	const playAudioHandler = () => {
		const audioRecording = new Audio(audio.value);
		audioRecording.play();
	};

	const deleteAudioHandler = () => {
		dispatch(deleteAudioAction(id));
		setFormState({ ...formState, audio: { value: '' } });
	};

	const deleteImageHandler = () => {
		dispatch(deleteRainbowImageAction(id));
		setFormState({
			...formState,
			image: {
				value: ''
			}
		});
	};

	const deleteElementHandler = () => {
		if (id) {
			dispatch(deleteRainbowElementAction(type, id, category));
		} else {
			setOpen(false);
		}
	};

	const recordAudioHandler = async () => {
		try {
			if (!isRecording) {
				setIsRecording(true);
				await recorder.start();
			} else {
				setIsRecording(false);
				const [buffer, blob] = await recorder.stop().getMp3();
				const file = new File(buffer, 'audioRecording.mp3', {
					type: blob.type,
					lastModified: Date.now()
				});
				dispatch(uploadAudioAction(file, type));
			}
		} catch (error) {
			setIsRecording(false);
			dispatch({
				type: UPLOAD_AUDIO_FAIL,
				payload:
					error.message === 'Requested device not found'
						? 'Please connect a microphone to record audio'
						: error.message
			});
		}
	};

	const generateAndUpload = (canvas, crop) => {
		if (!crop || !canvas) {
			return;
		}
		canvas.toBlob(
			(blob) => {
				dispatch(uploadRainbowImageAction(blob, type, id));
				setUpImg(null);
			},
			'image/png',
			1
		);
	}

	const onLoad = useCallback((img) => {
		imgRef.current = img;
	}, []);

	useEffect(() => {
		if (uploadImageSuccess) {
			setFormState({
				...formState,
				image: {
					value: uploadedImage
				}
			});
			dispatch({ type: UPLOAD_RAINBOW_IMAGE_CLEAR });
		}
		if (uploadAudioSuccess) {
			setFormState({
				...formState,
				audio: {
					value: uploadedAudio
				}
			});
			dispatch({ type: UPLOAD_AUDIO_CLEAR });
		}
	}, [
		uploadedImage,
		uploadImageSuccess,
		uploadedAudio,
		uploadAudioSuccess,
		dispatch,
		formState,
		setFormState
	]);

	useEffect(() => {
		let interval;
		if (isRecording) {
			interval = setInterval(() => {
				setRecordingIconIsChecked(prev => !prev);
			}, 500);
		} else {
			clearInterval(interval);
		}
		return () => {
			clearInterval(interval);
		};
	}, [isRecording]);

	useEffect(() => {
		if (
			updateRainbowElementRedirect ||
			createRainbowElementRedirect ||
			deleteRainbowElementRedirect
		) {
			setOpen(false);
			dispatch({ type: UPDATE_RAINBOW_ELEMENT_CLEAR_REDIRECT });
			dispatch({ type: CREATE_RAINBOW_ELEMENT_CLEAR_REDIRECT });
			dispatch({ type: DELETE_RAINBOW_ELEMENT_CLEAR_REDIRECT });
		}
	}, [
		updateRainbowElementRedirect,
		createRainbowElementRedirect,
		deleteRainbowElementRedirect,
		dispatch,
		setOpen
	]);

	useEffect(() => {
		if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
			return;
		}

		const image = imgRef.current;
		const canvas = previewCanvasRef.current;
		const crop = completedCrop;

		const scaleX = image.naturalWidth / image.width;
		const scaleY = image.naturalHeight / image.height;
		const ctx = canvas.getContext('2d');
		const pixelRatio = window.devicePixelRatio;

		canvas.width = crop.width * pixelRatio;
		canvas.height = crop.height * pixelRatio;

		ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
		ctx.imageSmoothingQuality = 'high';

		ctx.drawImage(
			image,
			crop.x * scaleX,
			crop.y * scaleY,
			crop.width * scaleX,
			crop.height * scaleY,
			0,
			0,
			crop.width,
			crop.height
		);
	}, [completedCrop]);

	return (
		<Modal open={open} onClose={() => {
			setOpen(false);
			setUpImg(null);
		}}>
			<Fade in={open}>
				<Paper
					className={bClasses.modalPaper}
					style={{ width: matchesXS ? '' : 410 }}
				>
					<IconButton className={bClasses.close} onClick={() => {
						setOpen(false);
						setUpImg(null);
					}}>
						<Close />
					</IconButton>
					<Grid container direction='column' alignItems='center'>
						<Typography variant='h3'>
							{id ? 'Update' : 'Add'}{' '}
							{type === 'PHYSICAL_EMOTION' || type === 'NEGATIVE_EMOTION'
								? 'Emotion'
								: 'Tool'}
						</Typography>
						<form onSubmit={submitHandler} className={bClasses.modalForm}>
							{title && (
								<TextField
									disabled={isHappy}
									id='title'
									label='Title'
									type='text'
									placeholder='Title'
									fullWidth
									color='secondary'
									value={title.value}
									onChange={e =>
										changeHandler(e, [
											VALIDATOR_REQUIRE(),
											VALIDATOR_MAXLENGTH(30)
										])
									}
									className={
										title.isTouched && !title.isValid
											? clsx(bClasses.input, bClasses.error)
											: title.isChanged
											? clsx(bClasses.input, bClasses.changed)
											: bClasses.input
									}
									error={title.isTouched && !title.isValid}
									helperText={
										title.isTouched && !title.isValid
											? 'Please enter a title under 30 characters'
											: ' '
									}
								/>
							)}
							{color && (
								<Grid
									container
									direction='column'
									justifyContent='center'
									alignItems='center'
									className={bClasses.colorPickerGrid}
								>
									<Typography>Color:</Typography>
									<input
										type='color'
										onBlur={changeColorHandler}
										defaultValue={color.value}
										className={bClasses.colorPicker}
									/>
								</Grid>
							)}
							<Grid>
								<Typography>Image:</Typography>
							</Grid>
							{upImg && <Grid
								container
								direction='column'
								alignItems='center'
								style={{ paddingTop: theme.spacing(2) }}>
								<br/>
								<ReactCrop
									src={upImg}
									onImageLoaded={onLoad}
									crop={crop}
									onChange={(c) => setCrop(c)}
									onComplete={(c) => setCompletedCrop(c)}
								/>
								<Button
									className={cClasses.button}
									style={{ paddingTop: theme.spacing(2) }}
									onClick={() => generateAndUpload(previewCanvasRef.current, completedCrop)}
									color='secondary'
								>
									SAVE IMAGE
								</Button>
								<div className={cClasses.avatarContainer}>
									<div
										className={cClasses.avatar}
										style={{ position: 'absolute', width: 200, height: 200 }}
									>
										<canvas
											ref={previewCanvasRef}
											// Rounding is important so the canvas width and height matches/is a multiple for sharpness.
											style={{
												width: '100%',
												height: '100%',
												'object-fit': 'cover'
											}}
										/>
									</div>
								</div>
							</Grid>}
							{!upImg &&<div className={cClasses.avatarContainer}>
								<Avatar
									className={cClasses.avatar}
									style={{ position: 'absolute' }}
									alt={`${title} image`}
									src={uploadImageIsLoading ? '' : image?.value}
								>
									{uploadImageIsLoading ? (
										<CircularProgress className={cClasses.avatarIcon} />
									) : (
										<AddPhotoAlternate className={cClasses.avatarIcon} />
									)}
								</Avatar>
								<input
									style={{ zIndex: 3 }}
									type='file'
									id={`${title}ImageUpload`}
									name={`${title} Image`}
									className={bClasses.inputOverlay}
									value=''
									onChange={uploadFileHandler}
								/>
								{image?.value && (
									<IconButton className={cClasses.editIcon}>
										<Edit style={{ opacity: 0.6 }} color='secondary' />
									</IconButton>
								)}
							</div>}
							{image?.value && (
								<Button
									variant='outlined'
									endIcon={<Cancel />}
									className={bClasses.errorButton}
									onClick={deleteImageHandler}
								>
									Remove Image
								</Button>
							)}

							<Grid style={{ marginTop: theme.spacing(2) }}>
								<Typography>Audio:</Typography>
							</Grid>
							<Button
								color='secondary'
								size='large'
								variant='outlined'
								endIcon={
									isRecording && recordingIconIsChecked ? (
										<RadioButtonChecked
											style={{ color: theme.palette.error.main }}
										/>
									) : isRecording ? (
										<RadioButtonUnchecked
											style={{ color: theme.palette.error.main }}
										/>
									) : (
										<Mic />
									)
								}
								onClick={recordAudioHandler}
								className={bClasses.recordAudioButton}
							>
								{isRecording ? 'Stop recording' : 'Start recording'}
							</Button>
							{audio?.value && (
								<Button
									color='secondary'
									size='large'
									variant='outlined'
									endIcon={<PlayArrow />}
									className={bClasses.recordAudioButton}
									onClick={playAudioHandler}
								>
									Play Recording
								</Button>
							)}
							{audio?.value && (
								<Button
									variant='outlined'
									className={bClasses.errorButton}
									endIcon={<Cancel />}
									onClick={deleteAudioHandler}
								>
									Remove audio
								</Button>
							)}

							<Button
								variant='contained'
								type='submit'
								endIcon={<Save />}
								className={bClasses.button}
								disabled={
									!title?.value || title?.value.length > 30 || isRecording
								}
								fullWidth
								color='secondary'
								disableElevation
								style={{ fontWeight: 700, fontSize: '1.1rem' }}
							>
								{updateRainbowElementIsLoading ||
								createRainbowElementIsLoading ? (
									<CircularProgress size={25} />
								) : (
									'Save'
								)}
							</Button>
							{id && (
								<Button
									endIcon={<Delete />}
									className={bClasses.errorButton}
									disableElevation
									style={{ marginTop: theme.spacing(1) }}
									onClick={deleteElementHandler}
									disabled={isHappy}
								>
									{deleteRainbowElementIsLoading ? (
										<CircularProgress
											style={{ color: theme.palette.error.main }}
											size={15}
										/>
									) : (
										`Delete ${type === 'TOOL' ? 'Tool' : 'Emotion'}`
									)}
								</Button>
							)}
						</form>
					</Grid>
				</Paper>
			</Fade>
		</Modal>
	);
};

export default AppSettingsModal;
