import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles(theme => ({
	container: {
		[theme.breakpoints.up('sm')]: {
			padding: theme.spacing(0, 2)
		}
	},
	screenRoot: {
		position: 'relative',
		padding: theme.spacing(3),
		[theme.breakpoints.down('xs')]: {
			padding: theme.spacing(3, 1)
		},
		marginTop: theme.spacing(1),
		backgroundColor: theme.palette.background.default,
		borderColor: theme.palette.grey[500],
		zIndex: 16,
		minWidth: 320
	},
	fullScreenRoot: {
		height: 'calc(90* var(--vh))'
	},
	loadingPaper: {
		width: '100%',
		height: 200,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	},
	button: {
		fontWeight: 700,
		marginRight: theme.spacing(1),
		[theme.breakpoints.down('xs')]: {
			marginTop: 0,
			margin: theme.spacing(2),
			marginBottom: theme.spacing(3)
		}
	},
	table: {
		[theme.breakpoints.up('sm')]: {
			padding: theme.spacing(1, 2)
		}
	},
	error: {
		color: theme.palette.error.main
	},
	collapse: {
		marginTop: -theme.spacing(2),
		[theme.breakpoints.down('md')]: {
			marginTop: -theme.spacing(1),
			marginBottom: theme.spacing(2)
		},
		width: '100%'
	},
	backButton: {
		marginTop: theme.spacing(3)
	},
	form: {
		width: 500,
		[theme.breakpoints.down('xs')]: {
			width: 320
		},
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center'
	},
	input: {
		marginTop: theme.spacing(2),
		width: '90%',
		'& .MuiInputLabel-shrink, p': {
			fontWeight: 700
		}
	},
	imageButton: {
		position: 'relative',
		margin: theme.spacing(3, 1, 2, 1),
		padding: theme.spacing(1),
		width: 'min-content',
		maxWidth: '80%',
		[theme.breakpoints.down('xs')]: {
			maxWidth: '100%'
		},
		'& p': {
			whiteSpace: 'nowrap',
			textTransform: 'none',
			marginTop: theme.spacing(1)
		}
	},
	imagePaper: {
		padding: theme.spacing(2),
		marginBottom: theme.spacing(2),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		background: theme.palette.background.default
	},
	stepper: {
		width: 200,
		margin: 'auto',
		backgroundColor: 'none'
	},
	image: {
		maxWidth: '100%',
		marginLeft: 'auto',
		marginRight: 'auto',
		marginBottom: theme.spacing(1)
	},
	subtitleInput: {
		marginBottom: theme.spacing(1)
	},
	deleteButton: {
		display: 'block',
		marginLeft: 'auto',
		'& .MuiButton-label': {
			display: 'flex',
			flexDirection: 'row',
			flexWrap: 'nowrap'
		},
		'&:hover': {
			color: theme.palette.error.main,
			borderColor: theme.palette.error.main
		}
	},
	banSwitch: {
		'& .Mui-checked': {
			color: theme.palette.error.main
		},
		'& .Mui-checked + .MuiSwitch-track': {
			backgroundColor: theme.palette.error.light
		}
	},
	titleContainer: {
		[theme.breakpoints.down('xs')]: {
			padding: theme.spacing(1)
		}
	},
	title: {
		textAlign: 'center'
		// [theme.breakpoints.down('sm')]: {
		// 	fontSize: '2.5rem'
		// },
		// [theme.breakpoints.down('xs')]: {
		// 	fontSize: '2rem'
		// }
	},
	subTitle: {
		margin: theme.spacing(1),
		textAlign: 'center',
		fontWeight: 700
		// [theme.breakpoints.down('sm')]: {
		// 	fontSize: '1.5rem'
		// },
		// [theme.breakpoints.down('xs')]: {
		// 	fontSize: '1rem'
		// }
	},
	paper: {
		padding: theme.spacing(2),
		marginBottom: theme.spacing(1)
	},
	adminPaper: {
		marginTop: theme.spacing(2)
	},
	text: {
		marginTop: theme.spacing(1),
		marginLeft: theme.spacing(1),
		'&:last-of-type': {
			marginBottom: theme.spacing(1)
		}
	},
	link: {
		textDecoration: 'none',
		color: theme.palette.secondary.main
	},
	divider: {
		margin: theme.spacing(1, 0)
	},
	address: {
		marginBottom: -theme.spacing(0.7),
		marginLeft: theme.spacing(1)
	},
	buttonContainer: {
		marginTop: theme.spacing(1)
	},
	full: {
		width: '100%',
		height: '100%'
	},

	margin2: {
		margin: theme.spacing(2)
	},
	pointer: {
		'&:hover': {
			cursor: 'pointer'
		}
	},
	hidden: {
		display: 'none'
	},
	profilePaperLoading: {
		width: '100%',
		height: '75px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},
	faintIcon: { color: theme.palette.grey[500] },
	headerRow: {
		'& th': {
			fontWeight: 700,
			[theme.breakpoints.down('md')]: {
				padding: theme.spacing(1)
			}
		}
	},
	tableRow: {
		'& td': {
			[theme.breakpoints.down('md')]: {
				padding: theme.spacing(1)
			}
		}
	},
	aboutPaper: {
		padding: theme.spacing(2),
		marginBottom: theme.spacing(3),
		maxWidth: 700
	},
	shadow: {
		marginTop: theme.spacing(2),
		boxShadow: theme.shadows[5]
	},
	divider2: {
		margin: theme.spacing(2, 0),
		padding: theme.spacing(1, 0),
		width: '80%',
		maxWidth: 400,
		borderBottom: `1px solid ${theme.palette.grey[800]}`
	},
	icons: {
		maxWidth: 150,
		'& a': {
			color: theme.palette.text.primary,
			'&:hover': {
				color: theme.palette.secondary.main
			}
		}
	},
	email: {
		marginTop: theme.spacing(1),
		'& a': {
			color: theme.palette.secondary.main,
			textDecoration: 'none',
			'&:hover': {
				color: theme.palette.secondary.main
			}
		}
	},
	subTitle3: {
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.7rem'
		},
		[theme.breakpoints.down('xs')]: {
			fontSize: '1.5rem'
		}
	},
	'@keyframes pulse': {
		'0%': {
			transform: 'translate(-50%, 40%)'
		},
		'100%': {
			transform: 'translate(-50%, 0%)'
		}
	},
	centerImage: {
		width: 300,
		height: 300
	},
	emotionPaper: {
		backgroundColor: theme.palette.background.default,
		// backgroundColor: 'rgba(255, 255, 255, 0.6)',
		zIndex: 2,
		minWidth: 320,
		border: 'none',
		padding: theme.spacing(2)
	},
	emotionTitle: {
		color: '#000'
	},
	overlayPaper: {
		position: 'fixed',
		left: '50%',
		top: '50%',
		flexWrap: 'nowrap',
		transform: 'translate(-50%, -50%)',
		padding: 0,
		backgroundColor: 'rgba(255, 255, 255, 1)',
		zIndex: 17,
		minWidth: 320,
		width: 'calc(100vw - 240px)',
		height: 'min-content',
		maxHeight: `calc(calc(100 * var(--vh)) - ${60 + theme.spacing(1)}px)`,
		[theme.breakpoints.down('xs')]: {
			top: '50%',
			width: 'calc(100vw - 176px)',
			maxHeight: `calc(calc(100 * var(--vh)) - ${33 + theme.spacing(1)}px)`
		}
	},
	scrollContainer: {
		position: 'relative',
		height: 'min-content',
		width: '100%',
		overflowY: 'auto',
		overflowX: 'hidden',
		padding: theme.spacing(3, 3, 2, 3),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		[theme.breakpoints.down('xs')]: {
			padding: theme.spacing(3, 4, 2, 2)
		}
	},
	buttonGrid: {
		padding: theme.spacing(1)
	},
	closeButton: {
		position: 'absolute',
		left: 0,
		top: 0
	},
	toolContainer: {
		margin: '0 auto',
		padding: theme.spacing(2),
		width: 320 - theme.spacing(6),
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(2),
		[theme.breakpoints.down('xs')]: {
			width: 320 - theme.spacing(4)
		}
	},
	toolImage: {
		width: 128,
		height: 128,
		marginTop: theme.spacing(2)
	},
	avatarContainer: {
		position: 'relative',
		width: 200,
		height: 200,
		borderRadius: '50%',
		overflow: 'hidden',
		margin: theme.spacing(1, 0, 2, 0),
		border: `1px solid ${theme.palette.grey[500]}`
	},
	avatar: {
		position: 'absolute',
		top: 0,
		left: 0,
		'&.MuiAvatar-root': {
			width: 200,
			height: 200
		}
	},
	avatarIcon: {
		width: 50,
		height: 50
	},
	imageInput: {
		position: 'absolute',
		width: '100%',
		height: '100%',
		opacity: 0,
		borderRadius: '50%',
		'&:hover': {
			cursor: 'pointer'
		}
	},
	profileSettingsButton: {
		margin: theme.spacing(1),
		fontWeight: 700,
		'&:first-of-type': {
			marginTop: theme.spacing(2)
		},
		'&:last-of-type': {
			marginBottom: theme.spacing(2)
		}
	},
	editIcon: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		zIndex: 1,
		backgroundColor: 'rgba(255, 255, 255, 0.3)'
	},
	elementContainer: {
		padding: theme.spacing(1)
	},
	element: {
		padding: theme.spacing(1),
		cursor: 'pointer',
		borderColor: theme.palette.secondary.main,
		height: '100%',
		width: '100%'
	},
	elementAvatar: {
		width: '100%',
		height: 'auto'
	},
	elementScroll: {
		height: `calc(100% - ${theme.spacing(6)}px)`,
		overflow: 'auto',
		marginTop: theme.spacing(1)
	},
	elementTitle: {
		textAlign: 'center',
		fontWeight: 800,
		fontSize: '1rem',
		textTransform: 'capitalize'
	},
	sortLabel: {
		'& svg': {
			fill: theme.palette.secondary.main
		}
	},
	modalTitle: {
		marginBottom: theme.spacing(2),
		marginTop: -theme.spacing(1)
	},
	datePickPaper: {
		padding: theme.spacing(0, 1),
		'& .MuiFormControl-root': {
			// margin: theme.spacing(1)
		}
	},
	datePickerContainer: {
		padding: theme.spacing(0, 1),
		'& .MuiFormLabel-root': {
			color: theme.palette.grey[800]
		},
		'& .MuiInputLabel-shrink, p': {
			fontWeight: 800
		},
		'& input': {
			[theme.breakpoints.down('xs')]: {
				fontSize: '.8rem'
			}
		}
	},
	datePickerError: {
		'& .MuiFormLabel-root': {
			color: theme.palette.error.main
		},
		'& .MuiFormHelperText-root': {
			color: theme.palette.error.main
		}
	},
	termsPaper: {
		'& p': {
			marginBottom: theme.spacing(1)
		},
		'& a': {
			textDecoration: 'none',
			color: theme.palette.secondary.main,
			fontWeight: 800
		}
	},
	aboutRainbow: {
		marginTop: theme.spacing(3),
		width: 250,
		[theme.breakpoints.up('sm')]: {
			width: 300
		}
	},
	bioGrid: {
		padding: theme.spacing(2),
		'& p': {
			'&:first-of-type': {
				margin: theme.spacing(0, 0, 1, 1),
				fontSize: '1.1rem'
			},
			'&:last-of-type': {
				margin: theme.spacing(1, 0, 0, 2),
				fontWeight: 700,
				fontSize: '1.1rem',
				fontStyle: 'italic'
			}
		}
	},
	bioImage: {
		width: '100%',
		maxWidth: 350,
		margin: theme.spacing(1, 0),
		[theme.breakpoints.down('xs')]: {
			width: '100%',
			maxWidth: 250
		},
		[theme.breakpoints.up('md')]: {
			margin: theme.spacing(2)
		}
	},
		titlePageImageContainer: {
		margin: theme.spacing(4, 2, 0, 0),
		width: 180,
		[theme.breakpoints.down('sm')]: {
			margin: theme.spacing(3, 1, 0, 0),
			width: 140
		},
		[theme.breakpoints.down('xs')]: {
			width: 120
		}
	},
	titlePageImage: {
		width: '100%'
	}
}));
export default styles;
