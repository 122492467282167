import React, {useCallback, useEffect, useRef, useState} from 'react';

import clsx from 'clsx';
import {
	Fade,
	Grid,
	IconButton,
	Modal,
	Typography,
	Paper,
	TextField,
	Button,
	Avatar,
	CircularProgress,
	useTheme,
	useMediaQuery
} from '@material-ui/core';
import {
	AddPhotoAlternate,
	Cancel,
	Close,
	Delete,
	Edit,
	Save
} from '@material-ui/icons';
import useBStyles from 'styles/bStyles';
import useCStyles from 'styles/cStyles';
import {
	VALIDATOR_REQUIRE,
	VALIDATOR_MAXLENGTH,
	validate
} from 'utils/validators';
import { useDispatch, useSelector } from 'react-redux';
import {
	uploadRainbowImageAction,
	deleteRainbowImageAction,
	deleteEventAction,
	addEventAction,
	editEventAction
} from 'actions/rainbowActions';
import { UPLOAD_RAINBOW_IMAGE_CLEAR } from 'constants/rainbowConstants';
import DateFnsUtils from '@date-io/date-fns';
import {
	MuiPickersUtilsProvider,
	KeyboardTimePicker
} from '@material-ui/pickers';
import moment from 'moment';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

const ScheduleSettingsModal = ({ open, setOpen, formState, setFormState }) => {
	const bClasses = useBStyles();
	const cClasses = useCStyles();
	const [upImg, setUpImg] = useState();
	const [crop, setCrop] = useState({ unit: 'px',
		width: 200, height: 200, x: 65,y:65,
		aspect: 1 });
	const [completedCrop, setCompletedCrop] = useState(null);
	const imgRef = useRef(null);
	const previewCanvasRef = useRef(null);
	const dispatch = useDispatch();
	const theme = useTheme();
	const matchesXS = useMediaQuery(theme => theme.breakpoints.down('xs'));
	const {
		loading: uploadImageIsLoading,
		image: uploadedImage,
		success: uploadImageSuccess
	} = useSelector(state => state.uploadRainbowImage);
	const { loading: editEventIsLoading, success: editEventSuccess } =
		useSelector(state => state.editEvent);
	const { loading: addEventIsLoading, success: addEventSuccess } = useSelector(
		state => state.addEvent
	);
	const { loading: deleteEventIsLoading, success: deleteEventSuccess } =
		useSelector(state => state.deleteEvent);

	const { title, start, end, id, image } = formState;

	const [pickerTime, setPickerTime] = useState({
		start: new Date(
			1970,
			0,
			1,
			Number(start?.toString().slice(0, -2)) || 8,
			Number(start?.toString().slice(-2)) || 0
		),
		end: new Date(
			1970,
			0,
			1,
			Number(end?.toString().slice(0, -2)) || 8,
			Number(end?.toString().slice(-2)) || 0
		)
	});

	const submitHandler = e => {
		e.preventDefault();
		const element = {
			title: title?.value,
			image: image?.value,
			start,
			end
		};
		if (id) {
			dispatch(editEventAction({ ...element, id }));
		} else {
			dispatch(addEventAction(element));
		}

	};

	const changeHandler = (e, validators) => {
		setFormState({
			...formState,
			[e.target.id]: {
				value: e.target.value,
				isValid: validate(e.target.value, validators),
				isTouched: true
			}
		});
	};

	const uploadFileHandler = e => {
		const file = e.target.files[0];
		// dispatch(uploadRainbowImageAction(file, 'EVENT', id));
		if (file) {
			const reader = new FileReader();
			reader.addEventListener('load', () => setUpImg(reader.result));
			reader.readAsDataURL(file);
		}

	};

	const deleteImageHandler = () => {
		dispatch(deleteRainbowImageAction(id));
		setFormState({
			...formState,
			image: {
				value: ''
			}
		});
	};

	const deleteEventHandler = () => {
		if (id) {
			dispatch(deleteEventAction(id));
		} else {
			setOpen(false);
		}
	};

	const changeTimeHandler = (time, type) => {
		setFormState({
			...formState,
			[type]: Number(moment(time).format('HHmm'))
		});
		setPickerTime({ ...pickerTime, [type]: time });
	};

	const generateAndUpload = (canvas, crop) => {
		if (!crop || !canvas) {
			return;
		}
		canvas.toBlob(
			(blob) => {
				dispatch(uploadRainbowImageAction(blob, 'EVENT', id));
				setUpImg(null);
			},
			'image/png',
			1
		);
	}

	const onLoad = useCallback((img) => {
		imgRef.current = img;
	}, []);

	useEffect(() => {
		if (uploadImageSuccess) {
			setFormState({
				...formState,
				image: {
					value: uploadedImage
				}
			});
			dispatch({ type: UPLOAD_RAINBOW_IMAGE_CLEAR });
		}
	}, [uploadedImage, uploadImageSuccess, dispatch, formState, setFormState]);

	useEffect(() => {
		if (addEventSuccess || editEventSuccess || deleteEventSuccess)
			setOpen(false);
	}, [addEventSuccess, editEventSuccess, deleteEventSuccess, setOpen]);

	useEffect(() => {
		if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
			return;
		}

		const image = imgRef.current;
		const canvas = previewCanvasRef.current;
		const crop = completedCrop;

		const scaleX = image.naturalWidth / image.width;
		const scaleY = image.naturalHeight / image.height;
		const ctx = canvas.getContext('2d');
		const pixelRatio = window.devicePixelRatio;

		canvas.width = crop.width * pixelRatio;
		canvas.height = crop.height * pixelRatio;

		ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
		ctx.imageSmoothingQuality = 'high';

		ctx.drawImage(
			image,
			crop.x * scaleX,
			crop.y * scaleY,
			crop.width * scaleX,
			crop.height * scaleY,
			0,
			0,
			crop.width,
			crop.height
		);
	}, [completedCrop]);

	const timeError = false; // pickerTime.start > pickerTime.end;

	return (
		<Modal open={open} onClose={() => {
			setOpen(false);
			setUpImg(null);
		}}>
			<Fade in={open}>
				<Paper
					className={bClasses.modalPaper}
					style={{
						width: matchesXS ? '' : 410,
						paddingBottom: theme.spacing(1)
					}}
				>
					<IconButton className={bClasses.close} onClick={() => {
						setOpen(false);
						setUpImg(null);
					}}>
						<Close />
					</IconButton>
					<Grid container direction='column' alignItems='center'>
						<Typography variant='h3'>{id ? 'Update' : 'Add'} Event</Typography>
						<form onSubmit={submitHandler} className={bClasses.modalForm}>
							<TextField
								id='title'
								label='Title'
								type='text'
								placeholder='Title'
								fullWidth
								color='secondary'
								value={title?.value}
								onChange={e =>
									changeHandler(e, [
										VALIDATOR_REQUIRE(),
										VALIDATOR_MAXLENGTH(30)
									])
								}
								className={
									title?.isTouched && !title?.isValid
										? clsx(bClasses.input, bClasses.error)
										: title?.isChanged
										? clsx(bClasses.input, bClasses.changed)
										: bClasses.input
								}
								error={title?.isTouched && !title?.isValid}
								helperText={
									title?.isTouched && !title?.isValid
										? 'Please enter a title under 30 characters'
										: ' '
								}
							/>
							<Grid>
								<Typography>Image:</Typography>
							</Grid>
							{upImg && <Grid
								container
								direction='column'
								alignItems='center'
								style={{ paddingTop: theme.spacing(2) }}>
								<br/>
								<ReactCrop
									src={upImg}
									onImageLoaded={onLoad}
									crop={crop}
									onChange={(c) => setCrop(c)}
									onComplete={(c) => setCompletedCrop(c)}
								/>
								<Button
									className={cClasses.button}
									style={{ paddingTop: theme.spacing(2) }}
									onClick={() => generateAndUpload(previewCanvasRef.current, completedCrop)}
									color='secondary'
								>
									Drop & Upload
								</Button>
								<div className={cClasses.avatarContainer}>
									<canvas
										ref={previewCanvasRef}
										// Rounding is important so the canvas width and height matches/is a multiple for sharpness.
										style={{
											width: 200,
											height: 200
										}}
									/>
								</div>
							</Grid>}
							{!upImg && <div className={cClasses.avatarContainer}>
								<Avatar
									className={cClasses.avatar}
									style={{ position: 'absolute' }}
									alt={`${title} image`}
									src={uploadImageIsLoading ? '' : image?.value}
								>
									{uploadImageIsLoading ? (
										<CircularProgress className={cClasses.avatarIcon} />
									) : (
										<AddPhotoAlternate className={cClasses.avatarIcon} />
									)}
								</Avatar>
								<input
									style={{ zIndex: 3 }}
									type='file'
									id={`${title}ImageUpload`}
									name={`${title} Image`}
									className={bClasses.inputOverlay}
									value=''
									onChange={uploadFileHandler}
								/>
								{image?.value && (
									<IconButton className={cClasses.editIcon}>
										<Edit style={{ opacity: 0.6 }} color='secondary' />
									</IconButton>
								)}
							</div>}
							{image?.value && (
								<Button
									variant='outlined'
									endIcon={<Cancel />}
									className={bClasses.errorButton}
									onClick={deleteImageHandler}
								>
									Remove Image
								</Button>
							)}

							<MuiPickersUtilsProvider utils={DateFnsUtils}>
								<div
									className={clsx(
										cClasses.datePickerContainer,
										timeError && cClasses.datePickerError
									)}
								>
									<KeyboardTimePicker
										margin='normal'
										id='start-time-picker'
										label='Start time'
										value={pickerTime.start}
										onChange={time => changeTimeHandler(time, 'start')}
										KeyboardButtonProps={{
											'aria-label': 'change time'
										}}
										helperText={
											timeError && 'Start time must earlier than end time'
										}
										error={timeError}
									/>
								</div>
								<div
									className={clsx(
										cClasses.datePickerContainer,
										timeError && cClasses.datePickerError
									)}
								>
									<KeyboardTimePicker
										margin='normal'
										id='end-time-picker'
										label='End time'
										value={pickerTime.end}
										onChange={time => changeTimeHandler(time, 'end')}
										KeyboardButtonProps={{
											'aria-label': 'change time'
										}}
										helperText={
											timeError && 'End time must later than start time'
										}
										error={timeError}
									/>
								</div>
							</MuiPickersUtilsProvider>

							<Button
								variant='contained'
								type='submit'
								endIcon={<Save />}
								className={bClasses.button}
								disabled={
									timeError || !title?.value || title?.value.length > 30
								}
								fullWidth
								color='secondary'
								disableElevation
								style={{
									fontWeight: 700,
									fontSize: '1.1rem',
									margin: theme.spacing(2, 1, 1, 1)
								}}
							>
								{editEventIsLoading || addEventIsLoading ? (
									<CircularProgress size={25} />
								) : (
									'Save'
								)}
							</Button>
							{id && (
								<Button
									endIcon={<Delete />}
									className={bClasses.errorButton}
									disableElevation
									style={{ marginTop: theme.spacing(1) }}
									onClick={deleteEventHandler}
								>
									{deleteEventIsLoading ? (
										<CircularProgress
											style={{ color: theme.palette.error.main }}
											size={15}
										/>
									) : (
										'Delete event'
									)}
								</Button>
							)}
						</form>
					</Grid>
				</Paper>
			</Fade>
		</Modal>
	);
};

export default ScheduleSettingsModal;
