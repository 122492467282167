import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import useStyles from 'styles/dStyles';
import useAStyles from 'styles/aStyles';
import {
	Avatar,
	Typography,
	Slider,
	Button,
	useMediaQuery,
	withStyles,
	IconButton,
	Fab
} from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import {
	AddCircleOutline,
	ArrowForward,
	Close,
	ArrowBack,
	RemoveCircleOutline,
	VolumeUp
} from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
	CLICK_EMOTION,
	OPEN_TOOLKIT,
	SET_DEGREE,
	CLOSE_EMOTION,
	CLOSE_TOOLKIT
} from 'constants/rainbowConstants';
import Triangle from 'assets/svg/triangle.svg';
import EmotionSwitch from 'components/EmotionSwitch';
import Title from 'components/Title';

const CustomSlider = withStyles(theme => ({
	mark: {
		height: 11,
		width: 1,
		marginTop: -4,
		backgroundColor: 'rgba(0,0,0,0.7)'
	},
	markActive: {
		marginTop: -4,
		height: 11,
		width: 1
	},
	valueLabel: {
		'& span span': {
			color: '#fff',
			fontSize: '1.1rem',
			fontWeight: 800
		}
	},
	track: {
		height: 3
	},
	markLabel: {
		color: theme.palette.grey[900],
		fontWeight: 800,
		marginTop: -55
	}
}))(Slider);

const Rainbow = ({ innerHeight }) => {
	const classes = useStyles();
	const aClasses = useAStyles();
	const theme = useTheme();
	const dispatch = useDispatch();
	const matchesXS = useMediaQuery(theme => theme.breakpoints.down('xs'));
	const { isAuth, user } = useSelector(state => state.userData);
	const {
		negativeEmotions: defaultNegativeEmotions,
		physicalEmotions: defaultPhysicalEmotions
	} = useSelector(state => state.getEmotions);
	const negativeEmotions = isAuth
		? user.negativeEmotions
		: defaultNegativeEmotions;
	const physicalEmotions = isAuth
		? user.physicalEmotions
		: defaultPhysicalEmotions;
	const {
		activeEmotion,
		prevEmotion,
		degreeRange: defaultDegreeRange,
		emotionType,
		toolkitIsOpen
	} = useSelector(state => state.rainbowState);
	const degreeRange = isAuth ? user.degreeRange : defaultDegreeRange;
	const [showContent, setShowContent] = useState(false);
	const [degreeValue, setDegreeValue] = useState(Math.round(degreeRange / 2));

	const emotions =
		emotionType === 'negative' ? negativeEmotions : physicalEmotions;

	useEffect(() => {
		let timer1 = setTimeout(
			() => setShowContent(true),
			500 + (emotions.length + 1) * 100
		);
		if (!activeEmotion) {
			clearTimeout(timer1);
			setShowContent(false);
		}
		return () => {
			clearTimeout(timer1);
		};
	}, [activeEmotion, emotions]);

	const handleBackButton = () => {
		dispatch({ type: CLOSE_EMOTION });
		dispatch({ type: CLOSE_TOOLKIT });
	};

	const handleClickEmotion = emotion => {
		dispatch({ type: CLICK_EMOTION, emotion });
	};

	const handleDegreeSlider = (e, newValue) => {
		setDegreeValue(newValue);
	};

	const handleNextButton = () => {
		if (activeEmotion.isHappy) {
			handleBackButton();
		} else {
			dispatch({ type: SET_DEGREE, degree: degreeValue });
			dispatch({ type: OPEN_TOOLKIT });
		}
	};

	const handleCloseEmotion = () => {
		dispatch({ type: CLOSE_EMOTION });
	};

	const handlePlayAudio = audio => {
		var audioRecording = new Audio(audio);
		audioRecording.play();
	};

	const handleBackgroundClick = () => {
		if (!toolkitIsOpen) {
			dispatch({ type: CLOSE_EMOTION });
		} else {
			dispatch({ type: CLOSE_TOOLKIT });
		}
	};

	const getTransition = (duration, delay, timingFunction = 'cubic') =>
		`transform ${duration}s ${
			timingFunction === 'cubic'
				? 'cubic-bezier(.48,-0.27,.46,1.25)'
				: timingFunction
		} ${delay}s,
	width ${duration}s ${
			timingFunction === 'cubic'
				? 'cubic-bezier(.48,-0.27,.46,1.25)'
				: timingFunction
		} ${delay}s,
	height ${duration}s ${
			timingFunction === 'cubic'
				? 'cubic-bezier(.48,-0.27,.46,1.25)'
				: timingFunction
		} ${delay}s,
	border-radius ${duration}s ${
			timingFunction === 'cubic'
				? 'cubic-bezier(.48,-0.27,.46,1.25)'
				: timingFunction
		} ${delay}s,
	z-index .3s linear ${activeEmotion ? emotions.length * 0.1 + 0.8 : 0}s`;

	const getTransform = (translateX, translateY, rotate, scale = 1) =>
		`translate(${translateX}, ${translateY}) rotate(${rotate}) scale(${scale})`;

	const rainbowImageWidth = (halve = false) =>
		`calc(calc(88 * var(--vh)) / ${
			halve === 'halve' ? emotions.length * 2 : emotions.length
		})`;

	return (
		<>
			<EmotionSwitch />
			<Title />
			<Link
				to='/'
				onClick={handleBackgroundClick}
				className={aClasses.backgroundButton}
				style={{
					opacity: activeEmotion ? 0.5 : 0,
					backgroundImage: `radial-gradient(${activeEmotion?.color} 0%, ${theme.palette.background.default} 80%)`,
					transition: `all .3s ease ${
						!activeEmotion ? 0.3 + emotions.length * 0.1 : 0.1
					}s`
				}}
			></Link>
			{emotions.map((em, i) => (
				<React.Fragment key={em._id}>
					<Link
						to='/'
						onClick={
							activeEmotion?._id === em._id
								? null
								: () => handleClickEmotion(em)
						}
						className={clsx(
							classes.bow,
							!activeEmotion || activeEmotion?._id === em._id
								? classes.shadow
								: null,
							activeEmotion?._id === em._id && classes.expand,
							activeEmotion && activeEmotion?._id !== em._id && classes.shrink
						)}
						style={{
							backgroundColor: em.color,
							borderRadius: activeEmotion?._id === em._id ? 5 : '50%',
							// zIndex: activeEmotion?._id === em._id ? 4 : 0,
							transition:
								activeEmotion?._id === em._id
									? getTransition(0.6, 0, 'ease')
									: !activeEmotion && prevEmotion?._id === em._id
									? getTransition(0.6, emotions.length * 0.1 + 0.3, 'ease')
									: activeEmotion
									? getTransition(
											0.3,
											(emotions.length - i - 1) * 0.1 + 0.5,
											'ease'
									  )
									: getTransition(0.3, (emotions.length - i - 1) * 0.1, 'ease'),
							transform:
								activeEmotion?._id === em._id
									? getTransform(
											`calc((100vw - 320px) / 2)`,
											innerHeight > (matchesXS ? 490 : 530)
												? `calc(-1 * (calc(100 * var(--vh)) - ${
														matchesXS ? 485 : 510
												  }px)/2)`
												: `-${theme.spacing(2)}px`,
											0
									  )
									: activeEmotion
									? getTransform(
											`calc(-50% - (${
												i >=
												emotions.findIndex(x => x._id === activeEmotion?._id)
													? i - 1
													: i
											} * ${(matchesXS ? 12 : 50) / (emotions.length + 1)}px))`,
											`calc(50% + (${
												i >=
												emotions.findIndex(x => x._id === activeEmotion?._id)
													? i - 1
													: i
											} * ${(matchesXS ? 12 : 50) / (emotions.length + 1)}px))`,
											'-30deg',
											1 -
												(i >=
												emotions.findIndex(x => x._id === activeEmotion?._id)
													? i - 1
													: i) *
													(matchesXS ? 0.15 : 0.1)
									  )
									: getTransform(
											`calc(-50% + ${-i * 3}% + 42px)`,
											`calc(${(65 / emotions.length) * i + 115}* var(--vh))`,
											0
									  ),
							cursor: activeEmotion?._id === em._id ? 'default' : 'pointer'
						}}
					>
						{showContent && (
							<IconButton
								className={classes.closeButton}
								onClick={handleCloseEmotion}
							>
								<Close />
							</IconButton>
						)}
						<div
							className={classes.emotionScroll}
							style={{
								width: activeEmotion ? '100%' : 'min-content',
								overflowX: 'hidden'
							}}
						>
							{em.audio && showContent && activeEmotion?._id === em._id && (
								<IconButton
									className={classes.audioButton}
									onClick={() => handlePlayAudio(em.audio)}
									style={{ top: em.image ? 222 : theme.spacing(1) }}
								>
									<VolumeUp fontSize='large' />
								</IconButton>
							)}
							{activeEmotion?._id === em._id && (
								<div
									className={classes.emotionContainer1}
									style={{
										marginTop:
											innerHeight < 350 && !activeEmotion ? 0 : theme.spacing(1)
									}}
								>
									<Typography
										variant='h6'
										className={classes.emotionTitle}
										style={{
											fontSize:
												innerHeight < 350 && !activeEmotion ? '1rem' : '',
											whiteSpace: 'nowrap'
										}}
									>
										{em.title}
									</Typography>
								</div>
							)}
							{showContent && activeEmotion?._id === em._id && (
								<>
									{!!em.image && (
										<div
											className={classes.emotionContainer2}
											style={{ background: 'transparent', width: '100%' }}
										>
											<Avatar
												className={classes.emotionAvatar}
												src={em.image}
												alt={`${em.title} Image`}
												style={{
													width: innerHeight < 330 ? 150 : 200,
													height: innerHeight < 330 ? 150 : 200
												}}
											></Avatar>
										</div>
									)}
									{!!degreeRange && !em.isHappy && (
										<div
											className={classes.degreeContainer}
											style={{ marginTop: !em.image ? theme.spacing(4) : '' }}
										>
											<Triangle className={classes.degreeTriangle} />
											<RemoveCircleOutline
												className={clsx(
													classes.degreeIcon,
													classes.degreeIconLeft
												)}
												fontSize='medium'
											/>
											<AddCircleOutline
												className={clsx(
													classes.degreeIcon,
													classes.degreeIconRight
												)}
												fontSize='medium'
											/>
											<CustomSlider
												value={degreeValue}
												aria-labelledby='intensity-slider'
												valueLabelDisplay='on'
												step={1}
												marks
												min={1}
												max={degreeRange}
												color='secondary'
												className={classes.degreeSlider}
												onChange={handleDegreeSlider}
											/>

											<Typography
												variant='h6'
												className={classes.emotionTitle}
												style={{ marginBottom: theme.spacing(1) }}
											>
												Rate your emotion
											</Typography>
										</div>
									)}
								</>
							)}
							{showContent && em.isHappy && activeEmotion?._id === em._id && (
								<div
									className={classes.emotionContainer1}
									style={{ width: 280 }}
								>
									<Typography
										variant='h6'
										className={classes.emotionTitle}
										style={{
											textAlign: 'center',
											padding: theme.spacing(1)
										}}
									>
										Great you are happy. Please come back again whenever you
										need to.
									</Typography>
								</div>
							)}
						</div>
						{showContent && em._id === activeEmotion?._id && (
							<div className={classes.emotionButtonContainer}>
								<Button
									variant='contained'
									endIcon={!em.isHappy ? <ArrowForward /> : null}
									startIcon={em.isHappy ? <ArrowBack /> : null}
									color='secondary'
									className={classes.emotionButton}
									size='large'
									onClick={handleNextButton}
									disabled={toolkitIsOpen}
								>
									{em.isHappy ? 'Back to rainbow' : 'Manage your emotion'}
								</Button>
							</div>
						)}
					</Link>
					<Link
						to='/'
						onClick={() => handleClickEmotion(em)}
						className={classes.emotionLabel}
						style={{
							transition: getTransition(
								0.2,
								activeEmotion ? 0 : emotions.length * 0.1 + 0.9,
								'ease'
							),
							transform: activeEmotion
								? getTransform(
										0,
										`calc(${
											(83 / emotions.length) * -(emotions.length - i - 1)
										}* var(--vh))`,
										0,
										0
								  )
								: getTransform(
										0,
										`calc(${
											(85 / emotions.length) * -(emotions.length - i - 1)
										}* var(--vh))`,
										0
								  ),
							zIndex: 14,
							backgroundColor: em.color,
							borderRadius: em.image
								? `0px ${rainbowImageWidth('halve')} ${rainbowImageWidth(
										'halve'
								  )} 0px`
								: '0 10px 10px 0',
							height: rainbowImageWidth(),
							paddingRight: em.image ? 0 : '',
							paddingLeft: 0
						}}
					>
						<div
							className={classes.emotionContainer1}
							style={{
								padding: innerHeight < 300 && emotions.length > 5 ? 0 : ''
							}}
						>
							<Typography
								variant='h6'
								className={classes.emotionTitle}
								style={{
									fontSize: innerHeight < 350 && !activeEmotion ? '1rem' : '',
									whiteSpace: 'nowrap'
								}}
							>
								{em.title}
							</Typography>
						</div>
						{em.image && (
							<div
								className={classes.emotionImageContainer}
								style={{
									backgroundColor: em.color,
									width: rainbowImageWidth(),
									maxWidth: 200,
									maxHeight: 200,
									height: rainbowImageWidth(),
									zIndex: i + 5
								}}
							>
								<Avatar
									className={classes.emotionImage}
									src={em.image}
									alt={`${em.title} Image`}
								></Avatar>
							</div>
						)}
					</Link>
				</React.Fragment>
			))}
			{showContent && (
				<Fab
					className={aClasses.rainbowBackButton}
					color='secondary'
					size='large'
					onClick={handleBackButton}
					style={{
						background: 'rgba(255,255, 255, 0.5)',
						color: '#000',
						'&:hover': {
							background: 'rgba(255,255, 255, 0.5)'
						}
					}}
				>
					<ArrowBack fontSize='large' />
				</Fab>
			)}
		</>
	);
};

export default Rainbow;
