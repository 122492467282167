import React from 'react';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	Button
} from '@material-ui/core';
import useBStyles from 'styles/bStyles';
import { useSelector, useDispatch } from 'react-redux';
import { TOGGLE_SETTINGS_MESSAGE } from 'constants/rainbowConstants';

const SettingsMessage = () => {
	const bClasses = useBStyles();
	const dispatch = useDispatch();
	const { settingsMessageIsOpen } = useSelector(state => state.rainbowState);

	const handleCloseMessage = () => {
		dispatch({ type: TOGGLE_SETTINGS_MESSAGE });
	};

	return (
		<Dialog
			classes={{ paper: bClasses.paper }}
			open={!!settingsMessageIsOpen}
			onClose={handleCloseMessage}
			transitionDuration={{ exit: 0 }}
		>
			<DialogTitle className={bClasses.alert}>Login Required</DialogTitle>
			<DialogContent>
				<DialogContentText className={bClasses.text}>
					Please login to change and save your settings
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button className={bClasses.alert} onClick={handleCloseMessage}>
					Ok
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default SettingsMessage;
