import React, { useState } from 'react';
import {
	Grid,
	Paper,
	Button,
	Typography,
	useMediaQuery,
	IconButton
} from '@material-ui/core';
import ReactPlayer from "react-player/lazy"
import useBStyles from 'styles/bStyles';
import useCStyles from 'styles/cStyles';
import {
	ArrowBack,
	ArrowForward,
	Close,
	EmojiPeople, Help,
	Info
} from '@material-ui/icons';
import RainbowWithBolt from 'assets/svg/rainbow-bolt.svg';
import { useHistory, Link } from 'react-router-dom';

const AboutScreen = ({ innerHeight }) => {
	const cClasses = useCStyles();
	const bClasses = useBStyles();
	const history = useHistory();
	const matchesSM = useMediaQuery(theme => theme.breakpoints.down('sm'));
	const matchesXS = useMediaQuery(theme => theme.breakpoints.down('xs'));
	const mediaMax635 = useMediaQuery('(max-width:635px)');
	const [page, setPage] = useState(0);

	const handleBackButton = () => {
		if (page === 0) {
			history.push('/');
		} else {
			setPage(prev => prev - 1);
		}
	};
	const handleNextButton = () => {
		if (page < 2) {
			setPage(prev => prev + 1);
		} else {
			history.push('/');
		}
	};

	const handleDownloadGuid = () => {
		fetch('https://rainbowofemotions.app/uploads/Rainbow-of-Emotions-App-User-Guide.pdf')
			.then(response => {
				response.blob().then(blob => {
					let url = window.URL.createObjectURL(blob);
					let a = document.createElement('a');
					a.href = url;
					a.download = 'Rainbow-of-Emotions-App-User-Guide.pdf';
					a.click();
				});
			});
	}


	const titles = ['Rainbow of Emotions', '', 'Dolly Bhargava'];
	const subTitles = [
		'Teaching Emotional Regulation Skills Step by Step',
		''
	];
	const content = [
		<>
			<Grid container justifyContent='center'>
				<RainbowWithBolt
					className={cClasses.aboutRainbow}
					alt='Rainbow with cloud and lightening bolt'
				/>
			</Grid>
			<Grid container justifyContent='center'>

				<div className={cClasses.titlePageImageContainer}>
					<img
						src='/images/PBS-Resources-logo.jpg'
						alt='NDIS logo'
						className={cClasses.titlePageImage}
					/>
				</div>			</Grid>
		</>,
		<Grid direction='column' justifyContent='center' alignItems='center' container>
			<ReactPlayer
					url="https://rainbowofemotions.app/uploads/Rainbow-of-Emotions-App.mp4"
					width={680}
				    height={380}
					style={{ width: 680, height: 380, maxWidth: '100%', maxHeight: '100%' }}
				    controls={true}
					playing={true}
					light='https://rainbowofemotions.app/uploads/about-video-thumnail.png'
				/>

				<Typography style={{ textAlign: 'center' }}>
					<br />
					<Button
				variant='contained'
				disableElevation
				endIcon={
					<Help />
				}
				size='large'
				className={bClasses.rainbowButton}
				style={{ fontSize: mediaMax635 ? '1rem' : '' }}
				color='secondary'
				onClick={handleDownloadGuid}
			>
				User guide
			</Button>
				</Typography>
		</Grid>,
		<Grid container>
			<Grid item xs={12} md={6} className={cClasses.bioGrid}>
				<Typography>Hi there,</Typography>
				<Typography>
					I am Dolly Bhargava and the founder of Behaviour Help. As a National
					Disability Insurance Scheme (NDIS) Registered Behaviour Support
					Practioner and Speech Pathologist I work with children, adolescents
					and adults with complex social, emotional and behavioural difficulties
					(EBD) in a variety of settings such as family homes, early childhood,
					schools, accommodation, post school options, employment and corrective
					services.
					<br />
					<br />
				</Typography>
				<Typography>
					Based on the practical wisdom I have gathered from working with
					individuals with EBD, their families and incredible staff over the
					last 21 years I have developed the Rainbow of Emotions App: Teaching
					Emotional Regulation Skills Step by Step.
					<br />
					<br />
				</Typography>
				<Typography>
					The app aims to educate, equip and enable individuals with EBD with
					the necessary emotional regulation skills to help them identify,
					understand, express and manage their emotions. By enabling the
					individual to take CHARGE of their rainbow of emotions they can learn
					positive ways of behaving and managing their emotions to reach their
					fullest potential.
					<br />
					<br />
				</Typography>
				<Typography>Dolly Bhargava</Typography>
			</Grid>
			<Grid
				item
				xs={12}
				md={6}
				container
				alignItems='center'
				justifyContent='flex-start'
				direction='column'
				className={cClasses.bioGrid}
			>
				<img
					src='/images/NDIS-logo.jpg'
					alt='NDIS logo'
					className={cClasses.bioImage}
				/>
			</Grid>
			<>
				<br/>
				<br/>
				<br/>
				{/* Titles */}
				<div className={cClasses.titleContainer} style={{width:
				'100%'}}>
					<Typography
						style={{
							fontSize:
								matchesXS || innerHeight < 520
									? '2rem'
									: matchesSM || innerHeight < 600
									? '2.5rem'
									: '3rem'
						}}
						variant='h1'
						className={cClasses.title}
					>
						Ravi Bhatt
					</Typography>
					<Typography
						variant='h4'
						className={cClasses.subTitle}
						style={{
							fontSize: matchesXS || innerHeight < 520 ? '1.2rem' : '1.6rem'
						}}
					>
						{subTitles[page]}
					</Typography>

				</div>
				{/* content */}
				<Grid container>
					<Grid item xs={12} md={6} className={cClasses.bioGrid}>
						<Typography>Hi there,</Typography>
						<Typography>
							Ravi Bhatt is the Managing Director at Sunrise2Sunrise Community Care.
							Sunrise2Sunrise Community Care is a registered NDIS Provider that provides a range of services including supported independent living,
							in-home support/personal care, community participation, support coordination, domestic assistance, and respite services to individuals of all ages.
							<br />
							<br />
						</Typography>
						<Typography>
							With more than 16 years experience of providing support and services to individuals with disabilities of all ages.
							Ravi recognised that emotional regulation is key to happiness, wellbeing and resilience.
							<br />
							<br />
						</Typography>
						<Typography>
							Ravi could see the benefit of  the Rainbow of Emotions app in the lives of the individuals they support. Under his guidance the app was introduced across the various Sunrise2Sunrise services. This app is helping the individuals develop positive ways of managing their emotions and thrive when faced with everyday stressors.
							<br />
							<br />
						</Typography>
						<Typography>
							Based on the positive impact of the app Ravi and Dolly have collaborated and set up pbsresources.com.au. The aim of this site is to share practical positive behaviour resources  to the wider community and  empower individuals with disability to lead a fulfilling and valued life.
							<br />
							<br />
						</Typography>

						<Typography>Ravi Bhatt</Typography>
					</Grid>
					<Grid
						item
						xs={12}
						md={6}
						container
						alignItems='center'
						justifyContent='flex-start'
						direction='column'
						className={cClasses.bioGrid}
					>
						<img
							src='/images/NDIS-logo.jpg'
							alt='NDIS logo'
							className={cClasses.bioImage}
						/>
					</Grid>
				</Grid>
			</>
		</Grid>

	];

	return (
		<Paper
			variant='outlined'
			className={cClasses.overlayPaper}
			component={Grid}
			container
			direction='column'
			justifyContent='space-between'
			style={{ width: 'calc(100vw - 240px)', height: 'min-content' }}
			wrap='nowrap'
		>
			<div className={cClasses.scrollContainer}>
				<IconButton className={cClasses.closeButton} component={Link} to='/'>
					<Close fontSize='small' />
				</IconButton>
				{/* Titles */}
				<div className={cClasses.titleContainer}>
					<Typography
						style={{
							fontSize:
								matchesXS || innerHeight < 520
									? '2rem'
									: matchesSM || innerHeight < 600
									? '2.5rem'
									: '3rem'
						}}
						variant='h1'
						className={cClasses.title}
					>
						{titles[page]}
					</Typography>
					<Typography
						variant='h4'
						className={cClasses.subTitle}
						style={{
							fontSize: matchesXS || innerHeight < 520 ? '1.2rem' : '1.6rem'
						}}
					>
						{subTitles[page]}
					</Typography>

				</div>
				{/* content */}
				{content[page]}

			</div>

			{/* Button Grid */}
			<Grid
				item
				container
				justifyContent='space-between'
				className={cClasses.buttonGrid}
			>
				<Button
					variant='outlined'
					disableElevation
					startIcon={mediaMax635 && page === 0 ? null : <ArrowBack />}
					style={{ fontSize: mediaMax635 ? '1rem' : '' }}
					size='large'
					className={bClasses.rainbowButton}
					color='secondary'
					onClick={handleBackButton}
				>
					{page === 0 ? 'Get started' : 'Back'}
				</Button>

				<Button
					variant='contained'
					disableElevation
					endIcon={
						page === 0 ? (
							<Info />
						) : page === 1 ? (
							<EmojiPeople />
						) : (
							<ArrowForward />
						)
					}
					size='large'
					className={bClasses.rainbowButton}
					style={{ fontSize: mediaMax635 ? '1rem' : '' }}
					color='secondary'
					onClick={handleNextButton}
				>
					{page === 0 ? 'App info' : page === 1 ? 'About PBSResources' : 'Get started'}
				</Button>
			</Grid>
		</Paper>
	);
};

export default AboutScreen;
