import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { Typography, Paper, useMediaQuery, useTheme } from '@material-ui/core';
import useStyles from 'styles/aStyles';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const Title = () => {
	const theme = useTheme();
	const classes = useStyles();
	const { activeEmotion, emotionType } = useSelector(
		state => state.rainbowState
	);
	const { isAuth, user } = useSelector(state => state.userData);
	const {
		negativeEmotions: defaultNegativeEmotions,
		physicalEmotions: defaultPhysicalEmotions
	} = useSelector(state => state.getEmotions);
	const negativeEmotions = isAuth
		? user.negativeEmotions
		: defaultNegativeEmotions;
	const physicalEmotions = isAuth
		? user.physicalEmotions
		: defaultPhysicalEmotions;
	const mediaMax700 = useMediaQuery('(max-width:700px)');

	const [showSubtitle, setShowSubtitle] = useState(!activeEmotion);

	const emotions =
		emotionType === 'negative' ? negativeEmotions : physicalEmotions;

	useEffect(() => {
		let timer1 = setTimeout(
			() => setShowSubtitle(true),
			500 + (emotions.length + 1) * 100
		);
		if (activeEmotion) {
			clearTimeout(timer1);
			setShowSubtitle(false);
		}
		return () => {
			clearTimeout(timer1);
		};
	}, [activeEmotion, emotions]);

	return (
		<>
			<Typography
				className={clsx(classes.mainTitle, classes.bowTitle2)}
				component={Link}
				to='/'
			>
				Rainbow of Emotions<span>.app</span>
			</Typography>
			{showSubtitle && (
				<Paper
					variant='outlined'
					style={{
						display: mediaMax700 ? 'none' : ''
					}}
					className={classes.rainbowSubtitleContainer}
				>
					<Typography
						variant='h4'
						style={{ color: theme.palette.text.primary }}
						className={classes.rainbowSubtitle}
					>
						How are you feeling
						{isAuth ? (
							<>
								<br />
								{user.name}?
							</>
						) : (
							'?'
						)}
					</Typography>
				</Paper>
			)}
		</>
	);
};

export default Title;
