import React, { useEffect, useState, useRef } from 'react';
import moment from 'moment';
import {
	Grid,
	Fade,
	Modal,
	Paper,
	IconButton,
	Typography,
	useMediaQuery,
	useTheme
} from '@material-ui/core';

import { ArrowForward, Close } from '@material-ui/icons';
import useBStyles from 'styles/bStyles';
import useCStyles from 'styles/cStyles';
import { useSelector } from 'react-redux';
import SubscriptionsTable from 'components/SubscriptionsTable';

const sortAlphabetically = (a, b, key, order) => {
	if (key === 'time' || key === 'date') key = 'createdAt';
	const valueA = typeof a[key] === 'string' ? a[key].toUpperCase() : a[key];
	const valueB = typeof b[key] === 'string' ? b[key].toUpperCase() : b[key];

	if (order === 'asc' ? valueA < valueB : valueA > valueB) {
		return -1;
	}
	if (order === 'asc' ? valueA > valueB : valueA < valueB) {
		return 1;
	}
	return 0;
};

const headCells = [
	{ label: 'Subscription Type', id: 'type' },
	{ label: 'Status', id: 'status' },
	{ label: 'Next payment', id: 'date' },
	{ label: 'Action', id: 'action' }
];

const getRows = (
	subscriptions,
	orderBy,
	order,
	toDate,
) => {
	if(subscriptions && subscriptions.length) {
		return subscriptions
			.sort((a, b) => sortAlphabetically(a, b, orderBy, order))
			.filter(x => {
				const getUnix = time => moment(time).unix();
				return (
					getUnix(x.start) < getUnix(toDate)
				);
			});
	}
	return null;
};

const SubscriptionsModal = ({ open, setOpen }) => {
	const bClasses = useBStyles();
	const cClasses = useCStyles();
	const theme = useTheme();
	const matchesXS = useMediaQuery(theme => theme.breakpoints.down('xs'));
	const matchesSM = useMediaQuery(theme => theme.breakpoints.down('sm'));

	const {
		user: { subscriptions }
	} = useSelector(state => state.userData);

	const tableRef = useRef(null);

	const [order, setOrder] = useState('desc');
	const [orderBy, setOrderBy] = useState('date');
	const [toDate, setToDate] = useState(new Date());

	const [rows, setRows] = useState(
		getRows(subscriptions, orderBy, order, toDate)
	);

	const sortHandler = (event, property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};



	useEffect(() => {
		setRows(
			getRows(subscriptions, orderBy, order, toDate)
		);
	}, [orderBy,  order, toDate, subscriptions]);

	return (
		<>
			<Modal open={open} onClose={() => setOpen(false)}>
				<Fade in={open}>
					<Paper className={bClasses.modalPaper} style={{minWidth: 900, maxWidth: 1500}}>
						<div style={{ display: 'none' }}>
							{subscriptions && <SubscriptionsTable
								sortHandler={sortHandler}
								matchesXS={matchesXS}
								matchesSM={matchesSM}
								headCells={headCells}
								orderBy={orderBy}
								order={order}
								rows={getRows(
									subscriptions,
									orderBy,
									order,
									toDate,
								)}
								tableRef={tableRef}
								printFriendly
							/>}
						</div>
						<IconButton
							className={bClasses.close}
							onClick={() => setOpen(false)}
						>
							<Close />
						</IconButton>

						<Grid container justifyContent='center'>
							<Typography className={cClasses.modalTitle} variant='h2'>
								Subscriptions
							</Typography>
						</Grid>
						<SubscriptionsTable
							sortHandler={sortHandler}
							matchesXS={matchesXS}
							matchesSM={matchesSM}
							headCells={headCells}
							orderBy={orderBy}
							order={order}
							rows={rows}
							printFriendly
						/>
						{matchesSM && (
							<Grid
								container
								justifyContent='flex-end'
								alignItems='center'
								style={{ marginTop: theme.spacing(1) }}
							>
								<Typography variant='body2'>Scroll for more</Typography>
								<ArrowForward
									style={{
										marginLeft: theme.spacing(1),
										color: theme.palette.grey[700]
									}}
									fontSize='small'
								/>
							</Grid>
						)}



					</Paper>
				</Fade>
			</Modal>
		</>
	);
};

export default SubscriptionsModal;
