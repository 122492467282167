import React, { useState } from 'react';
import clsx from 'clsx';
import {
	Grid,
	Fade,
	Modal,
	Paper,
	IconButton,
	Typography,
	useMediaQuery,
	useTheme,
	Button,
	CircularProgress
} from '@material-ui/core';
import {
	AddToPhotos,
	Close,
	Edit,
	Today,
	Build,
	Alarm,
	AlarmOff
} from '@material-ui/icons';
import useBStyles from 'styles/bStyles';
import useCStyles from 'styles/cStyles';
import useDStyles from 'styles/dStyles';
import { useDispatch, useSelector } from 'react-redux';
import ScheduleSettingsModal from 'components/ScheduleSettingsModal';
import ListModal from 'components/ListModal';
import defaultSchedule from '../data/defaultSchedule';
import { toggleTimesAction } from 'actions/rainbowActions';

const get12hrTime = time => {
	time = time.toString();
	let minutes = time.slice(-2);
	const hours = time.slice(0, -2);
	const isAm = Number(hours) < 12;
	if(minutes === '0') {
		minutes = '00';
	}
	if(Number(hours) === 12 || Number(hours) === 0) {
		return `12:${minutes}${isAm ? 'am' : 'pm'}`;
	}
	return `${isAm ? hours : Number(hours) - 12}:${minutes}${isAm ? 'am' : 'pm'}`;
};

const ScheduleModal = ({
	open,
	setOpen,
	useToolButton = false,
	useToolFunction
}) => {
	const bClasses = useBStyles();
	const cClasses = useCStyles();
	const dClasses = useDStyles();
	const theme = useTheme();
	const dispatch = useDispatch();

	const matchesXS = useMediaQuery(theme => theme.breakpoints.down('xs'));
	const { user, isAuth } = useSelector(state => state.userData);
	const { loading: toggleTimesIsLoading } = useSelector(
		state => state.toggleTimes
	);
	const schedule = isAuth ? user?.schedule : defaultSchedule;
	const [formState, setFormState] = useState({});
	const [modalIsOpen, setModalIsOpen] = useState(false);
	const [listModalIsOpen, setListModalIsOpen] = useState(false);
	const [showTimes, setShowTimes] = useState(true);

	const openListHandler = () => {
		setListModalIsOpen(true);
	};

	const handleEdit = (action, isEdit= true) => {
		setModalIsOpen(true);
		setFormState({
			title: {
				value: action.payload.title,
				isValid: !!action.payload.title,
				isTouched: false
			},
			start: action.payload.start ? action.payload.start : isEdit?1200:800,
			end: action.payload.end ? action.payload.end : isEdit?1200:900,
			image: {
				value: action.payload.image || ''
			},
			id: action.payload._id
		});
	};

	const useToolHandler = () => {
		useToolFunction();
		setOpen(false);
	};

	const toggleShowTimesHandler = () => {
		if (!isAuth) {
			setShowTimes(prev => !prev);
		} else {
			dispatch(toggleTimesAction());
		}
	};

	const showRoutineTimes = isAuth ? user?.showTimes : showTimes;

	return (
		<>
			<ListModal
				open={listModalIsOpen}
				setOpen={setListModalIsOpen}
				handleEdit={(payload)=>handleEdit(payload,false)}
				type='EVENT'
			/>
			{modalIsOpen && <ScheduleSettingsModal
				formState={formState}
				setFormState={setFormState}
				open={modalIsOpen}
				setOpen={setModalIsOpen}
			/>}
			<Modal open={open} onClose={() => setOpen(false)}>
				<Fade in={open}>
					<Paper
						className={bClasses.modalPaper}
						style={{
							width: !matchesXS ? 486 : '',
							height: useToolButton ? '100%' : '',
							overflow: useToolButton ? 'hidden' : '',
							paddingBottom: useToolButton ? theme.spacing(1) : ''
						}}
					>
						<IconButton
							className={bClasses.close}
							onClick={() => setOpen(false)}
						>
							<Close />
						</IconButton>

						<Grid
							container
							justifyContent='center'
							style={{ position: 'relative' }}
						>
							<Typography className={cClasses.modalTitle} variant='h2'>
								Routine
							</Typography>
							<Grid
								container
								direction={matchesXS ? 'column-reverse' : 'row'}
								alignItems='flex-start'
								justifyContent={!isAuth ? 'center' : 'flex-start'}
								wrap='nowrap'
								style={{
									position: 'absolute',
									top: 0,
									right: 0,
									height: '100%',
									width: 'min-content',
									marginTop: matchesXS ? -theme.spacing(1) : ''
								}}
							>
								<IconButton
									style={{ padding: theme.spacing(0, 1) }}
									color='secondary'
									onClick={toggleShowTimesHandler}
								>
									{toggleTimesIsLoading ? (
										<CircularProgress size={35} color='secondary' />
									) : !showRoutineTimes ? (
										<AlarmOff
											style={{
												width: 35,
												height: 35
											}}
										/>
									) : (
										<Alarm
											style={{
												width: 35,
												height: 35
											}}
										/>
									)}
								</IconButton>
								{isAuth && (
									<IconButton
										style={{ padding: theme.spacing(0, 1) }}
										color='secondary'
										onClick={openListHandler}
									>
										<AddToPhotos
											style={{
												width: 35,
												height: 35
											}}
										/>
									</IconButton>
								)}
							</Grid>
						</Grid>
						<div
							className={clsx(useToolButton && cClasses.scrollContainer)}
							style={{
								padding: 0,
								height: useToolButton ? 'calc(100% - 132px)' : ''
							}}
						>
							<Grid container direction='column'>
								{schedule.map(event => (
									<Paper
										key={isAuth ? event._id : event.title}
										variant='outlined'
										component={Grid}
										alignItems='center'
										container
										wrap='nowrap'
									>
										{event.image ? (
											<img
												src={event.image}
												style={{ width: 100, height: 100 }}
												alt={event.title}
											/>
										) : (
											<Grid
												container
												alignItems='center'
												justifyContent='center'
												style={{ width: 100, height: 100 }}
											>
												<Today fontSize='large' />
											</Grid>
										)}
										<Grid
											container
											direction='column'
											item
											zeroMinWidth
											xs
											style={{ paddingLeft: theme.spacing(2) }}
										>
											<Typography variant={matchesXS ? 'h4' : 'h3'}>
												{event.title}
											</Typography>
											{showRoutineTimes && (
												<Typography variant={matchesXS ? 'h5' : 'h4'}>
													{get12hrTime(event.start)} - {get12hrTime(event.end)}
												</Typography>
											)}
										</Grid>

										{isAuth && (
											<IconButton
												onClick={() =>
													handleEdit({
														payload: event
													})
												}
											>
												<Edit color='secondary' />
											</IconButton>
										)}
									</Paper>
								))}
							</Grid>
						</div>
						{useToolButton && (
							<Grid
								container
								justifyContent='center'
								style={{ padding: theme.spacing(1) }}
							>
								<Button
									endIcon={<Build />}
									variant='contained'
									disableElevation
									color='secondary'
									className={dClasses.toolModalButton}
									onClick={useToolHandler}
								>
									Use Tool
								</Button>
							</Grid>
						)}
					</Paper>
				</Fade>
			</Modal>
		</>
	);
};

export default ScheduleModal;
