import React from 'react';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	List,
	ListItem,
	Button
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { CLOSE_SUMMARY } from 'constants/rainbowConstants';
import useBStyles from 'styles/bStyles';

const SummaryMessage = () => {
	const bClasses = useBStyles();
	const dispatch = useDispatch();
	const { summary, degreeRange: defaultDegreeRange } = useSelector(
		state => state.rainbowState
	);
	const { isAuth, user } = useSelector(state => state.userData);
	const handleCloseSummary = () => {
		dispatch({ type: CLOSE_SUMMARY });
	};
	const degreeRange = isAuth ? user.degreeRange : defaultDegreeRange;

	return (
		<Dialog open={!!summary} onClose={handleCloseSummary}>
			<DialogTitle className={bClasses.titleColor}>Well done!</DialogTitle>
			<DialogContent>
				<List className={bClasses.summaryList}>
					<ListItem>
						Emotion:
						<span>{summary?.emotion.title}</span>
					</ListItem>
					{!!degreeRange && (
						<ListItem>
							Degree:
							<span>
								{summary?.degree}/{degreeRange}
							</span>
						</ListItem>
					)}
					<ListItem>
						Tool: <span>{summary?.tool.title}</span>
					</ListItem>
					<ListItem>
						Tool Category: <span>{summary?.toolCategory}</span>
					</ListItem>
					<ListItem>
						Outcome: <span>{summary?.outcome}</span>
					</ListItem>
				</List>
				<DialogContentText className={bClasses.summaryText}>
					{isAuth ? (
						<>
							A full history can be found in your{' '}
							<Link
								onClick={handleCloseSummary}
								className={bClasses.summaryLink}
								to='/profile'
							>
								Profile
							</Link>
						</>
					) : (
						<>
							Please{' '}
							<Link
								onClick={handleCloseSummary}
								className={bClasses.summaryLink}
								to='/login'
							>
								login
							</Link>{' '}
							to record usage history{' '}
						</>
					)}
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button
					color='secondary'
					style={{ fontWeight: 800 }}
					onClick={handleCloseSummary}
				>
					Ok
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default SummaryMessage;
