import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import clsx from 'clsx';
import {
	Fade,
	Grid,
	IconButton,
	Modal,
	Typography,
	Paper,
	TextField,
	Button,
	CircularProgress,
	useTheme,
	useMediaQuery, Select, MenuItem
} from '@material-ui/core';
import { Close, PersonAdd } from '@material-ui/icons';
import useBStyles from 'styles/bStyles';
import { useDispatch, useSelector } from 'react-redux';
import {
	PURCHASE_USERS_REQUEST,
	PURCHASE_USERS_FAIL,
	PURCHASE_USERS_SUCCESS
} from 'constants/userConstants';
import { sendPurchasedUsersAction, getUserAction } from 'actions/userActions';

const AppSettingsModal = ({ open, setOpen }) => {
	const bClasses = useBStyles();
	const dispatch = useDispatch();
	const theme = useTheme();
	const stripe = useStripe();
	const elements = useElements();
	const matchesXS = useMediaQuery(theme => theme.breakpoints.down('xs'));

	const { loading: purchaseUsersLoading, success: puchaseUsersSuccess } =
		useSelector(state => state.purchaseUsers);
	const { token } = useSelector(state => state.userData);

	const [numberOfUsers, setNumberOfUsers] = useState(1);
	const [selectedPrice, setSelectedPrice] = useState(1);
	const [selectedPriceType, setSelectedPriceType] = useState('yearly');
	const [totalPrice, setTotalPrice] = useState(99.00);

	const submitHandler = async e => {
		e.preventDefault();
		try {
			// purchase users is loading = true
			dispatch({
				type: PURCHASE_USERS_REQUEST
			});
			// get React card element
			const cardElement = elements.getElement(CardElement);
			// use card element to create payment method
			const { error, paymentMethod } = await stripe.createPaymentMethod({
				type: 'card',
				card: cardElement
			});
			if (error) throw error;

			const config = {
				headers: {
					'Content-Type': 'application/json'
				}
			};
			// retrive client secret for transation from backend
			/*const {
				data: { clientSecret, numberOfUsers: resNumberOfUsers }
			} = await axios.post(
				'/api/v1/users/payment-intent',
				{
					numberOfUsers
				},
				config
			);*/

			// add users to account
			await axios.post(
				'/api/v1/users/purchaseusers',
				{
					numberOfUsers: numberOfUsers,
					paymentMethod: paymentMethod.id,
					selectedPriceType: selectedPriceType
				},
				{
					headers: {
						...config.headers,
						'Authorization': `Bearer ${token}`
					}
				}
			);

			// confirm payment
			/*await stripe.confirmCardPayment(clientSecret, {
				payment_method: paymentMethod.id
			});*/

			// signup loading = false
			// display success message
			dispatch({
				type: PURCHASE_USERS_SUCCESS,
				payload: `${
					numberOfUsers > 1 ? 'A list of user codes have' : 'A user code has'
				} been sent to you email inbox (You may need to check your junk/spam folder)`
			});
			//send email
			dispatch(sendPurchasedUsersAction());
			// get user
			dispatch(getUserAction());
		} catch (err) {
			dispatch({
				type: PURCHASE_USERS_FAIL,
				payload:
					err.response && err.response.data.message
						? err.response.data.message
						: err.message
			});
		}
	};

	const getPricePerUnitByType = (type = 'yearly', numberOfUsers) => {
		switch (type) {
			case 'yearly': {
				if (numberOfUsers < 10) {
					return 99.00;
				} else if (numberOfUsers < 20) {
					return 78.00;
				} else if (numberOfUsers >= 20) {
					return 44.00;
				}
			}
			case 'monthly': {
				if (numberOfUsers < 10) {
					return 9.99;
				} else if (numberOfUsers < 20) {
					return 7.77;
				} else if (numberOfUsers >= 20) {
					return 4.44;
				}
			}
			default:
				return 99.00;
		}
		return 99.00;
	}

	const numberOfUsersHandler = (e, action) => {
		const value = action.type === 'RANGE' ? action.value : e.target.value;
		if (value > 0) {
			setNumberOfUsers(value);
			const pricePerUnitByType = getPricePerUnitByType(selectedPriceType, value);
			if (value < 10) {
				setSelectedPrice(1);
			} else if (value < 20) {
				setSelectedPrice(2);
			} else if (value >= 20) {
				setSelectedPrice(3);
			}
			setTotalPrice((value * pricePerUnitByType).toFixed(2));
		}
	};

	const handleChangeSelectedPriceType = (value) => {
		setSelectedPriceType(value);
		const pricePerUnitByType = getPricePerUnitByType(value, numberOfUsers);
		setTotalPrice((numberOfUsers * pricePerUnitByType).toFixed(2));
	}

	useEffect(() => {
		if (puchaseUsersSuccess) setOpen(false);
	}, [puchaseUsersSuccess, setOpen]);

	return (
		<Modal open={open} onClose={() => setOpen(false)}>
			<Fade in={open}>
				<Paper
					className={bClasses.modalPaper}
					style={{ width: matchesXS ? '' : 400 }}
				>
					<IconButton className={bClasses.close} onClick={() => setOpen(false)}>
						<Close />
					</IconButton>
					<Grid container direction='column' alignItems='center'>
						<Typography variant='h3'>Purchase Users</Typography>
						<form onSubmit={submitHandler} className={bClasses.modalForm}>
							<TextField
								id='numberOfUsers'
								label='Number of Users'
								type='number'
								placeholder='Number of Users'
								fullWidth
								color='secondary'
								value={numberOfUsers}
								onChange={e => numberOfUsersHandler(e, { type: 'INPUT' })}
								className={clsx(bClasses.input, bClasses.numberUsersInput)}
							/>
							<Select
								labelId="plan-simple-select-label"
								id="plan-simple-select"
								value={selectedPriceType}
								onChange={(event) => handleChangeSelectedPriceType(event.target.value)}
								className={clsx(bClasses.numberUsersInput)}
							>
								<MenuItem value='monthly'>Monthly</MenuItem>
								<MenuItem value='yearly'>Yearly</MenuItem>
							</Select>
							<br/>
							<Grid container>
								<Grid
									item
									xs={4}
									className={bClasses.priceGridItem}
									container
									justifyContent='center'
									style={{ padding: selectedPrice === 1 ? 0 : '' }}
								>
									<Button
										className={clsx(
											selectedPrice === 1 && bClasses.selectedPrice
										)}
										variant={selectedPrice === 1 ? 'contained' : 'outlined'}
										color={selectedPrice === 1 ? 'secondary' : 'default'}
										style={{ padding: theme.spacing(1) }}
										onClick={e =>
											numberOfUsersHandler(e, { type: 'RANGE', value: 1 })
										}
									>
										<Grid
											container
											direction='column'
											alignItems='center'
											variant='outlined'
											justifyContent='center'
										>
											<Typography>1-9 Users</Typography>
											<Typography>${getPricePerUnitByType(selectedPriceType,1)} ea</Typography>

										</Grid>
									</Button>
								</Grid>
								<Grid
									item
									xs={4}
									className={bClasses.priceGridItem}
									container
									justifyContent='center'
									style={{ padding: selectedPrice === 2 ? 0 : '' }}
								>
									<Button
										className={clsx(
											selectedPrice === 2 && bClasses.selectedPrice
										)}
										style={{ padding: theme.spacing(1) }}
										variant={selectedPrice === 2 ? 'contained' : 'outlined'}
										color={selectedPrice === 2 ? 'secondary' : 'default'}
										onClick={e =>
											numberOfUsersHandler(e, { type: 'RANGE', value: 10 })
										}
									>
										<Grid
											container
											direction='column'
											alignItems='center'
											variant='outlined'
											justifyContent='center'
										>
											<Typography>10-19 Users</Typography>
											<Typography>${getPricePerUnitByType(selectedPriceType,10)} ea</Typography>
										</Grid>
									</Button>
								</Grid>
								<Grid
									item
									xs={4}
									className={bClasses.priceGridItem}
									container
									justifyContent='center'
									style={{ padding: selectedPrice === 3 ? 0 : '' }}
								>
									<Button
										className={clsx(
											selectedPrice === 3 && bClasses.selectedPrice
										)}
										style={{ padding: theme.spacing(1) }}
										variant={selectedPrice === 3 ? 'contained' : 'outlined'}
										color={selectedPrice === 3 ? 'secondary' : 'default'}
										onClick={e =>
											numberOfUsersHandler(e, { type: 'RANGE', value: 20 })
										}
									>
										<Grid
											container
											direction='column'
											alignItems='center'
											justifyContent='center'
										>
											<Typography>20+ Users</Typography>
											<Typography>${getPricePerUnitByType(selectedPriceType,20)} ea</Typography>
										</Grid>
									</Button>
								</Grid>
							</Grid>
							<Grid>
								<Typography className={bClasses.totalPrice}>
									<span className={bClasses.total}>Total:</span> ${totalPrice}
									<span className={bClasses.currency}> AUD</span>
								</Typography>
							</Grid>
							<Grid container direction='column'>
								<Typography className={bClasses.cardInputLabel}>
									Pay with credit card:
								</Typography>
								<Paper
									variant='outlined'
									style={{ width: '100%' }}
									className={clsx(
										bClasses.cardInputPaper,
										(!stripe || !elements) && bClasses.cardDisabled
									)}
								>
									{stripe && elements ? (
										<CardElement
											options={{
												style: {
													base: {
														fontSize: '18px',
														color: theme.palette.text.primary,
														'::placeholder': {
															color: theme.palette.text.primary
														}
													},
													invalid: {
														color: theme.palette.error.main
													}
												}
											}}
											className={bClasses.cardInput}
										/>
									) : (
										<Typography variant='body2'>
											Loading credit card input...
										</Typography>
									)}
								</Paper>
							</Grid>

							<Button
								variant='contained'
								type='submit'
								endIcon={<PersonAdd />}
								className={bClasses.button}
								fullWidth
								color='secondary'
								disableElevation
								style={{ fontWeight: 700, fontSize: '1.1rem' }}
								disabled={!stripe || !elements}
							>
								{purchaseUsersLoading ? (
									<CircularProgress size={25} style={{ color: '#fff' }} />
								) : (
									'Purchase'
								)}
							</Button>
						</form>
					</Grid>
				</Paper>
			</Fade>
		</Modal>
	);
};

export default AppSettingsModal;
