import { Close } from '@material-ui/icons';
import {
	Paper,
	Typography,
	Grid,
	useTheme,
	IconButton
} from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router-dom';
import useCStyles from 'styles/cStyles';
import useBStyles from 'styles/bStyles';

const AboutScreen = () => {
	const bClasses = useBStyles();
	const cClasses = useCStyles();
	const theme = useTheme();
	const history = useHistory();
	return (
		<Paper
			classes={{ root: cClasses.screenRoot }}
			elevation={5}
			component={Grid}
			container
			direction='column'
			alignItems='center'
			className={cClasses.termsPaper}
		>
			<IconButton className={bClasses.close} onClick={() => history.push('/')}>
				<Close />
			</IconButton>
			<Typography variant='h3' style={{ marginBottom: theme.spacing(1) }}>
				Privacy Policy
			</Typography>

			<Grid>
				<Typography>
					BEHAVIOUR HELP – PRIVACY POLICY This Privacy Policy sets out the
					commitment of Behaviour Help Pty Ltd (ACN 612 728 275), successors and
					assignees (referred to as we, us and our) to protecting the privacy of
					your personal information collected through this website 
					<a
						href='https://www.behaviourhelp.com'
						target='_blank'
						rel='noreferrer noopener'
					>
						www.behaviourhelp.com
					</a>{' '}
					and{' '}
					<a
						href='https://rainbowofemotions.app'
						target='_blank'
						rel='noreferrer noopener'
					>
						rainbowofemotions.app
					</a>{' '}
					(Site), the Rainbow of Emotions application (App) or directly from
					you, being the person, organisation or entity that uses our Site, App
					or services (referred to as you or your).
				</Typography>
				<Typography> Please read this Privacy Policy carefully.</Typography>
				<Typography>
					Please contact us if you have any questions – our contact details are
					at the end of this Privacy Policy.
				</Typography>
				<Typography>
					{' '}
					You providing us with personal information indicates that you have had
					sufficient opportunity to access this Privacy Policy and that you have
					read and accepted it.
				</Typography>
				<Typography>
					{' '}
					If you do not wish to provide personal information to us, then you do
					not have to do so, however it may affect your use of this Site, App or
					any products and services offered on the Site or App.
				</Typography>
				<Typography>
					{' '}
					1. Collection of personal information Personal information: The type
					of information we collect may include: • name; •contact details
					including email address and address; •if you sign up as part of school
					or other education institution, the name of the school or institution;
					•preferences and opinions; and • any other information requested on
					this Site, App or otherwise requested by us or provided by you.
				</Typography>
				<Typography>
					{' '}
					Your use of our Site and App: As with most online businesses, we may
					log information about your access and use of our Site or App,
					including through the use of Internet cookies, your communications
					with our Site or App, the type of browser you are using, the type of
					operating system you are using and the domain name of your Internet
					service provider.
				</Typography>
				<Typography>
					{' '}
					Your opinion and feedback: We may contact you to voluntarily respond
					to questionnaires, surveys or market research to seek your opinion and
					feedback.
				</Typography>
				<Typography> Providing this information is optional to you.</Typography>
				<Typography>
					{' '}
					If we receive your personal information from third parties, we will
					protect it as set out in this Privacy Policy.
				</Typography>
				<Typography>
					{' '}
					2. Collection and use of personal information We collect and use the
					information for purposes including: • to contact and communicate with
					you; • for internal record keeping; • for market research and business
					development including website and application development; • to offer
					additional benefits to you; and • to send you information about third
					parties that we think may be of interest to you.
				</Typography>
				<Typography>
					{' '}
					3. Disclosure of personal information to third parties We may disclose
					personal information to: • credit reporting agencies and courts,
					tribunals and regulatory authorities where customers fail to pay for
					goods or services provided by us to them; • courts, tribunals,
					regulatory authorities and law enforcement officers as required by
					law, in connection with any actual or prospective legal proceedings,
					or in order to establish, exercise or defend our legal rights; and •
					third parties, including agents or sub-contractors, who assist us in
					providing information, products, services or direct marketing to you.
				</Typography>
				<Typography>
					{' '}
					This may include parties located, or that store data, outside of
					Australia.
				</Typography>
				<Typography>
					{' '}
					Where we disclose your personal information to third parties for these
					purposes, we will request that the third party follow this Privacy
					Policy regarding handling of your personal information.
				</Typography>
				<Typography>
					{' '}
					If there is a change of control of our business or a sale or transfer
					of business assets, we reserve the right to transfer to the extent
					permissible by law our user databases, together with any personal
					information and non-personal information contained in those databases.
				</Typography>
				<Typography>
					This information may be disclosed to a potential purchaser.
				</Typography>
				<Typography>
					{' '}
					We would seek to only disclose information in good faith.
				</Typography>
				<Typography>
					{' '}
					4. How we treat personal information that is also sensitive
					information Sensitive information is a sub-set of personal information
					that is given a higher level of protection under the Australian
					Privacy Principles.
				</Typography>
				<Typography>
					{' '}
					In referring to ‘sensitive information’ we mean information that
					relates to your racial or ethnic origin, political opinions, religion,
					trade union or other professional associations or memberships,
					philosophical beliefs, sexual orientation or practices and criminal
					records, health information or biometric information.
				</Typography>
				<Typography>
					{' '}
					The type of health information that we may collect includes: •
					information about an individual’s physical or mental health; and •
					notes on individual’s symptoms and observed behaviour.
				</Typography>
				<Typography>
					{' '}
					Sensitive information will be used and disclosed for purposes relating
					to the primary purpose for which the sensitive information was
					collected, including to provide the services as set out on the Site
					and App.
				</Typography>
				<Typography>
					{' '}
					Sensitive information may also be used or disclosed if required or
					authorised by law or to a responsible person which has the meaning
					given by section 6AA of the Privacy Act 1988 (Cth).
				</Typography>
				<Typography>
					{' '}
					5. Your rights and controlling your personal information Choice and
					consent: Providing us with your personal information is optional to
					you.
				</Typography>
				<Typography>
					{' '}
					You can choose not to provide personal information.
				</Typography>
				<Typography>
					{' '}
					When you provide us with your personal information, you consent to the
					terms in this Privacy Policy, and to us disclosing or receiving your
					personal information for these purposes.
				</Typography>
				<Typography>
					{' '}
					Your provision of third party information: If you provide us with
					third party personal information then you warrant to us that you have
					the third party’s consent to provide this.
				</Typography>
				<Typography>
					{' '}
					Restrict: You may choose to restrict the collection or use of your
					personal information.
				</Typography>
				<Typography>
					{' '}
					If you have previously agreed to us using your personal information
					for direct marketing purposes, you may change your mind at any time by
					contacting us at the email address listed in this Privacy Policy.
				</Typography>
				<Typography>
					{' '}
					Access: You may request details of personal information that we hold
					about you, in certain circumstances set out in the Privacy Act 1988
					(Cth).
				</Typography>
				<Typography>
					{' '}
					An administrative fee may be payable for the provision of information.
				</Typography>
				<Typography>
					{' '}
					We may refuse to provide you with information that we hold about you,
					in certain circumstances set out in the Privacy Act.
				</Typography>
				<Typography>
					Correction: If you believe that any information we hold about you is
					inaccurate, out of date, incomplete, irrelevant or misleading, please
					contact us by email.
				</Typography>
				<Typography>
					{' '}
					We rely in part upon customers advising us when their personal
					information changes.
				</Typography>
				<Typography>
					{' '}
					We will respond to any request within a reasonable time.
				</Typography>
				<Typography>
					{' '}
					We will endeavour to promptly correct any information found to be
					inaccurate, incomplete or out of date.
				</Typography>
				<Typography>
					Complaints: If you believe that we have breached the Australian
					Privacy Principles and wish to make a complaint about that breach,
					please contact us by email setting out details of the breach.
				</Typography>
				<Typography>
					{' '}
					We will promptly investigate your complaint and respond to you in
					writing setting out the outcome of our investigation, what steps we
					propose to take to remedy the breach and any other action we will take
					to deal with your complaint.
				</Typography>
				<Typography>
					{' '}
					Unsubscribe: To unsubscribe from our e-mail database, or opt out of
					communications, please contact us using the details below.
				</Typography>
				<Typography>
					{' '}
					6. Storage and Security We are committed to ensuring that the
					information you provide is secure.
				</Typography>
				<Typography>
					{' '}
					In order to prevent unauthorised access or disclosure, we have put in
					place suitable physical, electronic and managerial procedures to
					safeguard and secure the information and protect it from misuse,
					interference, loss and unauthorised access, modification and
					disclosure.
				</Typography>
				<Typography>
					{' '}
					No information transmitted over the Internet can be guaranteed to be
					secure.
				</Typography>
				<Typography>
					{' '}
					We cannot guarantee the security of any information that you transmit
					to us, or receive from us.
				</Typography>
				<Typography>
					The transmission and exchange of information is carried out at your
					own risk.
				</Typography>
				<Typography>
					{' '}
					Although we take measures to safeguard against unauthorised
					disclosures of information, we cannot assure you that personal
					information that we collect will not be disclosed in a manner that is
					inconsistent with this Privacy Policy.
				</Typography>
				<Typography>
					{' '}
					7. Cookies & Web Beacons We may use cookies on this Site and App from
					time to time.
				</Typography>
				<Typography>
					{' '}
					Cookies are text files placed in your computer's browser to store your
					preferences.
				</Typography>
				<Typography>
					Cookies, by themselves, do not tell us your e-mail address or other
					personally identifiable information.
				</Typography>
				<Typography>
					{' '}
					However, once you choose to furnish the Site and App with personally
					identifiable information, this information may be linked to the data
					stored in the cookie.
				</Typography>
				<Typography>
					{' '}
					We may use web beacons on this Site and App from time to time.
				</Typography>
				<Typography>
					{' '}
					Web beacons or clear .gifs are small pieces of code placed on a web
					page to monitor the visitors’ behaviour and collect data about the
					visitors viewing a web page.
				</Typography>
				<Typography>
					{' '}
					For example, web beacons can be used to count the users who visit a
					web page or to deliver a cookie to the browser of a visitor viewing
					that page.
				</Typography>
				<Typography>
					{' '}
					8. Links to other websites Our Site and App may contain links to other
					websites of interest.
				</Typography>
				<Typography>
					{' '}
					We do not have any control over those websites.
				</Typography>
				<Typography>
					{' '}
					We are not responsible for or liable for the protection and privacy of
					any information which you provide whilst visiting such websites, and
					such websites are not governed by this Privacy Policy.
				</Typography>
				<Typography>
					{' '}
					9. Amendments This Privacy Policy may be amended, including with
					changes, additions and deletions, from time to time in our sole
					discretion.
				</Typography>
				<Typography>
					{' '}
					Your continued use of our Site and App following any amendments
					indicates that you accept the amendments.
				</Typography>
				<Typography>
					{' '}
					You should check this Privacy Policy regularly, prior to providing
					personal information, to ensure you are aware of any changes, and only
					proceed to provide personal information if you accept the new Privacy
					Policy.
				</Typography>
				<Typography>
					{' '}
					For any questions or notice, please contact us at: Behaviour Help Pty
					Ltd (ACN 612 728 275) PO Box 5078 Cheltenham VIC 3192 Email:
					<a className={cClasses.link} href='mailto: dolly@behaviourhelp.com'>
						dolly@behaviourhelp.com
					</a>{' '}
					Ph: 0423 293 254
				</Typography>
			</Grid>
		</Paper>
	);
};

export default AboutScreen;
