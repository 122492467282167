import React, { useState } from 'react';
import clsx from 'clsx';
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Grid,
	Switch,
	withStyles,
	Button,
	useMediaQuery
} from '@material-ui/core';
import useBStyles from 'styles/bStyles';
import Body from 'assets/svg/body1.svg';
import SmallRainbow from 'assets/svg/smallRainbow.svg';
import { useSelector, useDispatch } from 'react-redux';
import {
	SET_NEGATIVE_EMOTIONS,
	SET_PHYSICAL_EMOTIONS,
	CLOSE_EMOTION,
	CLOSE_TOOLKIT
} from 'constants/rainbowConstants';
import { SentimentVeryDissatisfied } from '@material-ui/icons';

const CustomEmotionSwitch = withStyles(theme => ({
	thumb: {
		color: theme.palette.secondary.main
	},
	track: {
		backgroundColor: theme.palette.secondary.dark
	}
}))(Switch);

const EmotionSwitch = () => {
	const mediaMax700 = useMediaQuery('(max-width:700px)');
	const mediaMin600 = useMediaQuery('(min-width:600px)');
	const classes = useBStyles();
	const dispatch = useDispatch();
	const { emotionType, activeEmotion } = useSelector(
		state => state.rainbowState
	);
	const { isAuth, user } = useSelector(state => state.userData);
	const {
		negativeEmotions: defaultNegativeEmotions,
		physicalEmotions: defaultPhysicalEmotions
	} = useSelector(state => state.getEmotions);
	const negativeEmotions = isAuth
		? user.negativeEmotions
		: defaultNegativeEmotions;
	const physicalEmotions = isAuth
		? user.physicalEmotions
		: defaultPhysicalEmotions;

	const [messageIsOpen, setMessageIsOpen] = useState(false);
	const [messageEmotionType, setMessageEmotionType] = useState(emotionType);

	const handleSwitch = e => {
		setMessageIsOpen(true);
		setMessageEmotionType(emotionType === 'negative' ? 'physical' : 'negative');
		if (activeEmotion) {
			dispatch({ type: CLOSE_EMOTION });
			dispatch({ type: CLOSE_TOOLKIT });
			setTimeout(
				() => {
					dispatch({
						type:
							emotionType === 'negative'
								? SET_PHYSICAL_EMOTIONS
								: SET_NEGATIVE_EMOTIONS
					});
				},
				emotionType === 'negative'
					? negativeEmotions.length * 100 + 900
					: physicalEmotions.length * 100 + 900
			);
		} else {
			dispatch({
				type:
					emotionType === 'negative'
						? SET_PHYSICAL_EMOTIONS
						: SET_NEGATIVE_EMOTIONS
			});
		}
	};

	return (
		<>
			<Dialog
				classes={{ paper: classes.paper }}
				open={!!messageIsOpen}
				onClose={() => setMessageIsOpen(false)}
				transitionDuration={{ exit: 0 }}
			>
				<DialogTitle className={classes.titleColor}>
					{messageEmotionType === 'physical'
						? 'Physical Emotions'
						: 'Negative Emotions'}
				</DialogTitle>
				<DialogContent>
					<DialogContentText className={classes.text}>
						{messageEmotionType === 'physical' ? (
							<>
								The rainbow is now displaying physical emotions.
								<br />
								Example: <strong>hungry</strong>, <strong>hurt</strong> or{' '}
								<strong>pain</strong>.
							</>
						) : (
							<>
								The rainbow is now displaying negative emotions. <br />
								Example: <strong>angry</strong>, <strong>anxious</strong> or{' '}
								<strong>sad</strong>.
							</>
						)}
					</DialogContentText>
					<Grid
						container
						justifyContent='center'
						className={classes.messageIconContainer}
					>
						<Body
							className={clsx(
								classes.messageIcon,
								messageEmotionType === 'physical'
									? classes.messageIconInRight
									: classes.messageIconOutLeft
							)}
						/>
						<SentimentVeryDissatisfied
							className={clsx(
								classes.messageIcon,
								messageEmotionType === 'negative'
									? classes.messageIconInLeft
									: classes.messageIconOutRight
							)}
						/>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button
						className={classes.alert}
						onClick={() => setMessageIsOpen(false)}
					>
						Ok
					</Button>
				</DialogActions>
			</Dialog>
			<Grid
				container
				justifyContent='center'
				align='center'
				className={clsx(
					classes.emotionSwitchContainer,
					mediaMax700 && mediaMin600 && classes.emotionSwitchContainerSmall
				)}
				style={{ width: 120 }}
				onClick={handleSwitch}
			>
				<SmallRainbow
					className={clsx(
						classes.rainbowIcon,
						messageEmotionType === 'physical' && classes.rainbowIconTranslate
					)}
				/>
				<SentimentVeryDissatisfied
					style={{ stroke: 'none' }}
					className={clsx(
						classes.brain,
						emotionType === 'negative' && classes.blue
					)}
				/>
				<CustomEmotionSwitch
					color='secondary'
					checked={messageEmotionType === 'physical'}
				></CustomEmotionSwitch>
				<Body
					className={clsx(
						classes.body,
						emotionType === 'physical' && classes.blue
					)}
				/>
			</Grid>
		</>
	);
};

export default EmotionSwitch;
