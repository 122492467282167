import React from 'react';
import Message from 'components/Message';
import SnackBar from 'components/SnackBar';
import {
	SIGNUP_CLEAR,
	SEND_VERIFY_USER_CLEAR,
	LOGIN_CLEAR,
	VERIFY_USER_CLEAR,
	FORGOT_PASSWORD_CLEAR,
	RESET_PASSWORD_CLEAR,
	USER_UPDATE_PROFILE_CLEAR,
	CANCEL_EMAIL_UPDATE_CLEAR,
	VERIFY_EMAIL_UPDATE_CLEAR,
	GET_USERS_CLEAR,
	GET_USER_CLEAR,
	SET_ADMIN_CLEAR,
	UPLOAD_PROFILE_IMAGE_CLEAR,
	PURCHASE_USERS_CLEAR,
	SEND_PURCHASED_USERS_CLEAR
} from 'constants/userConstants';
import {
	UPLOAD_RAINBOW_IMAGE_CLEAR,
	DELETE_RAINBOW_IMAGE_CLEAR,
	UPLOAD_AUDIO_CLEAR,
	DELETE_AUDIO_CLEAR,
	CREATE_RAINBOW_ELEMENT_CLEAR,
	UPDATE_RAINBOW_ELEMENT_CLEAR,
	DELETE_RAINBOW_ELEMENT_CLEAR,
	ADD_HISTORY_CLEAR,
	UPDATE_EMOTION_RATING_CLEAR,
	ADD_EVENT_CLEAR,
	EDIT_EVENT_CLEAR,
	DELETE_EVENT_CLEAR,
	TOGGLE_TIMES_CLEAR
} from 'constants/rainbowConstants';
import { sendVerifyUserAction } from 'actions/userActions';
import { useSelector } from 'react-redux';

const UserFeedback = ({ authFormEmail }) => {
	const {
		signup: { error: signupError, success: signupSuccess },
		sendVerifyUser: {
			error: sendVerifyUserError,
			success: sendVerifyUserSuccess
		},
		login: { error: loginError },
		verifyUser: { error: verifyUserError, success: verifyUserSuccess },
		forgotPassword: {
			error: forgotPasswordError,
			success: forgotPasswordSuccess
		},
		resetPassword: { error: resetPasswordError, success: resetPasswordSuccess },
		userUpdateProfile: {
			error: userUpdateProfileError,
			success: userUpdateProfileSuccess
		},
		cancelEmailUpdate: {
			error: cancelEmailUpdateError,
			success: cancelEmailUpdateSuccess
		},
		verifyEmailUpdate: {
			error: verifyEmailUpdateError,
			success: verifyEmailUpdateSuccess
		},
		setAdmin: { error: setAdminError, success: setAdminSuccess },
		uploadProfileImage: { error: uploadProfileImageError },
		uploadRainbowImage: { error: uploadRainbowImageError },
		deleteRainbowImage: {
			error: deleteRainbowImageError,
			success: deleteRainbowImageSuccess
		},
		uploadAudio: { error: uploadAudioError },
		deleteAudio: { error: deleteAudioError },
		getUsers: { error: getUsersError },
		getUser: { error: getUserError },
		createRainbowElement: {
			error: createRainbowElementError,
			success: createRainbowElementSuccess
		},
		updateRainbowElement: {
			error: updateRainbowElementError,
			success: updateRainbowElementSuccess
		},
		deleteRainbowElement: {
			error: deleteRainbowElementError,
			success: deleteRainbowElementSuccess
		},
		addHistory: { error: addHistoryError, success: addHistorySuccess },
		updateEmotionRating: {
			error: updateEmotionRatingError,
			success: updateEmotionRatingSuccess
		},
		addEvent: { error: addEventError, success: addEventSuccess },
		editEvent: { error: editEventError, success: editEventSuccess },
		deleteEvent: { error: deleteEventError, success: deleteEventSuccess },
		toggleTimes: { error: toggleTimesError, success: toggleTimesSuccess },
		purchaseUsers: { error: purchaseUsersError, success: purchaseUsersSuccess },
		sendPurchasedUsers: { success: sendPurchasedUsersSuccess }
	} = useSelector(state => state);

	let uploadError = null;
	if (
		[uploadProfileImageError, uploadRainbowImageError, uploadAudioError].some(
			err => err === 'Request failed with status code 413'
		)
	)
		uploadError = 'Uploaded files must be below 2Mb';

	return (
		<>
			<Message
				error={
					signupError ||
					sendVerifyUserError ||
					loginError ||
					verifyUserError ||
					forgotPasswordError ||
					resetPasswordError ||
					userUpdateProfileError ||
					cancelEmailUpdateError ||
					verifyEmailUpdateError ||
					uploadError ||
					deleteAudioError ||
					deleteRainbowImageError ||
					createRainbowElementError ||
					deleteRainbowImageError ||
					updateRainbowElementError ||
					addHistoryError ||
					updateEmotionRatingError ||
					addEventError ||
					editEventError ||
					deleteEventError ||
					toggleTimesError ||
					purchaseUsersError
				}
				success={
					forgotPasswordSuccess
						? forgotPasswordSuccess
						: signupSuccess
						? signupSuccess
						: purchaseUsersSuccess
						? purchaseUsersSuccess
						: userUpdateProfileSuccess === 'Profile Updated'
						? null
						: userUpdateProfileSuccess
						? userUpdateProfileSuccess
						: null
				}
				clearType={
					signupError || signupSuccess
						? SIGNUP_CLEAR
						: sendVerifyUserError
						? SEND_VERIFY_USER_CLEAR
						: loginError
						? LOGIN_CLEAR
						: verifyUserError
						? VERIFY_USER_CLEAR
						: forgotPasswordError
						? FORGOT_PASSWORD_CLEAR
						: resetPasswordError
						? RESET_PASSWORD_CLEAR
						: userUpdateProfileError || userUpdateProfileSuccess
						? USER_UPDATE_PROFILE_CLEAR
						: cancelEmailUpdateError
						? CANCEL_EMAIL_UPDATE_CLEAR
						: verifyEmailUpdateError
						? VERIFY_EMAIL_UPDATE_CLEAR
						: uploadProfileImageError
						? UPLOAD_PROFILE_IMAGE_CLEAR
						: getUsersError
						? GET_USERS_CLEAR
						: getUserError
						? GET_USER_CLEAR
						: setAdminError
						? SET_ADMIN_CLEAR
						: uploadRainbowImageError
						? UPLOAD_RAINBOW_IMAGE_CLEAR
						: uploadAudioError
						? UPLOAD_AUDIO_CLEAR
						: deleteAudioError
						? DELETE_AUDIO_CLEAR
						: deleteRainbowImageError
						? DELETE_RAINBOW_IMAGE_CLEAR
						: createRainbowElementError
						? CREATE_RAINBOW_ELEMENT_CLEAR
						: updateRainbowElementError
						? UPDATE_RAINBOW_ELEMENT_CLEAR
						: deleteRainbowElementError
						? DELETE_RAINBOW_ELEMENT_CLEAR
						: addHistoryError
						? ADD_HISTORY_CLEAR
						: updateEmotionRatingError
						? UPDATE_EMOTION_RATING_CLEAR
						: forgotPasswordSuccess
						? FORGOT_PASSWORD_CLEAR
						: addEventError
						? ADD_EVENT_CLEAR
						: editEventError
						? EDIT_EVENT_CLEAR
						: deleteEventError
						? DELETE_EVENT_CLEAR
						: toggleTimesError
						? TOGGLE_TIMES_CLEAR
						: purchaseUsersError || purchaseUsersSuccess
						? PURCHASE_USERS_CLEAR
						: null
				}
				actionText={
					loginError?.startsWith('Please check your email') ||
					sendVerifyUserError
						? 'Resend Email'
						: 'Retry'
				}
				action={
					loginError?.startsWith('Please check your email') ||
					sendVerifyUserError
						? sendVerifyUserAction(authFormEmail)
						: null
				}
			/>
			<SnackBar
				message={
					sendVerifyUserSuccess ||
					verifyUserSuccess ||
					verifyEmailUpdateSuccess ||
					userUpdateProfileSuccess === 'Profile updated' ||
					resetPasswordSuccess ||
					cancelEmailUpdateSuccess ||
					setAdminSuccess ||
					createRainbowElementSuccess ||
					updateRainbowElementSuccess ||
					deleteRainbowImageSuccess ||
					deleteRainbowElementSuccess ||
					addHistorySuccess ||
					updateEmotionRatingSuccess ||
					addEventSuccess ||
					editEventSuccess ||
					deleteEventSuccess ||
					toggleTimesSuccess ||
					sendPurchasedUsersSuccess
				}
				clearType={
					sendVerifyUserSuccess
						? SEND_VERIFY_USER_CLEAR
						: verifyUserSuccess
						? VERIFY_USER_CLEAR
						: verifyEmailUpdateSuccess
						? VERIFY_EMAIL_UPDATE_CLEAR
						: resetPasswordSuccess
						? RESET_PASSWORD_CLEAR
						: cancelEmailUpdateSuccess
						? CANCEL_EMAIL_UPDATE_CLEAR
						: setAdminSuccess
						? SET_ADMIN_CLEAR
						: createRainbowElementSuccess
						? CREATE_RAINBOW_ELEMENT_CLEAR
						: updateRainbowElementSuccess
						? UPDATE_RAINBOW_ELEMENT_CLEAR
						: deleteRainbowElementSuccess
						? DELETE_RAINBOW_ELEMENT_CLEAR
						: deleteRainbowImageSuccess
						? DELETE_RAINBOW_IMAGE_CLEAR
						: addHistorySuccess
						? ADD_HISTORY_CLEAR
						: updateEmotionRatingSuccess
						? UPDATE_EMOTION_RATING_CLEAR
						: addEventSuccess
						? ADD_EVENT_CLEAR
						: editEventSuccess
						? EDIT_EVENT_CLEAR
						: deleteEventSuccess
						? DELETE_EVENT_CLEAR
						: toggleTimesSuccess
						? TOGGLE_TIMES_CLEAR
						: purchaseUsersSuccess
						? PURCHASE_USERS_CLEAR
						: sendPurchasedUsersSuccess
						? SEND_PURCHASED_USERS_CLEAR
						: null
				}
			/>
		</>
	);
};

export default UserFeedback;
