import {
	Paper,
	Typography,
	Grid,
	useTheme,
	Button,
	IconButton
} from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import React from 'react';
import useBStyles from 'styles/bStyles';
import useCStyles from 'styles/cStyles';
import { Close } from '@material-ui/icons';

const AboutScreen = () => {
	const bClasses = useBStyles();
	const cClasses = useCStyles();
	const theme = useTheme();
	const history = useHistory();
	return (
		<Paper
			classes={{ root: cClasses.screenRoot }}
			elevation={5}
			component={Grid}
			container
			direction='column'
			alignItems='center'
			className={cClasses.termsPaper}
		>
			<IconButton className={bClasses.close} onClick={() => history.push('/')}>
				<Close />
			</IconButton>
			<Typography variant='h3' style={{ marginBottom: theme.spacing(1) }}>
				Terms and conditions
			</Typography>
			<Button
				style={{ marginBottom: theme.spacing(1) }}
				component={Link}
				to='/privacy'
			>
				View Privacy Policy
			</Button>
			<Grid>
				<Typography>
					This Application Terms and Conditions (Terms) is between Behaviour
					Help Pty Ltd (ACN 612 728 275) (referred to as Behaviour Help) and the
					entity or individual agreeing to these Terms (referred to
					as you or your), collectively referred to as the Parties and each a
					Party.
				</Typography>
				<Typography>
					These Terms governs the access and use of the Rainbow of Emotions
					software application and services (App) and any related services made
					available to you (together with the App referred to as Services). You
					can access the Services by downloading the Rainbow of Emotions on any
					mobile, tablet, console or such other devices which supports the App
					(Device).
				</Typography>
				<Typography>
					The App is available for download on the{' '}
					<a
						href='https://rainbowofemotions.app'
						target='_blank'
						rel='noreferrer noopener'
					>
						rainbowofemotions.app
					</a>{' '}
					and/or through the Behaviour Help website at 
					<a
						href='https://www.behaviourhelp.com'
						target='_blank'
						rel='noreferrer noopener'
					>
						www.behaviourhelp.com
					</a>
					.
				</Typography>
				<Typography>
					{' '}
					The Site, App and Services contain materials intended for general
					information purposes only.
				</Typography>
				<Typography>
					They do not take into account your own personal circumstances, and
					Behaviour Help does not warrant that such information is
					comprehensive, complete, accurate or up to date.{' '}
				</Typography>
				<Typography>
					They are not intended to be advice, they are not intended to be relied
					upon and they are not a substitute for professional advice based on
					your personal circumstances.{' '}
				</Typography>
				<Typography>
					Please refer to clause 15 for Behaviour Help’s complete disclaimers.
					1.
				</Typography>
				<Typography>
					ACCEPTANCE 1.1 These Terms form a binding legal agreement between you
					and Behaviour Help. By using the Services, you agree to comply with
					and be legally bound by these Terms. Please read these Terms
					carefully.
				</Typography>
				<Typography>
					If there are any questions, you should contact Behaviour Help using
					the contact details at the end of these Terms. 1.2 You acknowledge and
					agree to these Terms by: (i) ticking ‘I Agree’; (ii) downloading,
					accessing or using the App or Services; or (iii) making part or full
					payment for the Services.
				</Typography>
				<Typography>
					If you do not agree to these Terms, you should cease accessing or
					using the Website, App and Services immediately.
				</Typography>
				<Typography>
					If you are agreeing to these Terms on behalf of an entity including
					but not limited to a company, school or other organisations, you
					represent and warrant that you have the power and authority to enter
					into and bind such organisation and act on behalf of any person who
					are using the Services (you and each person using the App collectively
					referred to as Users).
				</Typography>
				<Typography>
					You must ensure any such User using or accessing the Services do so in
					accordance with these Terms. 1.3 Behaviour Help reserves the right to
					make changes to these Terms at any time, effective upon the posting of
					modified Terms.
				</Typography>
				<Typography>
					Behaviour Help will endeavour to communicate these changes to Users
					via email.
				</Typography>
				<Typography>
					{' '}
					It is your obligation to ensure that you (and each User where
					applicable) has read, understood and agree to the most recent Terms
					available on the App or Site.
				</Typography>
				<Typography>
					{' '}
					1.4 Using the App may be prohibited or restricted in certain
					countries.
				</Typography>
				<Typography>
					{' '}
					If you use the Application outside of Australia, you are responsible
					for complying with the laws and regulations of the territory from
					which you access or use the App.
				</Typography>
				<Typography>
					{' '}
					2. ADDITIONAL TERMS AND CONDITIONS 2.1 These Terms supplements and
					incorporate: (a) the Apple Inc. Terms and Conditions, including
					without limitation, the Licensed Application Under User License
					Agreement provided therein (Apple Terms); and (b) the Android, Google
					Inc. Terms and Conditions including without limitation the License
					Agreement and Terms of Application (Android Terms).
				</Typography>
				<Typography>
					{' '}
					3. APPLICATION AND SERVICES 3.1 The App is the sole and exclusive
					property of Behaviour Help.
				</Typography>
				<Typography>
					{' '}
					3.2 Users can download, access and use the App on any supported Device
					as set out on the relevant App Store and the Site.
				</Typography>
				<Typography>
					{' '}
					3.3 To access the Services, you must subscribe to the Services for a
					period of time (Subscription) and pay a monthly fee for your
					Subscription (Subscription Fee) as set out on the Site and App.
				</Typography>
				<Typography>
					{' '}
					You will not be given an Account to access and use the Services until
					payment is confirmed.
				</Typography>
				<Typography>
					{' '}
					3.4 Behaviour Help grants Users with a current Subscription a
					personal, non-exclusive, non-transferable, limited and revocable
					licence (Licence) to use the App for your own personal or
					non-commercial use on a Device owned or controlled by you or the
					applicable User, and not to use the App in any other way or for any
					other purpose unless otherwise permitted by local fair dealing
					legislation.
				</Typography>
				<Typography>
					{' '}
					All other uses are prohibited unless you or the User obtains Behaviour
					Help’s prior written consent.
				</Typography>
				<Typography>
					{' '}
					4. ONLINE SUBSCRIPTION AND ACCOUNT 4.1 Access to the App will require
					you to register for an account (Account).
				</Typography>
				<Typography>
					{' '}
					When you request a Subscription, the App will require you to provide
					personal information to create such an Account.
				</Typography>
				<Typography>
					{' '}
					Information requested includes your name, contact email address,
					password and organisation details where applicable.
				</Typography>
				<Typography>
					{' '}
					This personal information collected, used and disclosed by Behaviour
					Help, is set out in the Privacy Policy available on the Site and App.
				</Typography>
				<Typography>
					{' '}
					4.2 Information that is created when you subscribe, such as log in
					details and passwords (User Information) is stored in servers in
					Australia.
				</Typography>
				<Typography>
					Behaviour Help will take reasonable steps to ensure that User
					Information is kept secure and confidential.
				</Typography>
				<Typography>
					{' '}
					5. SECURITY Behaviour Help has implemented and will maintain security
					systems for the transmission of User Information consisting of
					encryption and "firewall" technologies that are understood in the
					industry to provide adequate security for the transmission of such
					information over the Internet.
				</Typography>
				<Typography>
					{' '}
					These measures are intended to prevent unauthorised data infiltration
					or security breaches.
				</Typography>
				<Typography>
					Behaviour Help does not guarantee the security of any data or User
					Information stored on a User’s or your Device.
				</Typography>
				<Typography>
					{' '}
					6. PAYMENT 6.1 The price of the App and payment method for the App is
					set out at the time and place of purchase, in the currency specified
					at the point of sale.
				</Typography>
				<Typography>
					{' '}
					6.2 You agree to pay to Behaviour Help the Subscription Fees upfront
					as required to enable you to access and use the App and Services.
				</Typography>
				<Typography>Subscription Fees are payable upfront.</Typography>
				<Typography>
					{' '}
					6.3 You will be required to make payment via Stripe by way credit
					card.
				</Typography>
				<Typography>
					{' '}
					The initial payment will be processed upon receipt of the request for
					a Subscription.
				</Typography>
				<Typography>
					{' '}
					You must make further payments prior to the expiry of your
					Subscription on the relevant periodic basis.
				</Typography>
				<Typography>
					{' '}
					6.4 You acknowledge and agree that if Behaviour Help is unable to take
					payment from your credit card, Behaviour Help will attempt to contact
					you via email as soon as Behaviour Help is aware of the payment
					failure.
				</Typography>
				<Typography>
					Until payment is confirmed, you will not be able to access or use the
					Services, or your access to the Services may be suspended until
					Behaviour Help receives confirmation of payment.
				</Typography>
				<Typography>
					6.5 If payment is not made within 14 days of the last payment date,
					Behaviour Help may prevent each User’s access to the Services upon
					expiry of your period of Subscription without notice to you or the
					User and you will not be able to access the Services.
				</Typography>
				<Typography>
					If payment is not made within 90 days of the last payment date, any
					information or data stored on your Account will be deleted and will
					not be recoverable.
				</Typography>
				<Typography>
					6.6 All amounts are stated in Australian dollars and unless otherwise
					stated, all amounts are exclusive of GST.
				</Typography>
				<Typography>
					6.7 If you make a payment by credit card, you warrant that the
					information you provide to us is true and complete, that you are
					authorised to use the credit card to make the payment, that your
					payment will be honoured by your card issuer, and that you will
					maintain sufficient funds in your account to cover the purchase price.
					6.8 Behaviour Help’s pricing structure or payment methods may be
					amended from time to time at its sole discretion.
				</Typography>
				<Typography>
					7. CANCELLATION OF REGISTRATION 7.1 If you would like to cancel your
					Subscription, you may do so by contacting Behaviour Help using the
					contact details below.
				</Typography>
				<Typography>
					The User’s access to your Account and the Services will terminate at
					the end of the month that Behaviour Help is notified of your intention
					to terminate.
				</Typography>
				<Typography>
					7.2 It is the User’s responsibility to retrieve all necessary data
					from their Account prior to termination.
				</Typography>
				<Typography>
					7.3 Behaviour Help may terminate the Terms immediately, in its sole
					discretion, if: (a) you breach any of these Terms; (b) Behaviour Help
					reasonably suspect that you are attempting to reverse engineer the App
					that is provided to you; (c) where there is an Insolvency Event; or
					(d) for any other reason outside of Behaviour Help’s control which has
					the effect of compromising its ability to provide you with the
					required App or Services within a required timeframe.
				</Typography>
				<Typography>
					7.4 On termination or completion of the Services, Behaviour Help may
					retain your User Information, data and documents (including copies) as
					required by law or regularity requirements.
				</Typography>
				<Typography>
					Your express or implied agreement to the Terms constitutes your
					authority for Behaviour Help to retain or destroy documents in
					accordance with any applicable statutory periods.
				</Typography>
				<Typography>
					8. USER OBLIGATONS 8.1 You warrant that all information provided to
					Behaviour Help is true, accurate and complete.
				</Typography>
				<Typography>
					8.2 You acknowledge and agree that: (a) each User uses the Services at
					their own risk; (b) if there is any unauthorised use of your passwords
					or any other breach of security, you will immediately notify Behaviour
					Help of such activity; (c) the accessibility and reliability of the
					App and Services is dependent on the User’s choice of web-browser,
					internet connection and Device operating system; (d) it is your
					responsibility to determine that the Services meet your needs and are
					suitable for the purposes for which the Services are used; and (e) you
					are responsible for obtaining any consents, licences, permits and
					permissions from other parties as required for the Services to be
					provided, at your cost, and for providing Behaviour Help with the
					necessary consents, licences and permissions.
				</Typography>
				<Typography>
					8.3 You acknowledge and agree that each User: (a) is authorised to use
					the Services and to access any User Information or other data that
					they input (Data) into the App or provide to Behaviour Help as
					required for the Services; (b) will use the Services for its own
					lawful internal business purposes, in accordance with these Terms; and
					(c) will keep all usernames and passwords required to access the
					Services and their Account secure and confidential.
				</Typography>
				<Typography>
					8.4 Behaviour Help has no responsibility to any person or entity other
					than you and nothing in these Terms confers, or purports to confer, a
					benefit on any person or entity other than you.
				</Typography>
				<Typography>
					If you use the Services on behalf of or for the benefit of any third
					party, you agree that: (a) you are responsible for ensuring that you
					have the right to do so; (b) Behaviour Help does not warrant the
					fitness for purpose or suitability of the App and Services for such
					third party’s purposes and third parties may not rely on Behaviour
					Help for any purpose; (c) you are responsible for authorising any
					person who is given access to your User Information and any of your
					Data, and you agree that Behaviour Help has no obligation to provide
					any person or entity with access to such Data without authorisation
					from you and may refer any requests for access to the Data to you to
					address; and (d) you will indemnify Behaviour Help, on first demand,
					against any and all claims, expenses, liabilities or losses arising
					out of in connection with Behaviour Help’s refusal to provide any
					persons with access to your Data in accordance with these Terms and
					Behaviour Help making Data available to any person with authorisation
					from you.
				</Typography>
				<Typography>
					8.5 You remain solely responsible for complying with all applicable
					laws.
				</Typography>
				<Typography>
					It is your responsibility to ensure that the storage of and access to
					your Data via the Site and the App comply with laws which are
					applicable to you, including any laws requiring you to retain records
					of your Data.
				</Typography>
				<Typography>
					8.6 Each User must have their own safeguards and back up processes in
					place to recover from any failures or loss of Data which might occur
					whilst using the Services and protecting the confidentiality of your
					Data with suitable management procedures, as you may see fit.
				</Typography>
				<Typography>
					9. PROHIBITED USE 9.1 You acknowledge and agree that you will not: (a)
					attempt to circumvent or disable the App or any technology features or
					measures in the App by any means or in any manner; (b) attempt to
					modify, copy, adapt or reproduce the App except as necessary to use it
					for normal operation; (c) attempt to decompile, disassemble, reverse
					engineer, or otherwise attempt to derive the source code for the App;
					(d) distribute, encumber, sell, rent, lease, sub-license, or otherwise
					transfer, publish or disclose the Services to any third party (except
					as permitted under these Terms); (e) remove or alter any trademark,
					logo, copyright or other proprietary notices, legends, symbols or
					labels in or on the App or used in connection with the App or
					Services; (f) use the App or Services in any manner to aid in the
					violation of any third party Intellectual Property, including but not
					limited to another’s copyrights, trade secrets, and patents; (g) take
					any action that interferes, in any manner, with Behaviour Help’s
					rights with respect to the App and Services; (h) attempt to undermine
					the security or integrity of Behaviour Help’s computing systems or
					where the App is hosted by a third party, that third party’s computing
					systems and networks; (i) use, or misuse, the App in any way which may
					impair the functionality of the Site, App, or other systems used to
					deliver the App or impair the ability of any other user to use the
					Site or App; and (j) transmit, or input into the Site or App, any
					files that may damage any other person's computing devices or
					software, content that may be offensive, or material or Data in
					violation of any law (including any content protected by copyright or
					trade secrets which you do not have the right to use).
				</Typography>
				<Typography>
					9.2 Title, ownership rights and Intellectual Property rights in and to
					any content displayed on the Site, the App or accessed through the
					Site, the App or the Services, are the property of the applicable
					content owner and may be protected by applicable copyright or other
					law.
				</Typography>
				<Typography>
					These Terms gives you no rights to such content.
				</Typography>
				<Typography>
					9.3 You acknowledge that any breaches of this clause may lead to
					termination of these Terms.
				</Typography>
				<Typography>
					10. PRIVACY POLICY 10.1 Behaviour Help agrees to comply with the legal
					requirements of the Australian Privacy Principles as set out in
					the Privacy Act 1988(Cth) and any other applicable legislation or
					privacy guidelines, as set out in Behaviour Help’s privacy policy
					which is available on the App and Site.
				</Typography>
				<Typography>
					10.2 You will be taken to have read the terms of the Privacy Policy
					when you accept these Terms.
				</Typography>
				<Typography>
					11. INTELLECTUAL PROPERTY 11.1 Title to, and all Intellectual Property
					rights in the Site, and the App, and any documentation relating to the
					Services, remain the property of Behaviour Help and its successors and
					permitted assigns.
				</Typography>
				<Typography>
					Your right to use such Intellectual Property is subject to these
					Terms.
				</Typography>
				<Typography>
					11.2 You grant Behaviour Help a non-exclusive, worldwide licence to
					use any Intellectual Property which subsists in the Data you provide
					in connection with the use of your Account and the provision of the
					Services, including copyright in any third party logos or other
					materials.
				</Typography>
				<Typography>
					11.3 Title to and all Intellectual Property rights in any Data you
					input into the App remain your property.
				</Typography>
				<Typography>
					However, your access to the Data and continued use of the App and
					Services is contingent on payment of your Subscription Fee.
				</Typography>
				<Typography>
					11.4 You grant Behaviour Help a licence to use, copy, transmit, store,
					and back-up your information and data for the purposes of enabling you
					to access and use the App and for any other purpose related to
					provision of Services to you and the performance of Behaviour Help’s
					obligations under these Terms.
				</Typography>
				<Typography>
					11.5 It is the responsibility of the User to maintain copies of any
					data which is inputted into the App.
				</Typography>
				<Typography>
					Behaviour Help will endeavour to prevent data loss, however, as data
					submitted via the App is hosted by third parties, Behaviour Help does
					not make any guarantees that there will be no loss of data and does
					not represent or warrant that access to the App, Services the Data or
					an Account will be available without interruption. 11.6 If the User
					enables third-party applications for use in conjunction with the App,
					the User acknowledges that Behaviour Help may allow the providers of
					those third-party applications to access any inputted data as required
					for the interoperation of such third-party applications with the App.
				</Typography>
				<Typography>
					Behaviour Help is not responsible for any disclosure, modification or
					deletion of data resulting from any such access by third-party
					application providers.
				</Typography>
				<Typography>
					12. SUPPORT 12.1 Behaviour Help may, from time to time, issue updated
					versions of the App and the App may automatically connect to Behaviour
					Help’s or third-party servers via the internet to check for available
					updates to the App, and may either (i) automatically electronically
					update the version of the App that you are using on your Device or
					(ii) give you the option of downloading it.
				</Typography>
				<Typography>
					By installing the App you hereby agree to automatically request and
					receive updates from Behaviour Help or third-party servers.
				</Typography>
				<Typography>
					You acknowledge and agree that these Terms will apply to all such
					updates unless separate terms are provided with the update and by
					downloading the update those supplementary terms will apply.
				</Typography>
				<Typography>
					12.2 The App may contain automatic communications features which relay
					certain non-personally identifiable information to Behaviour Help in
					connection with the operation of the App.
				</Typography>
				<Typography>
					This information may include your App settings and what version of the
					App you are using.
				</Typography>
				<Typography>
					Behaviour Help may use this information for research purposes
					including statistical analysis of aggregate customer behaviour.
				</Typography>
				<Typography>
					12.3 Behaviour Help may provide support to the User.
				</Typography>
				<Typography>
					If you require technical support, please contact Behaviour Help using
					the details at the bottom of these Terms.
				</Typography>
				<Typography>
					13. AVAILABILITY OF SITE AND APP 13.1 Whilst Behaviour Help intends
					that access to the App and Services should be available on a full-time
					basis, it is possible that the Site, App or Services is unavailable to
					due to maintenance or other development activity.
				</Typography>
				<Typography>
					13.2 Where possible, Behaviour Help will provide notice to its Users
					of any maintenance or development activity in advance by providing
					Users notice via email.
				</Typography>
				<Typography>
					14. FEEDBACK AND DISPUTE RESOLUTION 14.1 Your feedback is important to
					Behaviour Help.
				</Typography>
				<Typography>
					Behaviour Help seeks to resolve your concerns quickly and effectively.
				</Typography>
				<Typography>
					If you have any feedback or questions about the App or Services,
					please contact Behaviour Help using the contact details at the bottom
					of these Terms. 14.2 If there is a dispute between the Parties in
					relation to these Terms, the Parties agree to the following dispute
					resolution procedure: (a.i) The complainant must tell the respondent
					in writing, the nature of the dispute, what outcome the complainant
					wants and what action the complainant thinks will settle the dispute.
				</Typography>
				<Typography>
					The Parties agree to meet in good faith to seek to resolve the dispute
					by agreement between them (Initial Meeting).
				</Typography>
				<Typography>
					(a.ii) If the Parties cannot agree how to resolve the dispute at the
					Initial Meeting, any Party may refer the matter to a mediator.
				</Typography>
				<Typography>
					If the parties cannot agree on who the mediator should be, the
					complainant will ask the Law Society of [State] to appoint a mediator.
					The mediator will decide the time and place for mediation.
				</Typography>
				<Typography>
					The Parties must attend the mediation in good faith, to seek to
					resolve the dispute. 14.3 Any attempts made by the Parties to resolve
					a dispute pursuant to this clause are without prejudice to other
					rights or entitlements of the Parties under these Terms, by law or in
					equity.
				</Typography>
				<Typography>
					15. LIMITATION OF LIABILITY AND DISCLAIMERS 15.1 The Parties agree
					that to the fullest extent applicable, Behaviour Help’s liability for
					the Services provided via the Site and the App is governed by the
					Australian Consumer Law to the extent applicable.
				</Typography>
				<Typography>
					15.2 Behaviour Help’s liability is covered by the relevant insurance
					policy.
				</Typography>
				<Typography>
					Behaviour Help will take steps to maintain such policies after the
					completion of the Services and termination of these Terms, for period
					as Behaviour Help may be required under law. 15.3 You acknowledge that
					whilst Behaviour Help will take reasonable steps to ensure that the
					Services will be fit for the purposes as advertised, Behaviour Help
					gives no guarantees that: (a) the App will meet your requirements as
					the functionality of the App is dependent upon configuration on your
					Device and other components; (b) the App will work in each of your
					desired use case scenarios; and (c) the App can be executed on every
					operating system, as it is impossible to test each variant.
				</Typography>
				<Typography>
					15.4 The Services uses third party hosting services which are provided
					without any sort of warranties, and Behaviour Help cannot ensure that
					these third party hosting services are provided free of defect or
					without interruption.
				</Typography>
				<Typography>
					15.5 Behaviour Help does not warrant that use of the Services will be
					uninterrupted or error free.
				</Typography>
				<Typography>
					The operation of the App is dependent on public telephone services,
					computer networks, the Internet, which can be unpredictable and may
					from time to time interfere with the use of the Services.
				</Typography>
				<Typography>
					Behaviour Help accepts no responsibility for any such interference or
					prevention of your use of the Services.
				</Typography>
				<Typography>
					15.6 Behaviour Help does not provide any medical, psychological,
					health or related advice.
				</Typography>
				<Typography>
					You agree and will procure the Users (as applicable) to agree that any
					information, insights or guidance contained on the Site, App or
					otherwise provided as part of the Services are not an attempt to
					practise medicine, psychology or health science or provide any
					medical, psychological or health advice.
				</Typography>
				<Typography>
					The Services must not be used or relied on for any diagnostic or
					treatment purpose and should not be used as a substitute for
					professional diagnosis and treatment.
				</Typography>
				<Typography>
					Any information that is contained in any of our materials, whether
					provided by Behaviour Help via the Site, App or through the provision
					of Services or by contract from any third parties, is simply for your
					convenience.
				</Typography>
				<Typography>
					The Information contained in our Site, App and Services is general
					information only and not intended to be relied upon and is not a
					substitute for professional medical, psychological or health advice
					based on personal circumstances.
				</Typography>
				<Typography>
					15.7 You are solely responsible for determining the suitability of any
					Services and you rely on any information provided to you through the
					Site, App and Services at your own risk.
				</Typography>
				<Typography>
					15.8 All risk arising out of the use or performance of the Services
					remains with you.
				</Typography>
				<Typography>
					You understand and agree that the use of the Services, material or
					data downloaded or otherwise obtained through the use of the Services,
					is at your own discretion and risk and that you will be solely
					responsible for any infections, contaminations or damage to your
					computer, system or network.
				</Typography>
				<Typography>
					Behaviour Help is not responsible or liable for delays, inaccuracies,
					errors or omissions arising out of your use of the Services, any third
					party software or operating system.
				</Typography>
				<Typography>
					15.9 To the maximum extent permitted by applicable law, Behaviour Help
					and its licensors disclaim all warranties, express or implied,
					including, without limitation, any implied warranties of
					merchantability, fitness for a particular purpose and non-infringement
					of third party intellectual property rights or lack of viruses, for
					the App and Services.
				</Typography>
				<Typography>
					In no event will Behaviour Help or its licensors be liable for any
					consequential, incidental, indirect, special, punitive, or other
					damages whatsoever arising out of these Terms, the use of or inability
					to use the Services, even if Behaviour Help has been advised of the
					possibility of such damages.
				</Typography>
				<Typography>
					15.10 The User acknowledges that Behaviour Help may pursue any
					available equitable or other remedy against you as a result of a
					breach by the User of any provision of these Terms.
				</Typography>
				<Typography>
					15.11 Behaviour Help or its licensors’ liability for breach of any of
					its obligations under these Terms for the Services, or breach of any
					warranty implied by law, will be limited, to the extent permitted by
					law, to the total price paid for the Subscription to execute the App
					and use the Services.
				</Typography>
				<Typography>
					Behaviour Help’s total liability to you for all damages in connection
					with the Services will not exceed the price paid by you under these
					Terms for the Services.
				</Typography>
				<Typography>
					The foregoing limitations, exclusions and disclaimers will apply to
					the maximum extent permitted by applicable law, even if any remedy
					fails its essential purpose.
				</Typography>
				<Typography>
					15.12 The User acknowledges and agrees that Behaviour Help will not be
					liable for any non-compensatory damages including punitive,
					aggravated, multiple, exemplary, liquated or any other
					non-compensatory damages or the consequences of non-payment. 16.
					AUSTRALIAN CONSUMER LAW 16.1 Certain legislation including the
					Australian Consumer Law (ACL) in the Consumer and Competition Act 2010
					(Cth), and similar consumer protection laws and regulations may confer
					you with rights, warranties, guarantees and remedies relating to the
					provision of Services by us to you which cannot be excluded,
					restricted or modified (Statutory Rights).
				</Typography>
				<Typography>
					16.2 Nothing in these Terms removes your Statutory Rights as a
					consumer under the ACL.
				</Typography>
				<Typography>
					You agree that Behaviour Help’s liability for Services provided to
					consumers is governed solely by the ACL and these Terms.
				</Typography>
				<Typography>
					Behaviour Help exclude all conditions and warranties implied by
					custom, law or statute except for your Statutory Rights.
				</Typography>
				<Typography>
					16.3 Except for your Statutory Rights, all material and work is
					provided to you without warranties of any kind, either express or
					implied; and Behaviour Help expressly disclaim all warranties of any
					kind including but not limited to implied warranties of
					merchantability and fitness for a particular purpose.
				</Typography>
				<Typography>
					16.4 If you are a consumer as defined in the ACL, the following
					applies to you: We guarantee that the services we supply to you are
					rendered with due care and skill; fit for the purpose that we
					advertise, or that you have told us you are acquiring the services for
					or for a result which you have told us you wish the services achieve,
					unless we consider and disclose that this purpose is not achievable;
					and will be supplied within a reasonable time.
				</Typography>
				<Typography>
					To the extent we are unable to exclude liability; our total liability
					for loss or damage you suffer or incur from our services is limited to
					us re-supplying the services to you, or, at our option, us refunding
					to you the amount you have paid us for the services to which your
					claim relates.
				</Typography>
				<Typography>
					17. INDEMNITY 17.1 You will be liable for and agree to indemnify,
					defend and hold us harmless for and against any and all claims,
					liabilities, suits, actions and expenses, including costs of
					litigation and reasonable legal costs, resulting directly or
					indirectly from: (i) any information that is not accurate, up to date
					or complete or is misleading or a misrepresentation; (ii) any breach
					of these Terms; (iii) and any misuse of the Services and/or Licence;
					from or by you, your employees, contractors or agents.
				</Typography>
				<Typography>
					17.2 You agree to co-operate with us (at your own expense) in the
					handling of disputes, complaints, investigations or litigation that
					arise as a result of your use of the Services and Licence including
					but not limited to disputes, complaints, investigations or litigation
					that arises out of or relates to incorrect information you have given
					us.  17.3 The obligations under this clause will survive termination
					of these Terms.
				</Typography>
				<Typography>
					18. NOTICE 18.1 Any notice required or permitted to be given to the
					User under these Terms will be addressed to the User at the email
					address provided by the User when requesting a Licence.
				</Typography>
				<Typography>
					19. RELATIONSHIP OF PARTIES 19.1 Neither Party is authorised to bind
					the other Party in any way without prior written consent of the other
					Party.
				</Typography>
				<Typography>
					19.2 The Parties acknowledge and agree that they will not seek to bind
					the other Party other than with the prior written consent of the other
					Party.
				</Typography>
				<Typography>
					20. RIGHTS OF THIRD PARTIES 20.1 Any person or entity who is not a
					party to these Terms has no right to benefit under or to enforce any
					of these Terms.
				</Typography>
				<Typography>
					21. ASSIGNMENT 21.1 These Terms are personal to you.
				</Typography>
				<Typography>
					You must not assign or deal with the whole or any part of its rights
					and/or obligations under these Terms without the prior written consent
					of Behaviour Help.
				</Typography>
				<Typography>
					21.2 Any purported dealing in breach of this clause is of no effect.
				</Typography>
				<Typography>
					22. POWERS, RIGHTS AND REMEDIES 22.1 Except as expressly stated to the
					contrary in these Terms, the powers, rights and/or remedies of a Party
					under these Terms are cumulative and are in addition to any other
					powers, rights and remedies of that Party.
				</Typography>
				<Typography>
					Nothing in these Terms merges, extinguishes, postpones, lessens or
					otherwise prejudicially affects any power, right, or remedy that a
					Party may have at any time against the other Party to these Terms or
					any other person.
				</Typography>
				<Typography>
					23. Force Majeure 23.1 If performance of the Services or any
					obligation under these Terms is prevented, restricted, or interfered
					with by causes beyond either party’s reasonable control (Force
					Majeure), and if the party unable to carry out its obligations gives
					the other party prompt written notice of such event, then the
					obligations of the party invoking this provision shall be suspended to
					the extent necessary by such event.  The term Force Majeure shall
					include, without limitation, acts of God, fire, explosion, vandalism,
					storm or other similar occurrence, orders of acts of military or civil
					authority, or by national emergencies, insurrections, riots, or wars,
					or strikes, lock-outs, work stoppages.
				</Typography>
				<Typography>
					The excused party shall use reasonable efforts under the circumstances
					to avoid or remove such causes of non-performance and shall proceed to
					perform with reasonable dispatch whenever such causes are removed or
					ceased.  An act or omission shall be deemed within the reasonable
					control of a party if committed, omitted, or caused by such party, or
					its employees, officers, agents, or affiliates.
				</Typography>
				<Typography>
					24. FURTHER ASSURANCE 24.1 Each Party must from time to time and in a
					timely manner do all things reasonably required of it by another Party
					to give effect to these Terms.
				</Typography>
				<Typography>
					25. ENFORCEABILITY 25.1 If any provision of these Terms is held by any
					competent authority to be invalid or unenforceable in whole or in
					part, the validity of the other provisions of these Terms and the
					remainder of the provisions in question will not be affected.
				</Typography>
				<Typography>
					26. ENTIRE AGREEMENT AND UNDERSTANDING 26.1 In respect of the subject
					matter of the Terms, the Terms contain the entire understanding
					between the Parties.
				</Typography>
				<Typography>
					Any previous oral and written communications, representations,
					warranties or commitments are superseded by the Terms and do not
					affect the interpretation or meaning of the Terms and each of the
					Parties has relied entirely on its own enquiries before entering into
					the Terms.
				</Typography>
				<Typography>
					27. GOVERNING LAW AND JURISDICTION 27.1 These Terms are governed by
					the laws of Western Australia and the Commonwealth of Australia.
				</Typography>
				<Typography>
					Each Party irrevocably and unconditionally submits to the exclusive
					jurisdiction of the courts operating in Western Australia.
				</Typography>
				<Typography>
					28. DEFINITIONS Insolvency Event means the occurrence of any of the
					following events in which a Party: (a) is unable to pay its debt as
					they fall due; (b) makes or commences negotiation with a view to
					making, a general rescheduling of its indebtedness, a general
					assignment, scheme or arrangement or composition with its creditors;
					(c) Takes any corporate action or any steps are taken or legal
					proceedings are started for: i. Its winding-up, dissolution,
					liquidation or re-organisation, other than to reconstruct or
					amalgamate while solvent on terms approved by the other Party (which
					approval will not be unreasonably withheld); or ii. The appointment of
					a controller, receiver, administrator, office manager, trustee or
					similar officer of it or of any of its revenues and assets; or (d)
					seeks protection or is granted protection from its creditors, under
					any applicable legislation.
				</Typography>
				<Typography>
					Intellectual Property includes any and all intellectual and industrial
					property rights throughout the world, whether subsisting now or in the
					future and includes all copyright and analogous rights, all rights in
					relation to inventions (including patent rights), registered and
					unregistered trademarks, designs (whether or not registered or
					registrable), circuit layouts, trade names, trade secrets, business
					names, company names or internet domain names.
				</Typography>
				<Typography>
					For any questions or notice, please contact us at: Behaviour Help Pty
					Ltd (ACN 612 728 275) PO Box 5078 Cheltenham VIC 3192 Email:
					<a className={cClasses.link} href='mailto: dolly@behaviourhelp.com'>
						dolly@behaviourhelp.com
					</a>
				</Typography>
			</Grid>
		</Paper>
	);
};

export default AboutScreen;
