import {
	GET_EMOTIONS_REQUEST,
	GET_EMOTIONS_SUCCESS,
	GET_EMOTIONS_FAIL,
	GET_EMOTIONS_CLEAR,
	GET_TOOLKIT_REQUEST,
	GET_TOOLKIT_SUCCESS,
	GET_TOOLKIT_FAIL,
	GET_TOOLKIT_CLEAR,
	CLICK_EMOTION,
	TOGGLE_SETTINGS_MESSAGE,
	CLOSE_EMOTION,
	OPEN_TOOLKIT,
	CLOSE_TOOLKIT,
	SET_ACTIVE_TOOL,
	OPEN_SUMMARY,
	CLOSE_SUMMARY,
	SET_DEGREE,
	SET_PHYSICAL_EMOTIONS,
	SET_NEGATIVE_EMOTIONS,
	UPLOAD_RAINBOW_IMAGE_REQUEST,
	UPLOAD_RAINBOW_IMAGE_SUCCESS,
	UPLOAD_RAINBOW_IMAGE_FAIL,
	UPLOAD_RAINBOW_IMAGE_CLEAR,
	UPLOAD_AUDIO_REQUEST,
	UPLOAD_AUDIO_SUCCESS,
	UPLOAD_AUDIO_FAIL,
	UPLOAD_AUDIO_CLEAR,
	DELETE_AUDIO_REQUEST,
	DELETE_AUDIO_SUCCESS,
	DELETE_AUDIO_FAIL,
	DELETE_AUDIO_CLEAR,
	DELETE_RAINBOW_IMAGE_REQUEST,
	DELETE_RAINBOW_IMAGE_SUCCESS,
	DELETE_RAINBOW_IMAGE_FAIL,
	DELETE_RAINBOW_IMAGE_CLEAR,
	CREATE_RAINBOW_ELEMENT_REQUEST,
	CREATE_RAINBOW_ELEMENT_SUCCESS,
	CREATE_RAINBOW_ELEMENT_FAIL,
	CREATE_RAINBOW_ELEMENT_CLEAR,
	CREATE_RAINBOW_ELEMENT_CLEAR_REDIRECT,
	UPDATE_RAINBOW_ELEMENT_REQUEST,
	UPDATE_RAINBOW_ELEMENT_SUCCESS,
	UPDATE_RAINBOW_ELEMENT_FAIL,
	UPDATE_RAINBOW_ELEMENT_CLEAR,
	UPDATE_RAINBOW_ELEMENT_CLEAR_REDIRECT,
	DELETE_RAINBOW_ELEMENT_REQUEST,
	DELETE_RAINBOW_ELEMENT_SUCCESS,
	DELETE_RAINBOW_ELEMENT_FAIL,
	DELETE_RAINBOW_ELEMENT_CLEAR,
	DELETE_RAINBOW_ELEMENT_CLEAR_REDIRECT,
	UPDATE_EMOTION_RATING_REQUEST,
	UPDATE_EMOTION_RATING_SUCCESS,
	UPDATE_EMOTION_RATING_FAIL,
	UPDATE_EMOTION_RATING_CLEAR,
	ADD_HISTORY_REQUEST,
	ADD_HISTORY_SUCCESS,
	ADD_HISTORY_FAIL,
	ADD_HISTORY_CLEAR,
	ADD_EVENT_REQUEST,
	ADD_EVENT_SUCCESS,
	ADD_EVENT_FAIL,
	ADD_EVENT_CLEAR,
	EDIT_EVENT_REQUEST,
	EDIT_EVENT_SUCCESS,
	EDIT_EVENT_FAIL,
	EDIT_EVENT_CLEAR,
	DELETE_EVENT_REQUEST,
	DELETE_EVENT_SUCCESS,
	DELETE_EVENT_FAIL,
	DELETE_EVENT_CLEAR,
	TOGGLE_TIMES_REQUEST,
	TOGGLE_TIMES_SUCCESS,
	TOGGLE_TIMES_FAIL,
	TOGGLE_TIMES_CLEAR,
	SET_OUTCOME,
	SET_ACTIVE_TOOL_CATEGORY
} from 'constants/rainbowConstants';
import { produce } from 'immer';

export const getEmotionsReducer = (
	state = {
		physicalEmotions: [
			{
				color: '#ffffff',
				title: 'Happy',
				image: '/images/emotions/happy.png',
				audio: '',
				isHappy: true,
				_id: 0
			},
			{
				color: '#ff0000',
				title: 'Hot',
				image: '/images/emotions/hot.png',
				audio: '',
				_id: 1
			},
			{
				color: '#ffa500',
				title: 'Hungry',
				image: '/images/emotions/hungry.png',
				audio: '',
				_id: 2
			},
			{
				color: '#ffff00',
				title: 'Hurt',
				image: '/images/emotions/hurt.png',
				audio: '',
				_id: 3
			},
			{
				color: '#008000',
				title: 'Tired',
				image: '/images/emotions/tired.png',
				audio: '',
				_id: 4
			},
			{
				color: '#0000ff',
				title: 'Uncomfortable',
				image: '/images/emotions/uncomfortable.png',
				audio: '',
				_id: 5
			},
			{
				color: '#800080',
				title: 'Sore',
				image: '/images/emotions/sore.png',
				audio: '',
				_id: 6
			},
			{
				color: '#ee82ee',
				title: 'Thirsty',
				image: '/images/emotions/thirsty.png',
				audio: '',
				_id: 7
			}
		],
		negativeEmotions: [
			{
				color: '#ffffff',
				title: 'Happy',
				image: '/images/emotions/happy.png',
				audio: '',
				isHappy: true,
				_id: 15
			},
			{
				color: '#ff0000',
				title: 'Bored',
				image: '/images/emotions/bored.png',
				audio: '',
				_id: 8
			},
			{
				color: '#ffa500',
				title: 'Afraid',
				image: '/images/emotions/afraid.png',
				audio: '',
				_id: 9
			},
			{
				color: '#ffff00',
				title: 'Angry',
				image: '/images/emotions/angry.png',
				audio: '',
				_id: 10
			},
			{
				color: '#008000',
				title: 'Anxious',
				image: '/images/emotions/anxious.png',
				audio: '',
				_id: 11
			},
			{
				color: '#0000ff',
				title: 'Embarrassed',
				image: '/images/emotions/embarrassed.png',
				audio: '',
				_id: 12
			},
			{
				color: '#800080',
				title: 'Insecure',
				image: '/images/emotions/insecure.png',
				audio: '',
				_id: 13
			},
			{
				color: '#ee82ee',
				title: 'Worried',
				image: '/images/emotions/worried.png',
				audio: '',
				_id: 14
			}
		]
	},
	action
) =>
	produce(state, draft => {
		switch (action.type) {
			case GET_EMOTIONS_REQUEST:
				draft.loading = true;
				break;
			case GET_EMOTIONS_SUCCESS:
				draft.loading = false;
				draft.negativeEmotions = action.negativeEmotions;
				draft.physicalEmotions = action.physicalEmotions;
				break;
			case GET_EMOTIONS_FAIL:
				draft.loading = false;
				draft.error = action.payload;
				break;
			case GET_EMOTIONS_CLEAR:
				draft.error = null;
				draft.success = null;
				break;
			default:
				return state;
		}
	});
export const getToolkitReducer = (
	state = {
		chat: [
			{
				title: 'Talk to brother',
				image: '/images/tools/chat/brother.png',
				audio: '',
				_id: 15
			},
			{
				title: 'Talk to friend',
				image: '/images/tools/chat/boy.png',
				audio: '',
				_id: 16
			},
			{
				title: 'Talk to dad',
				image: '/images/tools/chat/dad.png',
				audio: '',
				_id: 17
			},
			{
				title: 'Talk to mum',
				image: '/images/tools/chat/mother.png',
				audio: '',
				_id: 18
			}
		],
		helpfulThinking: [
			{
				title: 'Think positive thoughts',
				image: '/images/tools/helpfulThinking/helpfulThinking.png',
				audio: '',
				_id: 19
			}
		],
		amusement: [
			{
				title: 'Play cards',
				image: '/images/tools/amusement/cards.png',
				audio: '',
				_id: 20
			},
			{
				title: 'Make art',
				image: '/images/tools/amusement/art.png',
				audio: '',
				_id: 21
			},
			{
				title: 'Play chess',
				image: '/images/tools/amusement/chess1.png',
				audio: '',
				_id: 22
			},
			{
				title: 'Go fishing',
				image: '/images/tools/amusement/fishing.png',
				audio: '',
				_id: 23
			}
		],
		relaxation: [
			{
				title: 'Take a break',
				image: '/images/tools/relaxation/break-time.png',
				audio: '',
				_id: 24
			},
			{
				title: 'Take a nap',
				image: '/images/tools/relaxation/brief-nap.png',
				audio: '',
				_id: 25
			},
			{
				title: 'Count to ten',
				image: '/images/tools/relaxation/count-1-10.png',
				audio: '',
				_id: 26
			},
			{
				title: 'Enjoy a massage',
				image: '/images/tools/relaxation/massage1.png',
				audio: '',
				_id: 27
			}
		],
		goodRoutine: [
			{
				title: 'Breakfast',
				image: '/images/tools/goodRoutine/breakfast.png',
				audio: '',
				_id: 28
			},
			{
				title: 'Dinner',
				image: '/images/tools/goodRoutine/dinner.png',
				audio: '',
				_id: 29
			},
			{
				title: 'Go to bed',
				image: '/images/tools/goodRoutine/go-to-bed.png',
				audio: '',
				_id: 30
			},
			{
				title: 'Lunch',
				image: '/images/tools/goodRoutine/lunch.png',
				audio: '',
				_id: 31
			},
			{
				title: 'Take a shower',
				image: '/images/tools/goodRoutine/shower.png',
				audio: '',
				_id: 32
			}
		],
		exercise: [
			{
				title: 'Aerobics',
				image: '/images/tools/exercise/aerobics.png',
				audio: '',
				_id: 33
			},
			{
				title: 'Dance',
				image: '/images/tools/exercise/dancing.png',
				audio: '',
				_id: 34
			},
			{
				title: 'Football',
				image: '/images/tools/exercise/football.png',
				audio: '',
				_id: 35
			},
			{
				title: 'Gym',
				image: '/images/tools/exercise/gym.png',
				audio: '',
				_id: 36
			},
			{
				title: 'Skating',
				image: '/images/tools/exercise/skating.png',
				audio: '',
				_id: 37
			}
		]
	},
	action
) => {
	switch (action.type) {
		case GET_TOOLKIT_REQUEST:
			return { ...state, loading: true };
		case GET_TOOLKIT_SUCCESS:
			return {
				...state,
				loading: false,
				toolkit: action.payload
			};
		case GET_TOOLKIT_FAIL:
			return {
				...state,
				loading: false,
				error: action.payload
			};
		case GET_TOOLKIT_CLEAR:
			return {
				...state,
				error: null,
				success: null
			};
		default:
			return state;
	}
};

export const rainbowStateReducer = (
	state = {
		activeEmotion: null,
		prevEmotion: null,
		activeToolCategory: null,
		activeTool: null,
		toolkitIsOpen: false,
		degree: null,
		degreeRange: 3,
		summary: null,
		emotionType: 'negative',
		outcome: null
	},
	action
) =>
	produce(state, draft => {
		switch (action.type) {
			case CLICK_EMOTION:
				// if an emotion is open and click occured on a closed emotion
				if (
					state.activeEmotion &&
					state.activeEmotion._id !== action.emotion._id
				) {
					// set prevEmotion to emotion
					draft.prevEmotion = state.activeEmotion;
					// clear emotion
					draft.activeEmotion = null;
					draft.toolkitIsOpen = false;
				} else if (!state.activeEmotion) {
					draft.activeEmotion = action.emotion;
				}
				break;
			case TOGGLE_SETTINGS_MESSAGE:
				draft.settingsMessageIsOpen = !state.settingsMessageIsOpen;
				break;
			case CLOSE_EMOTION:
				if (state.activeEmotion) {
					draft.prevEmotion = state.activeEmotion;
					draft.activeEmotion = null;
				}
				break;
			case OPEN_TOOLKIT:
				draft.toolkitIsOpen = true;
				break;
			case CLOSE_TOOLKIT:
				draft.toolkitIsOpen = false;
				draft.activeTool = null;
				break;
			case SET_ACTIVE_TOOL:
				draft.activeTool = action.tool;
				break;
			case SET_ACTIVE_TOOL_CATEGORY:
				draft.activeToolCategory = action.toolCategory;
				break;
			case SET_OUTCOME:
				draft.outcome = action.payload;
				break;
			case OPEN_SUMMARY:
				draft.summary = {
					emotion: state.activeEmotion,
					emotionType: state.emotionType,
					degree: state.degree,
					degreeRange: state.degreeRange,
					tool: state.activeTool,
					toolCategory: state.activeToolCategory,
					outcome: state.outcome
				};
				break;
			case CLOSE_SUMMARY:
				draft.summary = null;
				break;
			case SET_DEGREE:
				draft.degree = action.degree;
				break;
			case SET_NEGATIVE_EMOTIONS:
				draft.emotionType = 'negative';
				break;
			case SET_PHYSICAL_EMOTIONS:
				draft.emotionType = 'physical';
				break;
			default:
				return state;
		}
	});

export const uploadRainbowImageReducer = (state = {}, action) => {
	switch (action.type) {
		case UPLOAD_RAINBOW_IMAGE_REQUEST:
			return { loading: true };
		case UPLOAD_RAINBOW_IMAGE_SUCCESS:
			return {
				loading: false,
				success: action.payload,
				image: action.image
			};
		case UPLOAD_RAINBOW_IMAGE_FAIL:
			return { loading: false, error: action.payload };
		case UPLOAD_RAINBOW_IMAGE_CLEAR:
			return { ...state, error: null, success: null };
		default:
			return state;
	}
};

export const uploadAudioReducer = (state = {}, action) => {
	switch (action.type) {
		case UPLOAD_AUDIO_REQUEST:
			return { loading: true };
		case UPLOAD_AUDIO_SUCCESS:
			return {
				loading: false,
				success: action.payload,
				audio: action.audio
			};
		case UPLOAD_AUDIO_FAIL:
			return { loading: false, error: action.payload };
		case UPLOAD_AUDIO_CLEAR:
			return { ...state, error: null, success: null };
		default:
			return state;
	}
};

export const deleteAudioReducer = (state = {}, action) => {
	switch (action.type) {
		case DELETE_AUDIO_REQUEST:
			return { loading: true };
		case DELETE_AUDIO_SUCCESS:
			return {
				loading: false,
				success: action.payload
			};
		case DELETE_AUDIO_FAIL:
			return { loading: false, error: action.payload };
		case DELETE_AUDIO_CLEAR:
			return { ...state, error: null, success: null };
		default:
			return state;
	}
};

export const deleteRainbowImageReducer = (state = {}, action) => {
	switch (action.type) {
		case DELETE_RAINBOW_IMAGE_REQUEST:
			return { loading: true };
		case DELETE_RAINBOW_IMAGE_SUCCESS:
			return {
				loading: false,
				success: action.payload
			};
		case DELETE_RAINBOW_IMAGE_FAIL:
			return { loading: false, error: action.payload };
		case DELETE_RAINBOW_IMAGE_CLEAR:
			return { ...state, error: null, success: null };
		default:
			return state;
	}
};
export const createRainbowElementReducer = (state = {}, action) => {
	switch (action.type) {
		case CREATE_RAINBOW_ELEMENT_REQUEST:
			return { loading: true };
		case CREATE_RAINBOW_ELEMENT_SUCCESS:
			return {
				loading: false,
				success: action.payload,
				redirect: true
			};
		case CREATE_RAINBOW_ELEMENT_FAIL:
			return { loading: false, error: action.payload };
		case CREATE_RAINBOW_ELEMENT_CLEAR:
			return { ...state, error: null, success: null };
		case CREATE_RAINBOW_ELEMENT_CLEAR_REDIRECT:
			return { ...state, redirect: false };
		default:
			return state;
	}
};
export const updateRainbowElementReducer = (state = {}, action) => {
	switch (action.type) {
		case UPDATE_RAINBOW_ELEMENT_REQUEST:
			return { loading: true };
		case UPDATE_RAINBOW_ELEMENT_SUCCESS:
			return {
				loading: false,
				success: action.payload,
				redirect: true
			};
		case UPDATE_RAINBOW_ELEMENT_FAIL:
			return { loading: false, error: action.payload };
		case UPDATE_RAINBOW_ELEMENT_CLEAR:
			return { ...state, error: null, success: null };
		case UPDATE_RAINBOW_ELEMENT_CLEAR_REDIRECT:
			return { ...state, redirect: false };
		default:
			return state;
	}
};

export const deleteRainbowElementReducer = (state = {}, action) => {
	switch (action.type) {
		case DELETE_RAINBOW_ELEMENT_REQUEST:
			return { loading: true };
		case DELETE_RAINBOW_ELEMENT_SUCCESS:
			return {
				loading: false,
				success: action.payload,
				redirect: true
			};
		case DELETE_RAINBOW_ELEMENT_FAIL:
			return { loading: false, error: action.payload };
		case DELETE_RAINBOW_ELEMENT_CLEAR:
			return { ...state, error: null, success: null };
		case DELETE_RAINBOW_ELEMENT_CLEAR_REDIRECT:
			return { ...state, redirect: false };
		default:
			return state;
	}
};

export const addHistoryReducer = (state = {}, action) => {
	switch (action.type) {
		case ADD_HISTORY_REQUEST:
			return { loading: true };
		case ADD_HISTORY_SUCCESS:
			return {
				loading: false,
				success: action.payload
			};
		case ADD_HISTORY_FAIL:
			return { loading: false, error: action.payload };
		case ADD_HISTORY_CLEAR:
			return { ...state, error: null, success: null };
		default:
			return state;
	}
};

export const updateEmotionRatingReducer = (state = {}, action) => {
	switch (action.type) {
		case UPDATE_EMOTION_RATING_REQUEST:
			return { loading: true };
		case UPDATE_EMOTION_RATING_SUCCESS:
			return {
				loading: false,
				success: action.payload
			};
		case UPDATE_EMOTION_RATING_FAIL:
			return { loading: false, error: action.payload };
		case UPDATE_EMOTION_RATING_CLEAR:
			return { ...state, error: null, success: null };
		default:
			return state;
	}
};

export const addEventReducer = (state = {}, action) => {
	switch (action.type) {
		case ADD_EVENT_REQUEST:
			return { loading: true };
		case ADD_EVENT_SUCCESS:
			return {
				loading: false,
				success: action.payload
			};
		case ADD_EVENT_FAIL:
			return { loading: false, error: action.payload };
		case ADD_EVENT_CLEAR:
			return { ...state, error: null, success: null };
		default:
			return state;
	}
};
export const editEventReducer = (state = {}, action) => {
	switch (action.type) {
		case EDIT_EVENT_REQUEST:
			return { loading: true };
		case EDIT_EVENT_SUCCESS:
			return {
				loading: false,
				success: action.payload
			};
		case EDIT_EVENT_FAIL:
			return { loading: false, error: action.payload };
		case EDIT_EVENT_CLEAR:
			return { ...state, error: null, success: null };
		default:
			return state;
	}
};
export const deleteEventReducer = (state = {}, action) => {
	switch (action.type) {
		case DELETE_EVENT_REQUEST:
			return { loading: true };
		case DELETE_EVENT_SUCCESS:
			return {
				loading: false,
				success: action.payload
			};
		case DELETE_EVENT_FAIL:
			return { loading: false, error: action.payload };
		case DELETE_EVENT_CLEAR:
			return { ...state, error: null, success: null };
		default:
			return state;
	}
};

export const toggleTimesReducer = (state = {}, action) => {
	switch (action.type) {
		case TOGGLE_TIMES_REQUEST:
			return { loading: true };
		case TOGGLE_TIMES_SUCCESS:
			return {
				loading: false,
				success: action.payload
			};
		case TOGGLE_TIMES_FAIL:
			return { loading: false, error: action.payload };
		case TOGGLE_TIMES_CLEAR:
			return { ...state, error: null, success: null };
		default:
			return state;
	}
};
