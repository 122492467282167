export const GET_EMOTIONS_REQUEST = 'GET_EMOTIONS_REQUEST';
export const GET_EMOTIONS_SUCCESS = 'GET_EMOTIONS_SUCCESS';
export const GET_EMOTIONS_FAIL = 'GET_EMOTIONS_FAIL';
export const GET_EMOTIONS_CLEAR = 'GET_EMOTIONS_CLEAR';

export const GET_TOOLKIT_REQUEST = 'GET_TOOLKIT_REQUEST';
export const GET_TOOLKIT_SUCCESS = 'GET_TOOLKIT_SUCCESS';
export const GET_TOOLKIT_FAIL = 'GET_TOOLKIT_FAIL';
export const GET_TOOLKIT_CLEAR = 'GET_TOOLKIT_CLEAR';

export const CLICK_EMOTION = 'CLICK_EMOTION';
export const TOGGLE_SETTINGS_MESSAGE = 'TOGGLE_SETTINGS_MESSAGE';
export const CLOSE_EMOTION = 'CLOSE_EMOTION';
export const OPEN_TOOLKIT = 'OPEN_TOOLKIT';
export const CLOSE_TOOLKIT = 'CLOSE_TOOLKIT';
export const SET_ACTIVE_TOOL = 'SET_ACTIVE_TOOL';
export const OPEN_SUMMARY = 'OPEN_SUMMARY';
export const CLOSE_SUMMARY = 'CLOSE_SUMMARY';
export const SET_DEGREE = 'SET_DEGREE';
export const SET_NEGATIVE_EMOTIONS = 'SET_NEGATIVE_EMOTIONS';
export const SET_PHYSICAL_EMOTIONS = 'SET_MIND_EMOTIONS';

export const UPLOAD_RAINBOW_IMAGE_REQUEST = 'UPLOAD_RAINBOW_IMAGE_REQUEST';
export const UPLOAD_RAINBOW_IMAGE_SUCCESS = 'UPLOAD_RAINBOW_IMAGE_SUCCESS';
export const UPLOAD_RAINBOW_IMAGE_FAIL = 'UPLOAD_RAINBOW_IMAGE_FAIL';
export const UPLOAD_RAINBOW_IMAGE_CLEAR = 'UPLOAD_RAINBOW_IMAGE_CLEAR';

export const DELETE_RAINBOW_IMAGE_REQUEST = 'DELETE_RAINBOW_IMAGE_REQUEST';
export const DELETE_RAINBOW_IMAGE_SUCCESS = 'DELETE_RAINBOW_IMAGE_SUCCESS';
export const DELETE_RAINBOW_IMAGE_FAIL = 'DELETE_RAINBOW_IMAGE_FAIL';
export const DELETE_RAINBOW_IMAGE_CLEAR = 'DELETE_RAINBOW_IMAGE_CLEAR';

export const UPLOAD_AUDIO_REQUEST = 'UPLOAD_AUDIO_REQUEST';
export const UPLOAD_AUDIO_SUCCESS = 'UPLOAD_AUDIO_SUCCESS';
export const UPLOAD_AUDIO_FAIL = 'UPLOAD_AUDIO_FAIL';
export const UPLOAD_AUDIO_CLEAR = 'UPLOAD_AUDIO_CLEAR';

export const DELETE_AUDIO_REQUEST = 'DELETE_AUDIO_REQUEST';
export const DELETE_AUDIO_SUCCESS = 'DELETE_AUDIO_SUCCESS';
export const DELETE_AUDIO_FAIL = 'DELETE_AUDIO_FAIL';
export const DELETE_AUDIO_CLEAR = 'DELETE_AUDIO_CLEAR';

export const UPDATE_EMOTION_RATING_REQUEST = 'UPDATE_EMOTION_RATING_REQUEST';
export const UPDATE_EMOTION_RATING_SUCCESS = 'UPDATE_EMOTION_RATING_SUCCESS';
export const UPDATE_EMOTION_RATING_FAIL = 'UPDATE_EMOTION_RATING_FAIL';
export const UPDATE_EMOTION_RATING_CLEAR = 'UPDATE_EMOTION_RATING_CLEAR';

export const CREATE_RAINBOW_ELEMENT_REQUEST = 'CREATE_RAINBOW_ELEMENT_REQUEST';
export const CREATE_RAINBOW_ELEMENT_SUCCESS = 'CREATE_RAINBOW_ELEMENT_SUCCESS';
export const CREATE_RAINBOW_ELEMENT_FAIL = 'CREATE_RAINBOW_ELEMENT_FAIL';
export const CREATE_RAINBOW_ELEMENT_CLEAR = 'CREATE_RAINBOW_ELEMENT_CLEAR';
export const CREATE_RAINBOW_ELEMENT_CLEAR_REDIRECT =
	'CREATE_RAINBOW_ELEMENT_CLEAR_REDIRECT';

export const UPDATE_RAINBOW_ELEMENT_REQUEST = 'UPDATE_RAINBOW_ELEMENT_REQUEST';
export const UPDATE_RAINBOW_ELEMENT_SUCCESS = 'UPDATE_RAINBOW_ELEMENT_SUCCESS';
export const UPDATE_RAINBOW_ELEMENT_FAIL = 'UPDATE_RAINBOW_ELEMENT_FAIL';
export const UPDATE_RAINBOW_ELEMENT_CLEAR = 'UPDATE_RAINBOW_ELEMENT_CLEAR';
export const UPDATE_RAINBOW_ELEMENT_CLEAR_REDIRECT =
	'UPDATE_RAINBOW_ELEMENT_CLEAR_REDIRECT';

export const DELETE_RAINBOW_ELEMENT_REQUEST = 'DELETE_RAINBOW_ELEMENT_REQUEST';
export const DELETE_RAINBOW_ELEMENT_SUCCESS = 'DELETE_RAINBOW_ELEMENT_SUCCESS';
export const DELETE_RAINBOW_ELEMENT_FAIL = 'DELETE_RAINBOW_ELEMENT_FAIL';
export const DELETE_RAINBOW_ELEMENT_CLEAR = 'DELETE_RAINBOW_ELEMENT_CLEAR';
export const DELETE_RAINBOW_ELEMENT_CLEAR_REDIRECT =
	'DELETE_RAINBOW_ELEMENT_CLEAR_REDIRECT';

export const ADD_HISTORY_REQUEST = 'ADD_HISTORY_REQUEST';
export const ADD_HISTORY_SUCCESS = 'ADD_HISTORY_SUCCESS';
export const ADD_HISTORY_FAIL = 'ADD_HISTORY_FAIL';
export const ADD_HISTORY_CLEAR = 'ADD_HISTORY_CLEAR';
export const ADD_HISTORY_CLEAR_REDIRECT = 'ADD_HISTORY_CLEAR_REDIRECT';

export const SET_OUTCOME = 'SET_OUTCOME';

export const SET_ACTIVE_TOOL_CATEGORY = 'SET_ACTIVE_TOOL_CATEGORY';

export const ADD_EVENT_REQUEST = 'ADD_EVENT_REQUEST';
export const ADD_EVENT_SUCCESS = 'ADD_EVENT_SUCCESS';
export const ADD_EVENT_FAIL = 'ADD_EVENT_FAIL';
export const ADD_EVENT_CLEAR = 'ADD_EVENT_CLEAR';

export const EDIT_EVENT_REQUEST = 'EDIT_EVENT_REQUEST';
export const EDIT_EVENT_SUCCESS = 'EDIT_EVENT_SUCCESS';
export const EDIT_EVENT_FAIL = 'EDIT_EVENT_FAIL';
export const EDIT_EVENT_CLEAR = 'EDIT_EVENT_CLEAR';

export const DELETE_EVENT_REQUEST = 'DELETE_EVENT_REQUEST';
export const DELETE_EVENT_SUCCESS = 'DELETE_EVENT_SUCCESS';
export const DELETE_EVENT_FAIL = 'DELETE_EVENT_FAIL';
export const DELETE_EVENT_CLEAR = 'DELETE_EVENT_CLEAR';

export const TOGGLE_TIMES_REQUEST = 'TOGGLE_TIMES_REQUEST';
export const TOGGLE_TIMES_SUCCESS = 'TOGGLE_TIMES_SUCCESS';
export const TOGGLE_TIMES_FAIL = 'TOGGLE_TIMES_FAIL';
export const TOGGLE_TIMES_CLEAR = 'TOGGLE_TIMES_CLEAR';
