const toolkit = {
	chat: [
		{ title: 'Talk to friend', image: '/images/tools/chat/boy.png', audio: '' },
		{
			title: 'Talk to brother',
			image: '/images/tools/chat/brother.png',
			audio: ''
		},
		{
			title: 'Talk to classmate',
			image: '/images/tools/chat/classmate.png',
			audio: ''
		},
		{ title: 'Talk to dad', image: '/images/tools/chat/dad.png', audio: '' },
		{
			title: 'Talk to friend',
			image: '/images/tools/chat/friend.png',
			audio: ''
		},
		{
			title: 'Talk to friend',
			image: '/images/tools/chat/girl.png',
			audio: ''
		},
		{
			title: 'Talk to teacher',
			image: '/images/tools/chat/man.png',
			audio: ''
		},
		{
			title: 'talk to mum',
			image: '/images/tools/chat/mother.png',
			audio: ''
		},
		{
			title: 'Talk to sister',
			image: '/images/tools/chat/sister.png',
			audio: ''
		},
		{
			title: 'Talk to teacher',
			image: '/images/tools/chat/teacher.png',
			audio: ''
		},
		{
			title: 'Talk to Teacher',
			image: '/images/tools/chat/woman.png',
			audio: ''
		}
	],
	amusement: [
		{
			title: 'Art',
			image: '/images/tools/amusement/art.png',
			audio: ''
		},
		{
			title: 'Cards',
			image: '/images/tools/amusement/cards.png',
			audio: ''
		},
		{
			title: 'Ceramics',
			image: '/images/tools/amusement/ceramics.png',
			audio: ''
		},
		{
			title: 'Checkers',
			image: '/images/tools/amusement/checkers.png',
			audio: ''
		},
		{
			title: 'Chess',
			image: '/images/tools/amusement/chess1.png',
			audio: ''
		},
		{
			title: 'Clay Play',
			image: '/images/tools/amusement/clay-play.png',
			audio: ''
		},
		{
			title: 'Computer Games',
			image: '/images/tools/amusement/computer-games2.png',
			audio: ''
		},
		{
			title: 'Cooking',
			image: '/images/tools/amusement/cooking.png',
			audio: ''
		},
		{
			title: 'Crochet',
			image: '/images/tools/amusement/crochet.png',
			audio: ''
		},
		{
			title: 'Cross Stitch',
			image: '/images/tools/amusement/cross-stitch.png',
			audio: ''
		},
		{
			title: 'Doll Making',
			image: '/images/tools/amusement/doll-making.png',
			audio: ''
		},
		{
			title: 'Drama',
			image: '/images/tools/amusement/drama.png',
			audio: ''
		},
		{
			title: 'Drawing',
			image: '/images/tools/amusement/drawing1.png',
			audio: ''
		},
		{
			title: 'Drumming',
			image: '/images/tools/amusement/drumming.png',
			audio: ''
		},
		{
			title: 'Feed Birds',
			image: '/images/tools/amusement/feed-birds.png',
			audio: ''
		},
		{
			title: 'Fishing',
			image: '/images/tools/amusement/fishing.png',
			audio: ''
		},
		{
			title: 'Flower Arranging',
			image: '/images/tools/amusement/flower-arranging.png',
			audio: ''
		},
		{
			title: 'Games',
			image: '/images/tools/amusement/games.png',
			audio: ''
		},
		{
			title: 'Gardening',
			image: '/images/tools/amusement/gardening.png',
			audio: ''
		},
		{
			title: 'Go To Beach',
			image: '/images/tools/amusement/go-to-beach.png',
			audio: ''
		},
		{
			title: 'Go To Park',
			image: '/images/tools/amusement/go-to-park.png',
			audio: ''
		},
		{
			title: 'Initiate Social Activity',
			image: '/images/tools/amusement/initiate-social-activity1.png',
			audio: ''
		},
		{
			title: 'Joke Telling',
			image: '/images/tools/amusement/joke-telling.png',
			audio: ''
		},
		{
			title: 'Knitting',
			image: '/images/tools/amusement/knitting.png',
			audio: ''
		},
		{
			title: 'Make A Book',
			image: '/images/tools/amusement/make-a-book.png',
			audio: ''
		},
		{
			title: 'Make A Video',
			image: '/images/tools/amusement/make-a-video.png',
			audio: ''
		},
		{
			title: 'Mobile Phone Games',
			image: '/images/tools/amusement/mobile-phone-games.png',
			audio: ''
		},
		{
			title: 'Mosaics',
			image: '/images/tools/amusement/mosaics.png',
			audio: ''
		},
		{
			title: 'Musical Instruments',
			image: '/images/tools/amusement/musical-instruments.png',
			audio: ''
		},
		{
			title: 'Painting',
			image: '/images/tools/amusement/painting.png',
			audio: ''
		},
		{
			title: 'Patchwork',
			image: '/images/tools/amusement/patchwork.png',
			audio: ''
		},
		{
			title: 'Picnic',
			image: '/images/tools/amusement/picnic.png',
			audio: ''
		},
		{
			title: 'Play Pool',
			image: '/images/tools/amusement/play-pool.png',
			audio: ''
		},
		{
			title: 'Pottery',
			image: '/images/tools/amusement/pottery.png',
			audio: ''
		},
		{
			title: 'Puzzles',
			image: '/images/tools/amusement/puzzles.png',
			audio: ''
		},
		{
			title: 'Read Magazines',
			image: '/images/tools/amusement/read-magazines.png',
			audio: ''
		},
		{
			title: 'Read Newspaper',
			image: '/images/tools/amusement/read-newspaper.png',
			audio: ''
		},
		{
			title: 'Read Or Listen',
			image: '/images/tools/amusement/read-or-listen.png',
			audio: ''
		},
		{
			title: 'Sewing',
			image: '/images/tools/amusement/sewing.png',
			audio: ''
		},
		{
			title: 'Shopping',
			image: '/images/tools/amusement/shopping.png',
			audio: ''
		},
		{
			title: 'Singing',
			image: '/images/tools/amusement/singing.png',
			audio: ''
		},
		{
			title: 'Sodoku',
			image: '/images/tools/amusement/sodoku.png',
			audio: ''
		},
		{
			title: 'Story Telling',
			image: '/images/tools/amusement/story-telling.png',
			audio: ''
		},
		{
			title: 'Take Photos',
			image: '/images/tools/amusement/take-photos.png',
			audio: ''
		},
		{
			title: 'Toys',
			image: '/images/tools/amusement/toys.png',
			audio: ''
		},
		{
			title: 'Video Games',
			image: '/images/tools/amusement/video-games1.png',
			audio: ''
		},
		{
			title: 'Watch Comedy',
			image: '/images/tools/amusement/watch-comedy.png',
			audio: ''
		},
		{
			title: 'Watch Dvd',
			image: '/images/tools/amusement/watch-dvd.png',
			audio: ''
		},
		{
			title: 'Water Play',
			image: '/images/tools/amusement/water-play.png',
			audio: ''
		},
		{
			title: 'Writing Short Stories',
			image: '/images/tools/amusement/writing-short-stories.png',
			audio: ''
		}
	],
	exercise: [
		{
			title: 'Aerobics',
			image: '/images/tools/exercise/aerobics.png',
			audio: ''
		},
		{
			title: 'Basketball',
			image: '/images/tools/exercise/basketball.png',
			audio: ''
		},
		{
			title: 'Bike Riding',
			image: '/images/tools/exercise/bike-riding.png',
			audio: ''
		},
		{
			title: 'Bushwalking',
			image: '/images/tools/exercise/bushwalking.png',
			audio: ''
		},
		{
			title: 'Cricket',
			image: '/images/tools/exercise/cricket.png',
			audio: ''
		},
		{
			title: 'Dancing',
			image: '/images/tools/exercise/dancing.png',
			audio: ''
		},
		{
			title: 'Football',
			image: '/images/tools/exercise/football.png',
			audio: ''
		},
		{
			title: 'Golf',
			image: '/images/tools/exercise/golf.png',
			audio: ''
		},
		{
			title: 'Gym',
			image: '/images/tools/exercise/gym.png',
			audio: ''
		},
		{
			title: 'Horse Riding',
			image: '/images/tools/exercise/horse-riding.png',
			audio: ''
		},
		{
			title: 'Jumping',
			image: '/images/tools/exercise/jumping.png',
			audio: ''
		},
		{
			title: 'Karate',
			image: '/images/tools/exercise/karate.png',
			audio: ''
		},
		{
			title: 'Kayaking',
			image: '/images/tools/exercise/kayaking.png',
			audio: ''
		},
		{
			title: 'Netball',
			image: '/images/tools/exercise/netball.png',
			audio: ''
		},
		{
			title: 'Pilates',
			image: '/images/tools/exercise/pilates.png',
			audio: ''
		},
		{
			title: 'Ping Pong',
			image: '/images/tools/exercise/ping-pong.png',
			audio: ''
		},
		{
			title: 'Rollerblading',
			image: '/images/tools/exercise/rollerblading.png',
			audio: ''
		},
		{
			title: 'Running',
			image: '/images/tools/exercise/running.png',
			audio: ''
		},
		{
			title: 'Skating',
			image: '/images/tools/exercise/skating.png',
			audio: ''
		},
		{
			title: 'Stretching',
			image: '/images/tools/exercise/stretching.png',
			audio: ''
		},
		{
			title: 'Swimming',
			image: '/images/tools/exercise/swimming.png',
			audio: ''
		},
		{
			title: 'Walking',
			image: '/images/tools/exercise/walking.png',
			audio: ''
		},
		{
			title: 'Yoga',
			image: '/images/tools/exercise/yoga.png',
			audio: ''
		}
	],
	goodRoutine: [
		{
			title: 'Breakfast',
			image: '/images/tools/goodRoutine/breakfast.png',
			audio: ''
		},
		{
			title: 'Dinner',
			image: '/images/tools/goodRoutine/dinner.png',
			audio: ''
		},
		{
			title: 'Drink Enough Water',
			image: '/images/tools/goodRoutine/drink-enough-water.png',
			audio: ''
		},
		{
			title: 'Enough Rest',
			image: '/images/tools/goodRoutine/enough-rest.png',
			audio: ''
		},
		{
			title: 'Exercises On Time',
			image: '/images/tools/goodRoutine/exercises-on-time2.png',
			audio: ''
		},
		{
			title: 'Go To Bed',
			image: '/images/tools/goodRoutine/go-to-bed.png',
			audio: ''
		},
		{
			title: 'Lunch',
			image: '/images/tools/goodRoutine/lunch.png',
			audio: ''
		},
		{
			title: 'Meds On Time',
			image: '/images/tools/goodRoutine/meds-on-time.png',
			audio: ''
		},
		{
			title: 'Shower',
			image: '/images/tools/goodRoutine/shower.png',
			audio: ''
		}
	],
	helpfulThinking: [
		{
			title: 'Believe in yourself',
			image: '/images/tools/helpfulThinking/believe-in-yourself.png',
			audio: ''
		},
		{
			title: 'Challenge negative thoughts',
			image: '/images/tools/helpfulThinking/challenge-negative-thoughts.png',
			audio: ''
		},
		{
			title: 'Journal',
			image: '/images/tools/helpfulThinking/journal.png',
			audio: ''
		},
		{
			title: 'Look at happiness book',
			image: '/images/tools/helpfulThinking/look-at-happiness-book.png',
			audio: ''
		},
		{
			title: 'Smart Thinking',
			image: '/images/tools/helpfulThinking/smart-thinking.png',
			audio: ''
		},
		{
			title: 'Think helpful thoughts',
			image: '/images/tools/helpfulThinking/think-helpful-thoughts.png',
			audio: ''
		},
		{
			title: 'Think of a health affirmation',
			image: '/images/tools/helpfulThinking/think-of-a-health-affirmation.png',
			audio: ''
		},
		{
			title: 'Think of a self confidence affirmation',
			image:
				'/images/tools/helpfulThinking/think-of-a-self-confidence-affirmation.png',
			audio: ''
		},
		{
			title: 'Think of a social affirmation',
			image: '/images/tools/helpfulThinking/think-of-a-social-affirmation.png',
			audio: ''
		},
		{
			title: 'Think of an emotional affirmation',
			image:
				'/images/tools/helpfulThinking/think-of-an-emotional-affirmation.png',
			audio: ''
		},
		{
			title: 'Think of solutions',
			image: '/images/tools/helpfulThinking/think-of-solutions.png',
			audio: ''
		},
		{
			title: 'Think positive thoughts',
			image: '/images/tools/helpfulThinking/think-positive-thoughts.png',
			audio: ''
		}
	],
	relaxation: [
		{
			title: 'Aromatherapy',
			image: '/images/tools/relaxation/aromatherapy.png',
			audio: ''
		},
		{
			title: 'Break Time',
			image: '/images/tools/relaxation/break-time.png',
			audio: ''
		},
		{
			title: 'Brief Nap',
			image: '/images/tools/relaxation/brief-nap.png',
			audio: ''
		},
		{
			title: 'Closing Eyes',
			image: '/images/tools/relaxation/closing-eyes.png',
			audio: ''
		},
		{
			title: 'Count 1 10',
			image: '/images/tools/relaxation/count-1-10.png',
			audio: ''
		},
		{
			title: 'Crushing Aluminium Cans',
			image: '/images/tools/relaxation/crushing-aluminium-cans.png',
			audio: ''
		},
		{
			title: 'Deep Breathing',
			image: '/images/tools/relaxation/deep-breathing.png',
			audio: ''
		},
		{
			title: 'Favourite Food',
			image: '/images/tools/relaxation/favourite-food.png',
			audio: ''
		},
		{
			title: 'Go To Quiet Place',
			image: '/images/tools/relaxation/go-to-quiet-place.png',
			audio: ''
		},
		{
			title: 'Have A Bath',
			image: '/images/tools/relaxation/have-a-bath.png',
			audio: ''
		},
		{
			title: 'Listen To Music',
			image: '/images/tools/relaxation/listen-to-music.png',
			audio: ''
		},
		{
			title: 'Massage',
			image: '/images/tools/relaxation/massage1.png',
			audio: ''
		},
		{
			title: 'Meditation',
			image: '/images/tools/relaxation/meditation.png',
			audio: ''
		},
		{
			title: 'Minimise Caffeine',
			image: '/images/tools/relaxation/minimise-caffeine.png',
			audio: ''
		},
		{
			title: 'Muscle Tense And Release',
			image: '/images/tools/relaxation/muscle-tense-and-release.png',
			audio: ''
		},
		{
			title: 'Put Head Down',
			image: '/images/tools/relaxation/put-head-down1.png',
			audio: ''
		},
		{
			title: 'Qi Gong',
			image: '/images/tools/relaxation/qi-gong.png',
			audio: ''
		},
		{
			title: 'Repetitive Prayer',
			image: '/images/tools/relaxation/repetitive-prayer.png',
			audio: ''
		},
		{
			title: 'Rocking Chair',
			image: '/images/tools/relaxation/rocking-chair.png',
			audio: ''
		},
		{
			title: 'Stress Ball',
			image: '/images/tools/relaxation/stress-ball.png',
			audio: ''
		},
		{
			title: 'Stretching Exercises',
			image: '/images/tools/relaxation/stretching-exercises.png',
			audio: ''
		},
		{
			title: 'Supervisor',
			image: '/images/tools/relaxation/supervisor.png',
			audio: ''
		},
		{
			title: 'Walking',
			image: '/images/tools/relaxation/walking.png',
			audio: ''
		},
		{
			title: 'Yoga',
			image: '/images/tools/relaxation/yoga.png',
			audio: ''
		}
	]
};

export default toolkit;
