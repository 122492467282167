import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles(theme => ({
	main: {
		position: 'relative',
		overflow: 'visible',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		margin: '0 auto',
		padding: theme.spacing(6, 4, 2, 4),
		minHeight: `calc(100 * var(--vh))`,
		width: '90%',
		maxWidth: 1220,
		[theme.breakpoints.down('md')]: {
			maxWidth: 900
		},
		[theme.breakpoints.down('sm')]: {
			maxWidth: 600
		},
		[theme.breakpoints.down('xs')]: {
			padding: theme.spacing(4, 4, 2, 4)
		}
	},
	mainTitle: {
		position: 'fixed',
		top: 0,
		left: 0,
		maxWidth: `calc(100vw - (67 + ${theme.spacing(2)}))`,
		fontSize: '2.5rem',
		[theme.breakpoints.down('xs')]: {
			fontSize: '1.4rem'
		},
		padding: theme.spacing(0, 2, 0, 2),
		fontFamily: "'Poppins', sans-serif",
		fontWeight: 400,
		backgroundImage:
			'linear-gradient(to left, indigo, violet, blue, green, orange, red)',
		WebkitBackgroundClip: 'text',
		color: 'transparent',
		'& span': {
			// fontFamily: "'Nixie One', sans-serif",
			fontSize: '1rem',
			[theme.breakpoints.down('xs')]: {
				display: 'none'
			}
		},
		transition: 'all .1s ease ',
		zIndex: 2,
		cursor: 'pointer',
		textDecoration: 'none'
	},
	rainbowSubtitleContainer: {
		position: 'fixed',
		top: 60 + theme.spacing(2),
		left: 'clamp(20vw, calc(60* var(--vh)), 50vw)',
		marginLeft: theme.spacing(2),
		zIndex: 0,
		[theme.breakpoints.down('xs')]: {
			top: 33
		},
		// backgroundColor: theme.palette.background.default
		backgroundColor: 'rgba(255,255,255,0.5)',
		boxShadow: theme.shadows[1]
	},
	rainbowSubtitle: {
		padding: theme.spacing(1),
		whiteSpace: 'nowrap',
		textAlign: 'center',
		fontWeight: 700,
		[theme.breakpoints.down('xs')]: {
			fontSize: '1.2rem'
		}
	},
	bowTitle: {
		color: theme.palette.background.default,
		fontWeight: 200
	},
	bowTitle2: {
		color: 'rgba(0,0,0,.5)',
		transform: 'translate(2px, -2px)',
		fontWeight: 200
		// backgroundImage: 'none',
		// WebkitBackgroundClip: 'none'
	},
	// bowTitle2: {
	// 	fontWeight: 200,
	// },
	link: {
		color: theme.palette.secondary.main
	},
	logo: {
		width: 100,
		padding: theme.spacing(0.5),
		fill: theme.palette.primary.dark
	},
	navButton: {
		fontWeight: 800,
		marginLeft: theme.spacing(5),
		border: `1px solid transparent`,
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
		letterSpacing: 1,
		[theme.breakpoints.up('md')]: {
			'&:last-of-type': {
				marginRight: theme.spacing(5)
			}
		}
	},
	selected: {
		color: theme.palette.secondary.main,
		border: `1px solid ${theme.palette.secondary.main}`
	},
	drawer: {
		width: 150
	},
	drawerNavButton: {
		color: theme.palette.secondary.main,
		'&.Mui-selected': {
			backgroundColor: theme.palette.secondary.light,
			color: '#fff',
			'& .MuiListItemIcon-root': {
				color: theme.palette.common.white
			}
		},
		'& .MuiListItemIcon-root': {
			color: theme.palette.secondary.main
		}
	},
	footer: {
		height: 'min-content',
		width: '100%',
		// backgroundColor: theme.palette.common.tan2,
		background: 'transparent',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		overflow: 'hidden',
		padding: theme.spacing(1),
		marginTop: theme.spacing(2),
		'& a': {
			textDecoration: 'none',
			fontWeight: 700
		}
	},
	footerText: {
		textAlign: 'center'
	},
	footerButton: {
		fontWeight: 800,
		marginLeft: theme.spacing(1),
		'&:first-of-type': {
			marginLeft: 0
		}
	},
	fabFocus: {
		backgroundImage:
			'linear-gradient(to bottom left, indigo, violet, blue, green, orange, red)',
		opacity: 1
	},
	fab: {
		position: 'fixed',
		zIndex: 20,
		margin: theme.spacing(2),
		[theme.breakpoints.down('xs')]: {
			margin: theme.spacing(1)
		},
		transition: 'color .2s ease, transform .2s ease'
	},
	fab1: {
		top: 0,
		right: 0
	},
	fab2: {
		top: 88,
		right: 16,
		[theme.breakpoints.down('xs')]: {
			top: 64,
			right: 0
		}
	},
	fab3: {
		top: 144,
		right: 16,
		[theme.breakpoints.down('xs')]: {
			top: 112,
			right: 0
		}
	},
	fab4: {
		top: 200,
		right: 20,
		[theme.breakpoints.down('xs')]: {
			top: 160,
			right: 0
		}
	},
	fab5: {
		top: 248,
		right: 20,
		[theme.breakpoints.down('xs')]: {
			top: 208,
			right: 0
		}
	},
	infoIcon: {
		fontFamily: "'Nixie One', cursive",
		fontWeight: 700,
		fontSize: '1.1rem',
		textTransform: 'none'
	},
	backgroundButton: {
		position: 'absolute',
		top: 0,
		left: 0,
		bottom: 0,
		right: 0,
		zIndex: 1,
		transition: 'all 1s ease',
		'&:hover': {
			cursor: 'default'
		}
		// backgroundImage:
		// 	'linear-gradient (to top right, rgba(0,0,0,0.1), rgba(0,0,0,1))'
	},
	'@keyframes pulse': {
		'0%': {
			opacity: 0
		},
		'50%': {
			opacity: 0
		},
		'80%': {
			opacity: 1,
			transform: 'translate(-50%, 40%) scale(1)'
		},
		'90%': {
			transform: 'translate(-50%, 40%) scale(1.5)'
		},
		'100%': {
			transform: 'translate(-50%, 40%) scale(1)'
		}
	},
	'@keyframes pulse2': {
		'0%': {
			opacity: 0
		},
		'50%': {
			opacity: 0
		},
		'80%': {
			opacity: 1,
			transform: 'translate(50%, 40%) scale(1.2)'
		},
		'90%': {
			transform: 'translate(50%, 40%) scale(1.7)'
		},
		'100%': {
			transform: 'translate(50%, 40%) scale(1.2)'
		}
	},
	rainbowBackButton: {
		position: 'absolute',
		left: 15,
		bottom: 15,
		[theme.breakpoints.down('xs')]: {
			left: 5,
			bottom: 5
		},
		background: 'rgba(255,255, 255, 0.5)',
		// border: '1px solid #fff',
		zIndex: 3,
		color: '#000',
		// color: theme.palette.grey[900],
		transition: 'transform .2s ease',
		'&:hover': {
			background: 'rgba(255,255, 255, 0.5)',
			transform: 'scale(1.1)'
		}
	},
	apexApps: {
		position: 'fixed',
		bottom: 0,
		right: 0,
		paddingRight: 5,
		paddingLeft: 6,
		textDecoration: 'none',
		zIndex: 4,
		borderRadius: '5px 0 0 0',
		// backgroundColor: 'rgba(255, 255, 255, 0.7)',
		backgroundColor: '#000',
		color: theme.palette.grey[900],
		opacity: 0.9,
		'&:hover': {
			opacity: 1
		},
		'&:active': {
			opacity: 1
		},
		'& p': {
			fontWeight: 700,
			fontSize: '.7rem'
		},
		'& span': {
			fontWeight: 800,
			fontSize: '.8rem',
			color: '#FF481F'
		}
	}
}));

export default styles;
