import React, { useState } from 'react';
import clsx from 'clsx';
import {
	Paper,
	IconButton,
	Grid,
	Button,
	Typography,
	ButtonGroup,
	useMediaQuery,
	Modal
} from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import {
	ArrowBack,
	Close,
	ArrowForward,
	Build,
	CheckCircleOutline,
	SentimentVeryDissatisfied,
	SentimentSatisfied,
	SentimentVerySatisfied,
	PlayCircleFilled,
	RadioButtonUnchecked,
	RadioButtonChecked,
	VolumeUp
} from '@material-ui/icons';
import useBStyles from 'styles/bStyles';
import useCStyles from 'styles/cStyles';
import useDStyles from 'styles/dStyles';
import { useDispatch, useSelector } from 'react-redux';
import {
	CLOSE_TOOLKIT,
	SET_ACTIVE_TOOL,
	CLOSE_EMOTION,
	OPEN_SUMMARY,
	SET_OUTCOME,
	SET_ACTIVE_TOOL_CATEGORY
} from 'constants/rainbowConstants';
import { addHistoryAction } from 'actions/rainbowActions';
import RainbowCloud from 'assets/svg/rainbow-cloud.svg';
import Icon1 from 'assets/svg/businessmen.svg';
import Icon2 from 'assets/svg/light-bulb.svg';
import Icon3 from 'assets/svg/happy.svg';
import Icon4 from 'assets/svg/prenatal.svg';
import Icon5 from 'assets/svg/calendar.svg';
import Icon6 from 'assets/svg/running.svg';
import Bolt from 'assets/svg/light-bolt.svg';
import ScheduleModal from 'components/ScheduleModal';

const icons = [
	<Icon1 />,
	<Icon2 />,
	<Icon3 />,
	<Icon4 />,
	<Icon5 />,
	<Icon6 />
];

const toolCategoryTitles = {
	chat: 'Chat',
	helpfulThinking: 'Helpful Thinking',
	amusement: 'Amusement',
	relaxation: 'Relaxation',
	goodRoutine: 'Good Routine',
	exercise: 'Exercise'
};

const ChargeToolkit = ({ innerHeight }) => {
	const bClasses = useBStyles();
	const cClasses = useCStyles();
	const dClasses = useDStyles();
	const dispatch = useDispatch();
	const theme = useTheme();
	const matchesXS = useMediaQuery(theme => theme.breakpoints.down('xs'));
	const matchesSM = useMediaQuery(theme => theme.breakpoints.down('sm'));
	const mediaMin800 = useMediaQuery('(min-width:800px)');
	const [page, setPage] = useState(1);
	const [happiness, setHappiness] = useState('');
	const [happinessHover, setHappinessHover] = useState('');
	const { isAuth, user } = useSelector(state => state.userData);
	const { getToolkit: defaultToolkit } = useSelector(state => state);
	const toolkit = isAuth ? user.toolkit : defaultToolkit;

	const { activeTool } = useSelector(state => state.rainbowState);
	const [toolCategory, setToolCategory] = useState(null);
	const [toolCategoryHover, setToolCategoryHover] = useState(null);
	const [carouselIsOpen, setCarouselIsOpen] = useState(false);
	const [toolIndex, setToolIndex] = useState(0);
	const [carouselDirection, setCarouselDirection] = useState('');
	const [scheduleModalIsOpen, setScheduleModalIsOpen] = useState(false);

	const setHappinessHandler = outcome => {
		setHappiness(outcome);
		dispatch({ type: SET_OUTCOME, payload: outcome });
	};

	const setHappinessHoverHandler = outcome => {
		setHappinessHover(outcome);
	};

	const handleClose = () => {
		dispatch({ type: CLOSE_TOOLKIT });
	};
	const handleNextButton = () => {
		if (page === 1) {
			if (toolCategory === 'goodRoutine') {
				setScheduleModalIsOpen(true);
			} else {
				setCarouselIsOpen(true);
			}
		} else {
			if (isAuth) dispatch(addHistoryAction());
			dispatch({ type: OPEN_SUMMARY });
			dispatch({ type: CLOSE_EMOTION });
			dispatch({ type: CLOSE_TOOLKIT });
		}
	};
	const handleBackButton = () => {
		if (page === 1) {
			dispatch({ type: CLOSE_TOOLKIT });
		} else {
			setPage(page - 1);
		}
	};

	const handleNextTool = () => {
		setToolIndex(prev =>
			prev === toolkit[toolCategory].length - 1 ? 0 : prev + 1
		);
		setCarouselDirection('left');
	};
	const handlePrevTool = () => {
		setToolIndex(prev =>
			prev === 0 ? toolkit[toolCategory].length - 1 : prev - 1
		);
		setCarouselDirection('right');
	};
	const handleSelectTool = tool => {
		setCarouselIsOpen(false);
		dispatch({ type: SET_ACTIVE_TOOL, tool });
		setPage(2);
	};

	const handleToolCategorySelect = category => {
		setToolCategory(category);
		dispatch({
			type: SET_ACTIVE_TOOL_CATEGORY,
			toolCategory: toolCategoryTitles[category]
		});
	};
	const handleToolCategoryHover = category => {
		setToolCategoryHover(category);
	};

	const handlePlayAudio = audio => {
		var audioRecording = new Audio(audio);
		audioRecording.play();
	};

	return (
		<>
			<ScheduleModal
				open={scheduleModalIsOpen}
				setOpen={setScheduleModalIsOpen}
				useToolButton
				useToolFunction={() => handleSelectTool({ title: 'Schedule' })}
			/>

			{toolCategory && (
				<Modal open={carouselIsOpen} onClose={() => setCarouselIsOpen(false)}>
					<Paper
						variant='outlined'
						className={dClasses.toolModal}
						component={Grid}
						container
						direction='column'
						alignItems='center'
						justifyContent='space-between'
						style={{
							bottom:
								innerHeight > 470
									? 'calc((calc(100 * var(--vh)) - 430px) / 2)'
									: theme.spacing(3)
						}}
					>
						<IconButton
							className={clsx(
								dClasses.changeToolButton,
								dClasses.prevToolButton
							)}
							onClick={handlePrevTool}
						>
							<PlayCircleFilled fontSize='large' />
						</IconButton>
						<IconButton
							color='secondary'
							className={clsx(
								dClasses.changeToolButton,
								dClasses.nextToolButton
							)}
							onClick={handleNextTool}
						>
							<PlayCircleFilled fontSize='large' />
						</IconButton>
						<div className={cClasses.scrollContainer}>
							<Typography variant='h4' className={dClasses.toolModalTitle}>
								Which {toolCategoryTitles[toolCategory].toLowerCase()} tool will
								you use?
							</Typography>
							<Grid container className={dClasses.toolImageContainer}>
								{toolkit[toolCategory].map((tool, i) => (
									<Grid
										variant='outlined'
										key={tool._id}
										container
										direction='column'
										alignItems='center'
										className={clsx(
											dClasses.toolImagePaper,
											toolIndex === i && carouselDirection === ''
												? dClasses.firstTool
												: i === toolkit[toolCategory].length - 1 &&
												  carouselDirection === ''
												? null
												: toolIndex === i && carouselDirection === 'left'
												? dClasses.toolInLeft
												: toolIndex === i && carouselDirection === 'right'
												? dClasses.toolInRight
												: (toolIndex === 0 &&
														i === toolkit[toolCategory].length - 1 &&
														carouselDirection === 'left') ||
												  (i === toolIndex - 1 && carouselDirection === 'left')
												? dClasses.toolOutLeft
												: (toolIndex === toolkit[toolCategory].length - 1 &&
														i === 0 &&
														carouselDirection === 'right') ||
												  (i === toolIndex + 1 && carouselDirection === 'right')
												? dClasses.toolOutRight
												: null
										)}
										style={{ minWidth: 128, minHeight: 128 }}
									>
										<Typography className={dClasses.toolImageText}>
											{tool.title}
										</Typography>
										<img
											alt={tool.title}
											src={tool.image}
											style={{ width: 128, height: 128 }}
										/>
										{tool.audio && (
											<IconButton
												className={dClasses.toolAudioButton}
												onClick={() => handlePlayAudio(tool.audio)}
												color='secondary'
											>
												<VolumeUp size='large' />
											</IconButton>
										)}
									</Grid>
								))}
							</Grid>
						</div>
						<div className={dClasses.chargeButtonContainer}>
							<div className={dClasses.toolIndicatorContainer}>
								{toolkit[toolCategory].map((tool, i) =>
									i === toolIndex ? (
										<RadioButtonChecked fontSize='small' key={tool._id} />
									) : (
										<RadioButtonUnchecked fontSize='small' key={tool._id} />
									)
								)}
							</div>
							<Button
								endIcon={<Build />}
								variant='contained'
								disableElevation
								color='secondary'
								className={dClasses.toolModalButton}
								onClick={() =>
									handleSelectTool(toolkit[toolCategory][toolIndex])
								}
							>
								Use Tool
							</Button>
						</div>
					</Paper>
				</Modal>
			)}
			<Paper
				variant='outlined'
				className={cClasses.overlayPaper}
				component={Grid}
				container
				direction='column'
				justifyContent='space-between'
				style={{ width: 'calc(100vw - 240px)' }}
				wrap='nowrap'
			>
				<div className={cClasses.scrollContainer}>
					<IconButton className={cClasses.closeButton} onClick={handleClose}>
						<Close fontSize='small' />
					</IconButton>
					<Grid item container alignItems='center' direction='column'>
						<Typography
							variant='h1'
							className={cClasses.title}
							style={{
								fontSize:
									matchesXS || innerHeight < 520
										? '2rem'
										: matchesSM || innerHeight < 600
										? '2.5rem'
										: '3rem'
							}}
						>
							{page === 1
								? 'CHARGE toolkit'
								: page === 2
								? toolCategoryTitles[toolCategory]
								: 'How do you feel?'}
						</Typography>

						<Typography
							variant='h4'
							className={cClasses.subTitle}
							style={{
								fontSize: matchesXS || innerHeight < 520 ? '1.2rem' : '1.6rem'
							}}
						>
							{page === 1
								? `Choose your emotional management tool${
										isAuth ? `, ${user.name}` : '!'
								  }`
								: 'Rate how happy you feel after using the tool'}
						</Typography>
					</Grid>
					{page === 1 ? (
						<div
							className={dClasses.center}
							style={{
								width: innerHeight * 0.7
							}}
						>
							<div className={dClasses.rainbowCloudContainer}>
								<RainbowCloud
									className={dClasses.rainbowCloud}
									alt='CHARGE cloud toolkit'
								/>
								{Object.keys(toolkit).map((category, i) => (
									<div
										key={category}
										className={clsx(
											dClasses.icon,
											dClasses[`icon${i + 1}`],
											toolCategory === category && dClasses.iconSelected
										)}
										onClick={() => handleToolCategorySelect(category)}
										onMouseEnter={() => handleToolCategoryHover(category)}
										onMouseLeave={() => handleToolCategoryHover(null)}
									>
										{icons[i]}
										<Typography
											style={{
												fontSize:
													mediaMin800 && innerHeight > 730 ? '.8rem' : '.5rem',
												left:
													matchesSM &&
													(category === 'helpfulThinking' ||
														category === 'amusement')
														? 'calc(50% - 4px)'
														: matchesSM &&
														  (category === 'relaxation' ||
																category === 'goodRoutine')
														? 'calc(50% + 4px)'
														: '50%'
											}}
											className={clsx(
												dClasses.iconText,
												toolCategory === category && dClasses.iconTextSelected
											)}
										>
											{toolCategoryTitles[category]}
										</Typography>
									</div>
								))}
								{toolCategory && (
									<div className={dClasses.bolt}>
										<Bolt />
									</div>
								)}
							</div>
							<Grid container direction='column' alignItems='center'>
								<Typography
									variant='h2'
									className={dClasses.chargeHeading}
									style={{
										color:
											toolCategory || toolCategoryHover
												? ''
												: 'rgba(0,0,0,0.3)',
										fontSize:
											matchesXS || innerHeight < 520
												? '1.6rem'
												: matchesSM || innerHeight < 600
												? '2.1rem'
												: '2.6rem'
									}}
								>
									{toolCategoryHover
										? toolCategoryTitles[toolCategoryHover]
										: toolCategory
										? toolCategoryTitles[toolCategory]
										: 'Tap a tool to select '}
								</Typography>
							</Grid>
						</div>
					) : (
						<Grid
							container
							direction='column'
							alignItems='center'
							justifyContent='flex-start'
							className={bClasses.happyButtonGrid}
							style={{ minWidth: 128, minHeight: 230 }}
							wrap='nowrap'
						>
							{toolCategory === 'goodRoutine' ? (
								<Icon5
									style={{
										width: 128,
										height: 128,
										padding: theme.spacing(3),
										fill: theme.palette.secondary.main
									}}
								/>
							) : (
								<img
									style={{ width: 128, height: 128 }}
									src={activeTool?.image}
									alt={activeTool?.text}
								/>
							)}
							<ButtonGroup
								size='large'
								color='secondary'
								className={bClasses.happyButtonGroup}
							>
								<Button
									size='large'
									className={clsx(
										happiness === 'Unhappy' && bClasses.happyButtonHighlight
									)}
									onClick={() => setHappinessHandler('Unhappy')}
									onMouseEnter={() => setHappinessHoverHandler('Unhappy')}
									onMouseLeave={() => setHappinessHoverHandler('')}
								>
									<SentimentVeryDissatisfied fontSize='large' />
								</Button>
								<Button
									size='large'
									className={clsx(
										happiness === 'Happy' && bClasses.happyButtonHighlight
									)}
									onClick={() => setHappinessHandler('Happy')}
									onMouseEnter={() => setHappinessHoverHandler('Happy')}
									onMouseLeave={() => setHappinessHoverHandler('')}
								>
									<SentimentSatisfied fontSize='large' />
								</Button>
								<Button
									size='large'
									className={clsx(
										happiness === 'Very Happy' && bClasses.happyButtonHighlight
									)}
									onClick={() => setHappinessHandler('Very Happy')}
									onMouseEnter={() => setHappinessHoverHandler('Very Happy')}
									onMouseLeave={() => setHappinessHoverHandler('')}
								>
									<SentimentVerySatisfied fontSize='large' />
								</Button>
							</ButtonGroup>
							<Typography
								variant='h4'
								className={dClasses.chargeHeading}
								style={{
									fontSize:
										matchesXS || innerHeight < 520
											? '1.4rem'
											: matchesSM || innerHeight < 600
											? '1.8rem'
											: '2.2rem',
									color: !happiness && !happinessHover ? 'rgba(0,0,0,0.3)' : ''
								}}
							>
								{happinessHover
									? happinessHover
									: happiness
									? happiness
									: 'Rate your happiness'}
							</Typography>
						</Grid>
					)}
				</div>
				<Grid
					item
					container
					justifyContent='space-between'
					className={cClasses.buttonGrid}
					xs
					zeroMinWidth
				>
					<Button
						variant='outlined'
						disableElevation
						startIcon={<ArrowBack />}
						size='large'
						className={bClasses.rainbowButton}
						color='secondary'
						onClick={handleBackButton}
						disabled={carouselIsOpen || scheduleModalIsOpen}
					>
						Back
					</Button>

					<Button
						variant='contained'
						disableElevation
						endIcon={page === 2 ? <CheckCircleOutline /> : <ArrowForward />}
						size='large'
						className={bClasses.rainbowButton}
						color='secondary'
						disabled={
							!toolCategory ||
							(page === 2 && happiness === '') ||
							carouselIsOpen ||
							scheduleModalIsOpen
						}
						onClick={handleNextButton}
					>
						{page === 2 ? 'Finish' : 'Select Tool'}
					</Button>
				</Grid>
			</Paper>
		</>
	);
};

export default ChargeToolkit;
