import React, { useEffect, useState, useRef } from 'react';
import moment from 'moment';
import ReactToPrint from 'react-to-print';
import {
	Grid,
	Fade,
	Modal,
	Paper,
	IconButton,
	Typography,
	TablePagination,
	useMediaQuery,
	Button,
	useTheme
} from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import {
	MuiPickersUtilsProvider,
	KeyboardTimePicker,
	KeyboardDatePicker
} from '@material-ui/pickers';
import { ArrowForward, Close, Print } from '@material-ui/icons';
import useBStyles from 'styles/bStyles';
import useCStyles from 'styles/cStyles';
import { useSelector } from 'react-redux';
import HistoryTable from 'components/HistoryTable';

const sortAlphabetically = (a, b, key, order) => {
	if (key === 'time' || key === 'date') key = 'createdAt';
	const valueA = typeof a[key] === 'string' ? a[key].toUpperCase() : a[key];
	const valueB = typeof b[key] === 'string' ? b[key].toUpperCase() : b[key];

	if (order === 'asc' ? valueA < valueB : valueA > valueB) {
		return -1;
	}
	if (order === 'asc' ? valueA > valueB : valueA < valueB) {
		return 1;
	}
	return 0;
};

const headCells = [
	{ label: 'Date', id: 'date' },
	{ label: 'Time', id: 'time' },
	{ label: 'Emotion', id: 'emotion' },
	{ label: 'Emotion Type', id: 'emotionType' },
	{ label: 'Rating', id: 'degree' },
	{ label: 'Tool', id: 'tool' },
	{ label: 'Tool Category', id: 'toolCategory' },
	{ label: 'Outcome', id: 'outcome' }
];

const getRows = (
	history,
	orderBy,
	order,
	toDate,
	fromDate,
	page,
	rowsPerPage,
	print = null
) => {
	const printRows = history
		.sort((a, b) => sortAlphabetically(a, b, orderBy, order))
		.filter(x => {
			const getUnix = time => moment(time).unix();
			return (
				getUnix(x.createdAt) < getUnix(toDate) &&
				getUnix(x.createdAt) > getUnix(fromDate)
			);
		});
	const rows = printRows.slice(page * rowsPerPage, (page + 1) * rowsPerPage);
	return print === 'print' ? printRows : rows;
};

const HistoryModal = ({ open, setOpen }) => {
	const bClasses = useBStyles();
	const cClasses = useCStyles();
	const theme = useTheme();
	const matchesXS = useMediaQuery(theme => theme.breakpoints.down('xs'));
	const matchesSM = useMediaQuery(theme => theme.breakpoints.down('sm'));

	const {
		user,
		user: { history }
	} = useSelector(state => state.userData);

	const tableRef = useRef(null);

	const [order, setOrder] = useState('desc');
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(5);
	const [orderBy, setOrderBy] = useState('date');
	const [fromDate, setFromDate] = useState(moment().subtract(1, 'months'));
	const [toDate, setToDate] = useState(new Date());

	const [rows, setRows] = useState(
		getRows(history, orderBy, order, toDate, fromDate, page, rowsPerPage)
	);

	const sortHandler = (event, property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const ChangePageHandler = (event, newPage) => {
		setPage(newPage);
	};

	const ChangeRowsPerPageHandler = event => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const fromDateChangeHandler = date => {
		setFromDate(date);
	};

	const toDateChangeHandler = date => {
		setToDate(date);
	};

	useEffect(() => {
		setRows(
			getRows(history, orderBy, order, toDate, fromDate, page, rowsPerPage)
		);
	}, [orderBy, rowsPerPage, page, order, fromDate, toDate, history]);

	return (
		<>
			<Modal open={open} onClose={() => setOpen(false)}>
				<Fade in={open}>
					<Paper className={bClasses.modalPaper}>
						<div style={{ display: 'none' }}>
							<HistoryTable
								sortHandler={sortHandler}
								matchesXS={matchesXS}
								matchesSM={matchesSM}
								headCells={headCells}
								orderBy={orderBy}
								order={order}
								rows={getRows(
									history,
									orderBy,
									order,
									toDate,
									fromDate,
									page,
									rowsPerPage,
									'print'
								)}
								tableRef={tableRef}
								printFriendly
							/>
						</div>
						<IconButton
							className={bClasses.close}
							onClick={() => setOpen(false)}
						>
							<Close />
						</IconButton>
						<ReactToPrint
							documentTitle={`${user.name}'s Rainbow of Emotions History `}
							trigger={() => (
								<Button
									variant='outlined'
									className={bClasses.pdf}
									color='secondary'
									size='large'
									endIcon={<Print />}
									style={{ textTransform: 'none' }}
								>
									Print
								</Button>
							)}
							content={() => tableRef.current}
						/>
						<Grid container justifyContent='center'>
							<Typography className={cClasses.modalTitle} variant='h2'>
								History
							</Typography>
						</Grid>
						<HistoryTable
							sortHandler={sortHandler}
							matchesXS={matchesXS}
							matchesSM={matchesSM}
							headCells={headCells}
							orderBy={orderBy}
							order={order}
							rows={rows}
							printFriendly
						/>
						{matchesSM && (
							<Grid
								container
								justifyContent='flex-end'
								alignItems='center'
								style={{ marginTop: theme.spacing(1) }}
							>
								<Typography variant='body2'>Scroll for more</Typography>
								<ArrowForward
									style={{
										marginLeft: theme.spacing(1),
										color: theme.palette.grey[700]
									}}
									fontSize='small'
								/>
							</Grid>
						)}
						<TablePagination
							rowsPerPageOptions={[5, 10, 25]}
							component='div'
							count={history.length}
							rowsPerPage={rowsPerPage}
							page={page}
							onPageChange={ChangePageHandler}
							onRowsPerPageChange={ChangeRowsPerPageHandler}
							className={cClasses.pagination}
						/>

						<MuiPickersUtilsProvider utils={DateFnsUtils}>
							<Grid container>
								<Grid
									item
									xs
									className={cClasses.datePickPaper}
									component={Paper}
									variant='outlined'
									container
								>
									<Grid
										item
										xs={12}
										md={6}
										className={cClasses.datePickerContainer}
										justifyContent='center'
										container
									>
										<KeyboardDatePicker
											margin='normal'
											id='from=date-picker'
											label='From Date'
											format='dd/MM/yyyy'
											value={fromDate}
											onChange={fromDateChangeHandler}
											KeyboardButtonProps={{
												'aria-label': 'change date'
											}}
											className={cClasses.datePicker}
										/>
									</Grid>
									<Grid
										item
										xs={12}
										md={6}
										className={cClasses.datePickerContainer}
										justifyContent='center'
										container
									>
										<KeyboardTimePicker
											margin='normal'
											id='from-time-picker'
											label='From Time'
											value={fromDate}
											onChange={fromDateChangeHandler}
											KeyboardButtonProps={{
												'aria-label': 'change time'
											}}
											className={cClasses.datePicker}
										/>
									</Grid>
								</Grid>

								<Grid
									item
									xs
									component={Paper}
									className={cClasses.datePickPaper}
									variant='outlined'
									container
								>
									<Grid
										item
										xs={12}
										md={6}
										className={cClasses.datePickerContainer}
										justifyContent='center'
										container
									>
										<KeyboardDatePicker
											margin='normal'
											id='to-date-picker-dialog'
											label='To Date'
											format='dd/MM/yyyy'
											value={toDate}
											onChange={toDateChangeHandler}
											KeyboardButtonProps={{
												'aria-label': 'change date'
											}}
											className={cClasses.datePicker}
										/>
									</Grid>
									<Grid
										item
										xs={12}
										md={6}
										className={cClasses.datePickerContainer}
										justifyContent='center'
										container
									>
										<KeyboardTimePicker
											margin='normal'
											id='to-time-picker'
											label='To Time'
											value={toDate}
											onChange={toDateChangeHandler}
											KeyboardButtonProps={{
												'aria-label': 'change time'
											}}
											className={cClasses.datePicker}
										/>
									</Grid>
								</Grid>
							</Grid>
						</MuiPickersUtilsProvider>
					</Paper>
				</Fade>
			</Modal>
		</>
	);
};

export default HistoryModal;
