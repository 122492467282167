import {
	Paper,
	Typography,
	Grid,
	useTheme,
	IconButton
} from '@material-ui/core';
import React from 'react';
import useCStyles from 'styles/cStyles';
import { Link } from 'react-router-dom';
import { Close } from '@material-ui/icons';

const PageNotFoundScreen = () => {
	const cClasses = useCStyles();
	const theme = useTheme();
	return (
		<Paper
			classes={{ root: cClasses.screenRoot }}
			elevation={5}
			component={Grid}
			container
			direction='column'
			alignItems='center'
		>
			<IconButton className={cClasses.closeButton} component={Link} to='/'>
				<Close fontSize='small' />
			</IconButton>
			<Typography variant='h3' style={{ marginBottom: theme.spacing(1) }}>
				Page not found
			</Typography>
		</Paper>
	);
};

export default PageNotFoundScreen;
