import React, { useState, useCallback, useRef, useEffect } from 'react';
import clsx from 'clsx';
import {
	Button,
	Grid,
	Paper,
	Typography,
	Divider,
	Avatar,
	IconButton,
	CircularProgress,
	useTheme
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { logoutAction, uploadProfileImageAction } from 'actions/userActions';
import ProfileModal from 'components/ProfileModal';
import HistoryModal from 'components/HistoryModal';
import useBStyles from 'styles/bStyles';
import useCStyles from 'styles/cStyles';
import {
	Close,
	AddAPhoto,
	History,
	PersonAdd,
	Email, Subscriptions
} from '@material-ui/icons';
import { Link } from 'react-router-dom';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import PurchaseUsersModal from 'components/PurchaseUsersModal';
import { sendPurchasedUsersAction } from 'actions/userActions';
import SubscriptionsModal from "../components/SubscriptionsModal";

const ProfileScreen = () => {
	const bClasses = useBStyles();
	const cClasses = useCStyles();
	const dispatch = useDispatch();
	const theme = useTheme();
	const {
		user: { address },
		user
	} = useSelector(state => state.userData);
	const [historyprofileModalIsOpen, setHistoryprofileModalIsOpen] =
		useState(false);
	const [subscriptionsProfileModalIsOpen, setSubscriptionsProfileModalIsOpen] =
		useState(false);

	const [profileModalIsOpen, setProfileModalIsOpen] = useState(false);
	const [purchaseUsersModalIsOpen, setPurchaseUsersModalIsOpen] =
		useState(false);
	const [resendUserCodes, setResendUserCodes] = useState(false);

	const [upImg, setUpImg] = useState();
	const [crop, setCrop] = useState({ unit: 'px',
		width: 150, height: 150, x: 110,y:55,
		 });
	const [completedCrop, setCompletedCrop] = useState(null);
	const imgRef = useRef(null);
	const previewCanvasRef = useRef(null);
	const { error: userUpdateProfileError, success: userUpdateProfileSuccess } =
		useSelector(state => state.userUpdateProfile);
	const { loading: uploadImageIsLoading } = useSelector(
		state => state.uploadProfileImage
	);
	const {
		loading: sendPurchasedUsersLoading,
		success: sendPurchasedUsersSuccess
	} = useSelector(state => state.sendPurchasedUsers);

	useEffect(() => {
		if (userUpdateProfileSuccess || userUpdateProfileError) {
			setProfileModalIsOpen(false);
		}
	}, [userUpdateProfileSuccess, userUpdateProfileError]);

	const uploadFileHandler = e => {
		const file = e.target.files[0];
		if (file) {
			const reader = new FileReader();
			reader.addEventListener('load', () => setUpImg(reader.result));
			reader.readAsDataURL(file);
		}
	};

	const historyModalHandler = () => {
		setHistoryprofileModalIsOpen(true);
	};

	const subscriptionsModalHandler = () => {
		setSubscriptionsProfileModalIsOpen(true);
	};

	const resendCodesHandler = () => {
		setResendUserCodes(true);
		dispatch(sendPurchasedUsersAction());
	};
	const generateAndUpload = (canvas, crop) => {
		if (!crop || !canvas) {
			return;
		}
		canvas.toBlob(
			(blob) => {
				dispatch(uploadProfileImageAction(blob));
				setUpImg(null);
			},
			'image/png',
			1
		);
	}

	const onLoad = useCallback((img) => {
		imgRef.current = img;
	}, []);

	useEffect(() => {
		if (sendPurchasedUsersSuccess) setResendUserCodes(false);
	}, [sendPurchasedUsersSuccess]);

	useEffect(() => {
		if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
			return;
		}

		const image = imgRef.current;
		const canvas = previewCanvasRef.current;
		const crop = completedCrop;

		const scaleX = image.naturalWidth / image.width;
		const scaleY = image.naturalHeight / image.height;
		const ctx = canvas.getContext('2d');
		const pixelRatio = window.devicePixelRatio;

		canvas.width = crop.width * pixelRatio;
		canvas.height = crop.height * pixelRatio;

		ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
		ctx.imageSmoothingQuality = 'high';

		ctx.drawImage(
			image,
			crop.x * scaleX,
			crop.y * scaleY,
			crop.width * scaleX,
			crop.height * scaleY,
			0,
			0,
			crop.width,
			crop.height
		);
	}, [completedCrop]);


	return (
		<>
			<ProfileModal
				open={profileModalIsOpen}
				setOpen={setProfileModalIsOpen}
				user={user}
			/>
			<HistoryModal
				open={historyprofileModalIsOpen}
				setOpen={setHistoryprofileModalIsOpen}
			/>
			<SubscriptionsModal
			open={subscriptionsProfileModalIsOpen}
			setOpen={setSubscriptionsProfileModalIsOpen}
			>

			</SubscriptionsModal>

			<PurchaseUsersModal
				open={purchaseUsersModalIsOpen}
				setOpen={setPurchaseUsersModalIsOpen}
			/>
			<Paper
				classes={{ root: cClasses.screenRoot }}
				style={{ width: 330, padding: theme.spacing(3, 0, 1, 0) }}
				elevation={5}
				component={Grid}
				container
				direction='column'
				alignItems='center'
				zeroMinWidth
				item
			>
				<IconButton className={cClasses.closeButton} component={Link} to='/'>
					<Close fontSize='small' />
				</IconButton>
				{upImg && <Grid
					container
					direction='column'
					alignItems='center'
					style={{ paddingTop: theme.spacing(2) }}>
				<br/>
				<ReactCrop
					src={upImg}
					onImageLoaded={onLoad}
					crop={crop}
					onChange={(c) => setCrop(c)}
					onComplete={(c) => setCompletedCrop(c)}
				/>
					<Button
						className={cClasses.button}
						style={{ paddingTop: theme.spacing(2) }}
						onClick={() => generateAndUpload(previewCanvasRef.current, completedCrop)}
						color='secondary'
					>
						SAVE IMAGE
					</Button>
					<div className={cClasses.avatarContainer}>
						<div
							className={cClasses.avatar}
							style={{ position: 'absolute', width: 200, height: 200 }}
							alt={`${user?.name} profile image`}
						>
						<canvas
							ref={previewCanvasRef}
							// Rounding is important so the canvas width and height matches/is a multiple for sharpness.
							style={{
								width: '100%',
								height: '100%',
								'object-fit': 'cover'
							}}
						/>
						</div>
					</div>
				</Grid>}

				{!upImg && <div className={cClasses.avatarContainer}>

					<Avatar
						className={cClasses.avatar}
						style={{ position: 'absolute' }}
						alt={`${user?.name} profile image`}
						src={!uploadImageIsLoading ? user?.image : ''}
					>
						{uploadImageIsLoading ? (
							<CircularProgress className={cClasses.avatarIcon} />
						) : (
							<AddAPhoto className={cClasses.avatarIcon} />
						)}
					</Avatar>

					<input
						type='file'
						id='profileImageUpload'
						name='userImage'
						className={cClasses.imageInput}
						value=''
						onChange={uploadFileHandler}
					/>
				</div>}
				<Paper className={cClasses.paper}>
					<Typography
						variant='h4'
						style={{ color: theme.palette.text.primary }}
						className={cClasses.subTitle}
					>
						{user?.name}
					</Typography>
					<Typography style={{ textAlign: 'center' }} className={cClasses.text}>
						{user?.email}
					</Typography>
					<Divider className={cClasses.divider} />
					{address && (
						<Typography className={cClasses.address}>{user?.name}</Typography>
					)}
					{address &&
						Object.keys(address).map(key =>
							address[key] && key === 'state' ? (
								<Typography key={key} className={cClasses.address}>
									{address.state}&nbsp;&nbsp;&nbsp;{address.postCode}
								</Typography>
							) : address[key] && key !== 'postCode' ? (
								<Typography className={cClasses.address} key={key}>
									{address[key]}
								</Typography>
							) : null
						)}
					<Grid
						container
						justifyContent='flex-end'
						spacing={2}
						className={cClasses.buttonContainer}
					>
						<Grid item>
							<Button
								className={cClasses.button}
								onClick={() => setProfileModalIsOpen(true)}
								color='secondary'
							>
								Edit Profile
							</Button>
						</Grid>
						<Grid item>
							<Button
								className={clsx(cClasses.button, cClasses.error)}
								onClick={() => dispatch(logoutAction())}
							>
								Log Out
							</Button>
						</Grid>
					</Grid>
				</Paper>
				<Button
					onClick={historyModalHandler}
					variant='contained'
					color='secondary'
					endIcon={<History />}
					className={clsx(
						cClasses.profileSettingsButton,
						bClasses.rainbowButton
					)}
					size='large'
				>
					History
				</Button>
				<Button
					variant='outlined'
					color='secondary'
					endIcon={<PersonAdd />}
					className={clsx(bClasses.button3)}
					onClick={() => setPurchaseUsersModalIsOpen(true)}
					style={{ margin: theme.spacing(1) }}
				>
					Purchase Users
				</Button>
				<Button
					variant='outlined'
					color='secondary'
					endIcon={<Subscriptions />}
					className={clsx(bClasses.button3)}
					onClick={subscriptionsModalHandler}
					style={{ margin: theme.spacing(1) }}
				>
					My Subscriptions
				</Button>
				{!!user?.userCodes?.length && (
					<Button
						onClick={resendCodesHandler}
						variant='outlined'
						color='secondary'
						endIcon={
							sendPurchasedUsersLoading && resendUserCodes ? (
								<CircularProgress size={15} color='secondary' />
							) : (
								<Email />
							)
						}
						className={clsx(bClasses.button3)}
						style={{ margin: theme.spacing(1) }}
					>
						Resend user codes
					</Button>
				)}
				{/* <Button
					component={Link}
					to='/settings'
					variant='contained'
					color='secondary'
					endIcon={<Settings />}
					className={clsx(
						cClasses.profileSettingsButton,
						bClasses.rainbowButton
					)}
					size='large'
				>
					Settings
				</Button> */}
				<Divider style={{ width: '90%', margin: theme.spacing(1, 0) }} />
				<Grid container justifyContent='space-around'>
					<Button
						color='secondary'
						style={{ textTransform: 'none', fontWeight: 600 }}
						component={Link}
						to='/contact'
					>
						Contact
					</Button>
					<Button
						color='secondary'
						style={{ textTransform: 'none', fontWeight: 600 }}
						component={Link}
						to='/terms'
					>
						Terms & Conditions
					</Button>
					<Button
						color='secondary'
						style={{ textTransform: 'none', fontWeight: 600 }}
						component={Link}
						to='/privacy'
					>
						Privacy Policy
					</Button>
				</Grid>
			</Paper>
		</>
	);
};

export default ProfileScreen;
