import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import {
	signupReducer,
	sendVerifyUserReducer,
	loginReducer,
	userDataReducer,
	verifyUserReducer,
	forgotPasswordReducer,
	resetPasswordReducer,
	userUpdateProfileReducer,
	cancelEmailUpdateReducer,
	verifyEmailUpdateReducer,
	getUsersReducer,
	getUserReducer,
	setBanReducer,
	setAdminReducer,
	uploadProfileImageReducer,
	purchaseUsersReducer,
	sendPurchasedUsersReducer,
	cancelSubscriptionReducer
} from 'reducers/userReducers';
import {
	getEmotionsReducer,
	getToolkitReducer,
	rainbowStateReducer,
	uploadRainbowImageReducer,
	uploadAudioReducer,
	deleteAudioReducer,
	deleteRainbowImageReducer,
	createRainbowElementReducer,
	updateRainbowElementReducer,
	deleteRainbowElementReducer,
	addHistoryReducer,
	updateEmotionRatingReducer,
	addEventReducer,
	editEventReducer,
	deleteEventReducer,
	toggleTimesReducer
} from 'reducers/rainbowReducers';

const reducer = combineReducers({
	signup: signupReducer,
	sendVerifyUser: sendVerifyUserReducer,
	login: loginReducer,
	userData: userDataReducer,
	verifyUser: verifyUserReducer,
	forgotPassword: forgotPasswordReducer,
	resetPassword: resetPasswordReducer,
	userUpdateProfile: userUpdateProfileReducer,
	cancelEmailUpdate: cancelEmailUpdateReducer,
	verifyEmailUpdate: verifyEmailUpdateReducer,
	getUsers: getUsersReducer,
	getUser: getUserReducer,
	setBan: setBanReducer,
	setAdmin: setAdminReducer,
	getEmotions: getEmotionsReducer,
	getToolkit: getToolkitReducer,
	rainbowState: rainbowStateReducer,
	uploadProfileImage: uploadProfileImageReducer,
	uploadRainbowImage: uploadRainbowImageReducer,
	uploadAudio: uploadAudioReducer,
	deleteAudio: deleteAudioReducer,
	deleteRainbowImage: deleteRainbowImageReducer,
	createRainbowElement: createRainbowElementReducer,
	updateRainbowElement: updateRainbowElementReducer,
	deleteRainbowElement: deleteRainbowElementReducer,
	addHistory: addHistoryReducer,
	updateEmotionRating: updateEmotionRatingReducer,
	addEvent: addEventReducer,
	editEvent: editEventReducer,
	deleteEvent: deleteEventReducer,
	toggleTimes: toggleTimesReducer,
	purchaseUsers: purchaseUsersReducer,
	sendPurchasedUsers: sendPurchasedUsersReducer,
	cancelSubscriptions: cancelSubscriptionReducer
});

const userFromStorage = localStorage.getItem('user')
	? JSON.parse(localStorage.getItem('user'))
	: null;

const initialState = {
	userData: {
		user: userFromStorage?.user,
		token: userFromStorage?.token,
		isAuth: !!userFromStorage?.token
	}
};

const middleware = [thunk];

const store = createStore(
	reducer,
	initialState,
	composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
