export const SIGNUP_REQUEST = 'SIGNUP_REQUEST';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_FAIL = 'SIGNUP_FAIL';
export const SIGNUP_CLEAR = 'SIGNUP_CLEAR';

export const SEND_VERIFY_USER_REQUEST = 'SEND_VERIFY_USER_REQUEST';
export const SEND_VERIFY_USER_SUCCESS = 'SEND_VERIFY_USER_SUCCESS';
export const SEND_VERIFY_USER_FAIL = 'SEND_VERIFY_USER_FAIL';
export const SEND_VERIFY_USER_CLEAR = 'SEND_VERIFY_USER_CLEAR';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGIN_CLEAR = 'LOGIN_CLEAR';

export const USER_DATA_REQUEST = 'USER_DATA_REQUEST';
export const USER_DATA_SUCCESS = 'USER_DATA_SUCCESS';
export const USER_DATA_FAIL = 'USER_DATA_FAIL';
export const USER_DATA_CLEAR = 'USER_DATA_CLEAR';
export const USER_DATA_LOGOUT = 'USER_DATA_LOGOUT';
export const USER_DATA_IMAGE_UPLOAD_SUCCESS = 'USER_DATA_IMAGE_UPLOAD_SUCCESS';

export const VERIFY_USER_REQUEST = 'VERIFY_USER_REQUEST';
export const VERIFY_USER_SUCCESS = 'VERIFY_USER_SUCCESS';
export const VERIFY_USER_FAIL = 'VERIFY_USER_FAIL';
export const VERIFY_USER_CLEAR = 'VERIFY_USER_CLEAR';

export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAIL = 'FORGOT_PASSWORD_FAIL';
export const FORGOT_PASSWORD_CLEAR = 'FORGOT_PASSWORD_CLEAR';

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL';
export const RESET_PASSWORD_CLEAR = 'RESET_PASSWORD_CLEAR';

export const USER_UPDATE_PROFILE_REQUEST = 'USER_UPDATE_PROFILE_REQUEST';
export const USER_UPDATE_PROFILE_SUCCESS = 'USER_UPDATE_PROFILE_SUCCESS';
export const USER_UPDATE_PROFILE_FAIL = 'USER_UPDATE_PROFILE_FAIL';
export const USER_UPDATE_PROFILE_CLEAR = 'USER_UPDATE_PROFILE_CLEAR';

export const CANCEL_EMAIL_UPDATE_REQUEST = 'CANCEL_EMAIL_UPDATE_REQUEST';
export const CANCEL_EMAIL_UPDATE_SUCCESS = 'CANCEL_EMAIL_UPDATE_SUCCESS';
export const CANCEL_EMAIL_UPDATE_FAIL = 'CANCEL_EMAIL_UPDATE_FAIL';
export const CANCEL_EMAIL_UPDATE_CLEAR = 'CANCEL_EMAIL_UPDATE_CLEAR';

export const VERIFY_EMAIL_UPDATE_REQUEST = 'VERIFY_EMAIL_UPDATE_REQUEST';
export const VERIFY_EMAIL_UPDATE_SUCCESS = 'VERIFY_EMAIL_UPDATE_SUCCESS';
export const VERIFY_EMAIL_UPDATE_FAIL = 'VERIFY_EMAIL_UPDATE_FAIL';
export const VERIFY_EMAIL_UPDATE_CLEAR = 'VERIFY_EMAIL_UPDATE_CLEAR';

export const GET_USERS_REQUEST = 'GET_USERS_REQUEST';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_FAIL = 'GET_USERS_FAIL';
export const GET_USERS_CLEAR = 'GET_USERS_CLEAR';

export const GET_USER_REQUEST = 'GET_USER_REQUEST';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAIL = 'GET_USER_FAIL';
export const GET_USER_CLEAR = 'GET_USER_CLEAR';

export const SET_ADMIN_REQUEST = 'SET_ADMIN_REQUEST';
export const SET_ADMIN_SUCCESS = 'SET_ADMIN_SUCCESS';
export const SET_ADMIN_FAIL = 'SET_ADMIN_FAIL';
export const SET_ADMIN_CLEAR = 'SET_ADMIN_CLEAR';

export const SET_BAN_REQUEST = 'SET_BAN_REQUEST';
export const SET_BAN_SUCCESS = 'SET_BAN_SUCCESS';
export const SET_BAN_FAIL = 'SET_BAN_FAIL';
export const SET_BAN_CLEAR = 'SET_BAN_CLEAR';

export const UPLOAD_PROFILE_IMAGE_REQUEST = 'UPLOAD_PROFILE_IMAGE_REQUEST';
export const UPLOAD_PROFILE_IMAGE_SUCCESS = 'UPLOAD_PROFILE_IMAGE_SUCCESS';
export const UPLOAD_PROFILE_IMAGE_FAIL = 'UPLOAD_PROFILE_IMAGE_FAIL';
export const UPLOAD_PROFILE_IMAGE_CLEAR = 'UPLOAD_PROFILE_IMAGE_CLEAR';

export const PURCHASE_USERS_REQUEST = 'PURCHASE_USERS_REQUEST';
export const PURCHASE_USERS_SUCCESS = 'PURCHASE_USERS_SUCCESS';
export const PURCHASE_USERS_FAIL = 'PURCHASE_USERS_FAIL';
export const PURCHASE_USERS_CLEAR = 'PURCHASE_USERS_CLEAR';

export const SEND_PURCHASED_USERS_REQUEST = 'SEND_PURCHASED_USERS_REQUEST';
export const SEND_PURCHASED_USERS_SUCCESS = 'SEND_PURCHASED_USERS_SUCCESS';
export const SEND_PURCHASED_USERS_FAIL = 'SEND_PURCHASED_USERS_FAIL';
export const SEND_PURCHASED_USERS_CLEAR = 'SEND_PURCHASED_USERS_CLEAR';

export const CANCEL_SUBSCRIPTIONS_REQUEST = 'CANCEL_SUBSCRIPTIONS_REQUEST';
export const CANCEL_SUBSCRIPTIONS_SUCCESS = 'CANCEL_SUBSCRIPTIONS_SUCCESS';
export const CANCEL_SUBSCRIPTIONS_FAIL = 'CANCEL_SUBSCRIPTIONS_FAIL';
