import React from 'react';
import {
	Paper,
	Grid,
	Typography,
	useTheme,
	IconButton
} from '@material-ui/core';
import useBStyles from 'styles/bStyles';
import useCStyles from 'styles/cStyles';
import { Close, Email, Phone } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';

const AboutScreen = () => {
	const bClasses = useBStyles();
	const cClasses = useCStyles();
	const theme = useTheme();
	const history = useHistory();
	return (
		<Paper
			classes={{ root: cClasses.screenRoot }}
			elevation={5}
			component={Grid}
			container
			direction='column'
			alignItems='center'
			className={cClasses.termsPaper}
			style={{ width: 320 }}
		>
			<IconButton className={bClasses.close} onClick={() => history.push('/')}>
				<Close />
			</IconButton>
			<Typography variant='h3' className={cClasses.modalTitle}>
				Contact Us
			</Typography>
			<Typography
				style={{
					fontSize: '1.1rem',
					fontWeight: 600,
					marginBottom: theme.spacing(1)
				}}
			>
				Dolly Bhargava:
			</Typography>
			<Typography
				style={{ fontWeight: 800, margin: 0, marginLeft: theme.spacing(1) }}
			>
				Behaviour Help PTY LTD
			</Typography>
			<Typography style={{ fontWeight: 800, margin: theme.spacing(1) }}>
				ABN: 746 127 282 75
			</Typography>
			<Grid
				container
				justifyContent='center'
				alignItems='center'
				style={{ marginBottom: theme.spacing(1) }}
			>
				<Phone />
				<Typography
					style={{ fontWeight: 800, margin: 0, marginLeft: theme.spacing(1) }}
				>
					0423 293 254
				</Typography>
			</Grid>
			<Grid
				container
				justifyContent='center'
				alignItems='center'
				style={{ marginBottom: theme.spacing(1) }}
			>
				<Email />
				<Typography style={{ margin: 0, marginLeft: theme.spacing(1) }}>
					<a className={cClasses.link} href='mailto: dolly@behaviourhelp.com'>
						dolly@behaviourhelp.com
					</a>
				</Typography>
			</Grid>
		</Paper>
	);
};

export default AboutScreen;
