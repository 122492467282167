import React, { useState } from 'react';
import {
	Paper,
	IconButton,
	Grid,
	Typography,
	List,
	ListItem,
	ListItemText,
	Collapse,
	useTheme,
	Button
} from '@material-ui/core';
import {
	Add,
	Close,
	ExpandLess,
	ExpandMore,
	Settings,
	Speed
} from '@material-ui/icons';
import useBStyles from 'styles/bStyles';
import useCStyles from 'styles/cStyles';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import AppSettingsModal from 'components/AppSettingsModal';
import ListModal from 'components/ListModal';
import RatingModal from 'components/RatingModal';
import ScheduleModal from 'components/ScheduleModal';

const categoryTypes = {
	chat: 'CHAT',
	helpfulThinking: 'HELPFUL_THINKING',
	amusement: 'AMUSEMENT',
	relaxation: 'RELAXATION',
	goodRoutine: 'GOOD_ROUTINE',
	exercise: 'EXERCISE'
};

const categoryTitles = {
	chat: 'Chat',
	helpfulThinking: 'Helpful Thinking',
	amusement: 'Amusement',
	relaxation: 'Relaxation',
	goodRoutine: 'Good Routine',
	exercise: 'Exercise'
};

const SettingsScreen = () => {
	const bClasses = useBStyles();
	const cClasses = useCStyles();
	const theme = useTheme();
	const { isAuth, user } = useSelector(state => state.userData);
	const {
		negativeEmotions: defaultNegativeEmotions,
		physicalEmotions: defaultPhysicalEmotions
	} = useSelector(state => state.getEmotions);
	const { getToolkit: defaultToolkit } = useSelector(state => state);
	const negativeEmotions = isAuth
		? user.negativeEmotions
		: defaultNegativeEmotions;
	const physicalEmotions = isAuth
		? user.physicalEmotions
		: defaultPhysicalEmotions;
	const toolkit = isAuth ? user.toolkit : defaultToolkit;
	const [settingsIdOpen, setSettingsIdOpen] = useState(0);
	const [modalIsOpen, setModalIsOpen] = useState(false);
	const [listModalIsOpen, setListModalIsOpen] = useState(false);
	const [scheduleModalIsOpen, setScheduleModalIsOpen] = useState(false);
	const [listModalType, setListModalType] = useState('');
	const [formState, setFormState] = useState({});
	const [ratingModalIsOpen, setRatingModalIsOpen] = useState(false);

	const handleOpenSettings = settingsId => {
		if (settingsId === settingsIdOpen) {
			setSettingsIdOpen(0);
		} else {
			setSettingsIdOpen(settingsId);
		}
	};

	const handleOpenList = action => {
		setListModalIsOpen(true);
		setListModalType(action.type);
	};

	const handleEdit = action => {
		setModalIsOpen(true);
		const formData = {
			isHappy: !!action.payload?.isHappy,
			type: action.type,
			id: action.payload?._id,
			category: action.payload?.category,
			title: {
				value: action.payload ? action.payload.title : '',
				isValid: !!action.payload?.title,
				isTouched: false
			},
			image: {
				value: action.payload ? action.payload.image : ''
			},
			audio: {
				value: action.payload ? action.payload.audio : ''
			}
		};
		setFormState(
			action.type === 'PHYSICAL_EMOTION' || action.type === 'NEGATIVE_EMOTION'
				? {
						...formData,
						color: {
							value: action.payload ? action.payload.color : '',
							isValid: !!action.payload?.color,
							isTouched: false
						}
				  }
				: formData
		);
	};
	return (
		<>
			<AppSettingsModal
				open={modalIsOpen}
				setOpen={setModalIsOpen}
				formState={formState}
				setFormState={setFormState}
			/>
			<ListModal
				open={listModalIsOpen}
				setOpen={setListModalIsOpen}
				type={listModalType}
				handleEdit={handleEdit}
			/>
			<ScheduleModal
				open={scheduleModalIsOpen}
				setOpen={setScheduleModalIsOpen}
			/>
			<RatingModal open={ratingModalIsOpen} setOpen={setRatingModalIsOpen} />
			<Paper
				classes={{ root: cClasses.screenRoot }}
				elevation={5}
				component={Grid}
				container
				direction='column'
				alignItems='center'
				style={{ width: 320 }}
			>
				<IconButton className={cClasses.closeButton} component={Link} to='/'>
					<Close fontSize='small' />
				</IconButton>
				<Typography variant='h3' style={{ marginBottom: theme.spacing(1) }}>
					Settings
				</Typography>
				<List className={bClasses.outerList}>
					<ListItem button onClick={() => handleOpenSettings(1)}>
						<ListItemText
							className={bClasses.listTitle}
							primary='Negative Emotions'
						/>

						{settingsIdOpen === 1 ? <ExpandLess /> : <ExpandMore />}
					</ListItem>
					<Collapse in={settingsIdOpen === 1} timeout='auto' unmountOnExit>
						<List className={bClasses.innerList}>
							{negativeEmotions.map((emotion, i) => (
								<ListItem
									button
									component={Grid}
									className={bClasses.settingsListItem}
									key={`${emotion.title}${i}`}
									onClick={() =>
										handleEdit({ type: 'NEGATIVE_EMOTION', payload: emotion })
									}
								>
									<ListItemText primary={emotion.title} />
									<Settings
										fontSize='small'
										className={bClasses.settingsListIcon}
									/>
								</ListItem>
							))}

							<ListItem
								button
								component={Grid}
								className={bClasses.settingsListItem}
								onClick={() => handleOpenList({ type: 'NEGATIVE_EMOTION' })}
								disabled={negativeEmotions.length > 8}
								style={
									negativeEmotions.length > 8
										? { textDecoration: 'line-through' }
										: null
								}
							>
								<ListItemText
									primary='Add emotion'
									className={bClasses.settingsListAdd}
								/>
								<Add fontSize='small' className={bClasses.settingsListIcon} />
							</ListItem>
							{negativeEmotions.length > 8 && (
								<ListItem
									button
									component={Grid}
									className={bClasses.settingsListItem}
									disabled={negativeEmotions.length > 8}
								>
									<ListItemText
										primary='Maximum 10 emotions'
										className={bClasses.settingsListAdd}
									/>
								</ListItem>
							)}
						</List>
					</Collapse>
				</List>
				<List className={bClasses.outerList}>
					<ListItem button onClick={() => handleOpenSettings(2)}>
						<ListItemText
							className={bClasses.listTitle}
							primary='Physical Emotions'
						/>

						{settingsIdOpen === 2 ? <ExpandLess /> : <ExpandMore />}
					</ListItem>
					<Collapse in={settingsIdOpen === 2} timeout='auto' unmountOnExit>
						<List className={bClasses.innerList}>
							{physicalEmotions.map((emotion, i) => (
								<ListItem
									button
									component={Grid}
									className={bClasses.settingsListItem}
									key={`${emotion.title}${i}`}
									onClick={() =>
										handleEdit({ type: 'PHYSICAL_EMOTION', payload: emotion })
									}
								>
									<ListItemText primary={emotion.title} />
									<Settings
										fontSize='small'
										className={bClasses.settingsListIcon}
									/>
								</ListItem>
							))}
							<ListItem
								button
								component={Grid}
								className={bClasses.settingsListItem}
								onClick={() => handleOpenList({ type: 'PHYSICAL_EMOTION' })}
								disabled={physicalEmotions.length > 8}
								style={
									physicalEmotions.length > 8
										? { textDecoration: 'line-through' }
										: null
								}
							>
								<ListItemText
									primary='Add emotion'
									className={bClasses.settingsListAdd}
								/>
								<Add fontSize='small' className={bClasses.settingsListIcon} />
							</ListItem>
							{physicalEmotions.length > 8 && (
								<ListItem
									button
									component={Grid}
									className={bClasses.settingsListItem}
									onClick={() => handleOpenList({ type: 'NEGATIVE_EMOTION' })}
									disabled={physicalEmotions.length > 8}
								>
									<ListItemText
										primary='Maximum 10 emotions'
										className={bClasses.settingsListAdd}
									/>
								</ListItem>
							)}
						</List>
					</Collapse>
				</List>
				<Button
					// color='secondary'
					variant='outlined'
					style={{
						marginTop: theme.spacing(2),
						fontWeight: 600,
						textTransform: 'none',
						fontSize: '1.1rem',
						width: 243,
						borderColor: theme.palette.grey[500],
						justifyContent: 'space-between'
					}}
					onClick={() => setRatingModalIsOpen(true)}
					endIcon={<Speed />}
					size='large'
					className={bClasses.listTitle}
				>
					Emotion Rating
				</Button>
				<List className={bClasses.outerList}>
					<ListItem button onClick={() => handleOpenSettings(3)}>
						<ListItemText
							className={bClasses.listTitle}
							primary='CHARGE Toolkit '
						/>

						{settingsIdOpen === 3 ? <ExpandLess /> : <ExpandMore />}
					</ListItem>
					<Collapse in={settingsIdOpen === 3} timeout='auto' unmountOnExit>
						<List className={bClasses.innerList}>
							{Object.keys(toolkit).map(category =>
								category === 'goodRoutine' ? (
									<React.Fragment key={category}>
										<ListItem>
											<ListItemText
												className={bClasses.toolCategoryTitle}
												primary={categoryTitles[category]}
											/>
										</ListItem>

										<ListItem
											button
											component={Grid}
											className={bClasses.settingsListItem}
											onClick={() => setScheduleModalIsOpen(true)}
										>
											<ListItemText primary='Routine' />
											<Settings
												fontSize='small'
												className={bClasses.settingsListIcon}
											/>
										</ListItem>
									</React.Fragment>
								) : (
									<React.Fragment key={category}>
										<ListItem>
											<ListItemText
												className={bClasses.toolCategoryTitle}
												primary={categoryTitles[category]}
											/>
										</ListItem>

										{toolkit[category].map((tool, i) => (
											<ListItem
												button
												component={Grid}
												className={bClasses.settingsListItem}
												key={`${tool.title}${i}`}
												onClick={() =>
													handleEdit({
														type: 'TOOL',
														payload: { ...tool, category }
													})
												}
											>
												<ListItemText primary={tool.title} />
												<Settings
													fontSize='small'
													className={bClasses.settingsListIcon}
												/>
											</ListItem>
										))}

										<ListItem
											button
											component={Grid}
											className={bClasses.settingsListItem}
											onClick={() =>
												handleOpenList({ type: categoryTypes[category] })
											}
											disabled={toolkit[category].length > 19}
											style={
												toolkit[category].length > 19
													? { textDecoration: 'line-through' }
													: null
											}
										>
											<ListItemText
												primary='Add tool'
												className={bClasses.settingsListAdd}
											/>
											<Add
												fontSize='small'
												className={bClasses.settingsListIcon}
											/>
										</ListItem>
										{toolkit[category].length > 19 && (
											<ListItem
												button
												component={Grid}
												className={bClasses.settingsListItem}
												disabled={negativeEmotions.length > 8}
											>
												<ListItemText
													primary='Maximum 20 tools'
													className={bClasses.settingsListAdd}
												/>
											</ListItem>
										)}
									</React.Fragment>
								)
							)}
						</List>
					</Collapse>
				</List>
			</Paper>
		</>
	);
};

export default SettingsScreen;
