import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { verifyUserAction, verifyEmailUpdateAction } from 'actions/userActions';
import { CircularProgress, Typography, Paper, Grid } from '@material-ui/core';
import useCStyles from 'styles/cStyles';
import { useTheme } from '@material-ui/styles';

const VerifyScreen = ({ match, location, history }) => {
	const cClasses = useCStyles();
	const dispatch = useDispatch();
	const verifyToken = match.params.token;
	const theme = useTheme();

	const { error: verifyUserError, success: verifyUserSuccess } = useSelector(
		state => state.verifyUser
	);

	const { error: verifyEmailError, success: verifyEmailSuccess } = useSelector(
		state => state.verifyEmailUpdate
	);

	useEffect(() => {
		if (verifyUserSuccess || verifyEmailSuccess || verifyEmailError) {
			history.push('/profile');
		} else if (verifyUserError) {
			history.push('/login');
		} else if (location.pathname.startsWith('/verifyuser')) {
			dispatch(verifyUserAction(verifyToken));
		} else if (location.pathname.startsWith('/verifyemail')) {
			dispatch(verifyEmailUpdateAction(verifyToken));
		}
	}, [
		dispatch,
		verifyToken,
		verifyUserSuccess,
		verifyEmailSuccess,
		history,
		location.pathname,
		verifyEmailError,
		verifyUserError
	]);

	return (
		<Paper
			variant='outlined'
			className={cClasses.overlayPaper}
			component={Grid}
			container
			direction='column'
			alignItems='center'
			style={{ width: 'min-content', padding: theme.spacing(2) }}
			wrap='nowrap'
		>
			<Typography variant='h1'>Verifying</Typography>
			<CircularProgress
				style={{ marginTop: 20, color: theme.palette.secondary.main }}
				thickness={2}
				size={50}
			/>
		</Paper>
	);
};

export default VerifyScreen;
