import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles(theme => ({
	form: {
		width: 320,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center'
	},
	input: {
		[theme.breakpoints.down('xs')]: {
			paddingRight: theme.spacing(3)
		},
		'&:first-of-type': {
			marginTop: theme.spacing(2)
		},
		width: '90%',
		'& .MuiFormLabel-root': {
			color: theme.palette.text.primary
		},
		'& .Mui-disabled': {
			color: theme.palette.text.secondary,
			'& .MuiInput-input': {
				backgroundColor: theme.palette.grey[200]
			}
		},
		'& .MuiInputLabel-shrink, p': {
			fontWeight: 700
		}
	},
	errorButton: {
		color: theme.palette.error.main,
		borderColor: theme.palette.error.main,
		margin: theme.spacing(1),
		fontWeight: 700
	},
	error: {
		'& label.Mui-focused': {
			color: theme.palette.error.main
		},
		'& .MuiFormLabel-root': {
			color: theme.palette.error.main
		},
		'& .MuiTypography-root': {
			color: theme.palette.error.main,
			fontWeight: 700
		}
	},
	button: {
		margin: theme.spacing(1)
	},
	button2: {
		marginTop: theme.spacing(2),
		fontWeight: 700
	},
	button3: {
		marginTop: theme.spacing(1),
		color: theme.palette.secondary.main,
		textTransform: 'none',
		fontWeight: 700,
		'&:hover': {
			// color: theme.palette.primary.main
		}
	},
	submitProgress: {
		color: theme.palette.background.default
	},
	modalPaper: {
		padding: theme.spacing(4, 2, 3, 2),
		position: 'fixed',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		overflowY: 'scroll',
		'&:focus': {
			outline: 'none'
		},
		minWidth: 320,
		maxWidth: '100%',
		maxHeight: '85%',
		[theme.breakpoints.down('xs')]: {
			top: 0,
			left: 0,
			width: '100vw',
			height: 'calc(100 * var(--vh))',
			maxHeight: 'calc(100 * var(--vh))',
			transform: 'none'
		}
	},
	modalForm: {
		maxWidth: 400,
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center'
	},
	close: {
		position: 'absolute',
		top: 0,
		left: 0
	},
	popover: {
		position: 'absolute',
		top: 0,
		right: 0
	},
	pdf: {
		position: 'absolute',
		top: 0,
		right: 0,
		margin: theme.spacing(3)
	},
	outerList: {
		width: '90%',
		marginTop: theme.spacing(2),
		padding: 0,
		border: `1px solid ${theme.palette.grey[500]}`,
		borderRadius: 5
	},
	// outerListOpen: {
	// 	borderColor: theme.palette.grey[600],
	// 	borderWidth: 1
	// },
	innerList: {
		padding: theme.spacing(1, 2),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center'
	},
	greyText: {
		color: theme.palette.grey[600]
	},
	success: {
		color: theme.palette.success.main,
		fontWeight: 700,
		'& .MuiTypography-root': {
			fontWeight: 700
		}
	},
	alert: {
		color: theme.palette.common.purple6,
		fontWeight: 700,
		'& .MuiTypography-root': {
			fontWeight: 700
		}
	},
	text: {
		color: theme.palette.text.primary
	},
	paper: {
		backgroundColor: theme.palette.common.tan2
	},
	happyButtonGrid: {
		// height: '100%'
	},
	happyButtonGroup: { marginTop: theme.spacing(1) },
	happyButtonHighlight: {
		backgroundColor: theme.palette.secondary.main,
		color: theme.palette.background.default,
		'&:hover': {
			backgroundColor: theme.palette.secondary.main,
			color: theme.palette.background.default
		}
	},
	summaryTitle: {
		padding: theme.spacing(2, 2, 1, 2),
		'& .MuiTypography-h6': {
			fontWeight: 700
		}
	},
	summaryLink: {
		textDecoration: 'none',
		color: theme.palette.secondary.main,
		fontWeight: 700
	},
	summaryList: {
		paddingTop: 0,
		'& span': {
			fontWeight: 700,
			marginLeft: theme.spacing(1)
		},
		'& li': {
			paddingTop: 0
		}
	},
	summaryText: {
		color: theme.palette.text.primary,
		fontSize: '.9rem'
	},
	emotionSwitchContainer: {
		cursor: 'pointer',
		position: 'fixed',
		top: 22,
		right: 110,
		height: 40,
		width: 120,
		zIndex: 2,
		fill: theme.palette.grey[500],
		color: theme.palette.grey[500],
		[theme.breakpoints.down('xs')]: {
			top: 33,
			right: 75 + theme.spacing(2)
		}
	},
	emotionSwitchContainerSmall: {
		top: 55
	},
	brain: {
		position: 'absolute',
		left: 2,
		top: '50%',
		transform: 'translateY(-50%)',
		height: 30,
		width: 30
	},
	body: {
		position: 'absolute',
		right: 0,
		height: 35,
		width: 35,
		top: '50%',
		transform: 'translateY(-50%)'
	},
	blue: {
		fill: theme.palette.secondary.main,
		color: theme.palette.secondary.main,
		stroke: theme.palette.secondary.main
	},
	titleColor: {
		paddingBottom: theme.spacing(1),
		color: theme.palette.common.tan10,
		'& h2': {
			fontWeight: 700
		}
	},
	messageIconContainer: {
		position: 'relative',
		height: 75
	},
	messageIcon: {
		width: 75,
		height: 75,
		fill: theme.palette.secondary.main,
		color: theme.palette.secondary.main,
		position: 'absolute',
		top: 0,
		left: '50%'
	},
	messageIconInRight: {
		animation: '$icon-in-right 1s ease forwards'
	},
	messageIconInLeft: {
		animation: '$icon-in-left 1s ease forwards'
	},
	messageIconOutLeft: {
		animation: '$icon-out-left 1s ease forwards'
	},
	messageIconOutRight: {
		animation: '$icon-out-right 1s ease forwards'
	},
	'@keyframes icon-in-left': {
		'0%': {
			transform: 'translateX(50%)',
			opacity: 0
		},
		'100%': {
			transform: 'translateX(-50%)',
			opacity: 1
		}
	},
	'@keyframes icon-out-left': {
		'0%': {
			transform: 'translateX(-50%)',
			opacity: 1
		},
		'100%': {
			transform: 'translateX(-150%)',
			opacity: 0
		}
	},
	'@keyframes icon-in-right': {
		'0%': {
			transform: 'translateX(-150%)',
			opacity: 0
		},
		'100%': {
			transform: 'translateX(-50%)',
			opacity: 1
		}
	},
	'@keyframes icon-out-right': {
		'0%': {
			transform: 'translateX(-50%)',
			opacity: 1
		},
		'100%': {
			transform: 'translateX(50%)',
			opacity: 0
		}
	},
	rainbowIcon: {
		position: 'absolute',
		left: 7,
		top: '88%',
		transition: '.5s ease',
		width: 20,
		height: 20
	},
	rainbowIconTranslate: {
		transform: 'translateX(85px)'
	},
	rainbowButton: {
		fontWeight: 700,
		textTransform: 'none',
		fontSize: '1.3rem'
	},
	useToolButton: {
		fontWeight: 700,
		textTransform: 'none',
		fontSize: '1.5rem'
	},
	listTitle: {
		'& span': {
			fontWeight: 700,
			fontSize: '1.2rem'
		}
	},
	settingsListItem: {
		'& span': {
			fontWeight: 700
		},
		'& svg': {
			color: theme.palette.grey[500]
		},
		'&:hover': {
			'& svg': {
				color: theme.palette.secondary.main
			}
		}
	},
	settingsListAdd: {
		// marginLeft: theme.spacing(2),
		'& span': {
			fontWeight: 500
		}
	},
	toolCategoryTitle: {
		borderBottom: `1px solid ${theme.palette.grey[500]}`,
		'& span': {
			fontWeight: 700,
			fontSize: '1.2rem'
		}
	},
	inputOverlay: {
		position: 'absolute',
		width: '100%',
		height: '100%',
		opacity: 0,
		'&:hover': {
			cursor: 'pointer'
		}
	},
	colorPickerGrid: {
		marginTop: theme.spacing(1)
	},
	audioGrid: {
		margin: theme.spacing(2)
	},
	colorPicker: {
		border: 'none',
		background: 'transparent',
		margin: theme.spacing(0, 1, 2, 1),
		width: 120,
		height: 50,
		cursor: 'pointer'
	},
	recordAudioButton: {
		margin: theme.spacing(1),
		fontWeight: 700,
		textTransform: 'none'
	},
	cardInputPaper: {
		margin: theme.spacing(1, 0),
		padding: theme.spacing(1)
	},
	cardInputLabel: {
		fontSize: '.8rem',
		fontWeight: 700
	},
	cardInput: { width: '100%' },
	priceGridItem: {
		padding: theme.spacing(1),
		'& div': {
			textTransform: 'none'
			// padding: theme.spacing(1)
		},
		'& p:first-of-type': {
			fontSize: '.8rem'
		}
	},
	selectedPrice: {
		// borderColor: theme.palette.secondary.main,
		// backgroundColor: theme.palette.secondary.main,
		margin: theme.spacing(1, 0),
		'& p': {
			// color: theme.palette.background.default,
			fontWeight: 700
		}
	},
	totalPrice: {
		margin: theme.spacing(2),
		fontSize: '1.2rem'
	},
	total: {
		fontWeight: 700
	},
	currency: {
		fontSize: '.7rem',
		fontWeight: 800
	},
	numberUsersInput: {
		width: 125,
		margin: theme.spacing(1, 0, 2, 0),
		'& .MuiInputBase-input': {
			fontSize: '1.2rem',
			fontWeight: 700,
			textAlign: 'center'
		}
	},
	cardDisabled: {
		backgroundColor: theme.palette.grey[200],
		'& p': {
			color: theme.palette.text.secondary,
			fontSize: '.8rem'
		}
	}
}));

export default styles;
